import React from "react";

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const Section = ({ children, title, subtitle, ...props }: Props) => {
  return (
    <section {...props} className="mt-12">
      <div className="mx-auto ">
        <div className="text-center">
          <h1 className=" font-sans">{title}</h1>
          <h4 className="lg:mx-auto font-normal text-gray-dark">{subtitle}</h4>
        </div>

        <div className="mt-8">{children}</div>
      </div>
    </section>
  );
};

export default Section;
