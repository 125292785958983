import React from 'react'
import Barcode from 'react-barcode'

type Props = {
    ean: number | undefined | string
    small?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const ProductBarcode = ({ean , small, ...props}: Props) => {
  return (
    <Barcode {...props} width={small ? 1 : 1.25} height={small ? 20 : 25} value={(typeof ean == "string" ? ean  : ean?.toString()) || ""}  fontSize={15}  margin={5} format="EAN13"  />
  )
}

export default ProductBarcode