import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const updateAddress = createAsyncThunk(
  "user/updateAddress",
  async (args, thunkAPI) => {
    try {
      const token = Cookies.get("fd-token");
      const response = await axios.post(
        `https://www.fragrancedistri.com/wp/wp-json/wp/v3/customer/address/update?JWT=${token}`,
        args
      );
      const userResponse = await axios.post(
        `https://www.fragrancedistri.com/wp/wp-json/wp/v3/customer/info/update?JWT=${token}`,
        {
          vat_number: args?.vat_number,
        }
      );
      console.log("userResponse", args);
      return { ...userResponse.data, ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
