import CardInnerBorder from "../../components/Card/CardInnerBorder";
import Section from "../../components/Elements/Semantic/Section";
import Page from "../../components/Layout/Page";
import { Link } from "react-router-dom";
import { SecondaryInput } from "../../components/Input/Input";
import PasswordInput from "../../components/Input/PasswordInput";
import Checkbox from "../../components/Input/Checkbox";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../app/User/actions/signIn";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";




function Login() {
    const dispatch = useDispatch();
    const { loggedIn } = useSelector((state: any) => state.user)
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        /* RequireAuth() */
        if (loggedIn) {
            return navigate(state?.path || "/");
        }

    }, [loggedIn]);

    useEffect(() => {
        if (loggedIn) {
            return navigate(state?.path || "/");
        }
    }, []);


    const handleSignIn = (e: any) => {


        e.preventDefault();
        dispatch(
            //@ts-ignore
            signIn({
                //@ts-ignore
                user: {
        email: e.target.email.value,
        password: e.target.password.value
      }
            })
        );
    };

    return (
        <Page>
            <Section title="Login" subtitle="Welcome to Fragrancedistri!">
                <CardInnerBorder className="sm:mx-10 md:mx-30 lg:mx-60">
                    <form onSubmit={handleSignIn} className="grid grid-cols-2  sm:px-5 md:px-10 lg:px-10 xl:px-24">
                        <div className="col-span-2 my-4">
                            <img className="w-[201px] h-[201px] m-auto" src="logo/logo_green.png" alt="logo" />
                        </div>
                        <div className="col-span-2 ">
                            <SecondaryInput className="" name={"email"} placeholder="Email" />
                        </div>
                        <div className="col-span-2 mt-2 ">
                            <PasswordInput name={"password"} placeholder="Password" />
                        </div>
                        <div className="col-span-2 flex flex-row justify-between mt-4">
                            <div className="flex">
                                <Checkbox /> <label className="ml-3 text-xs self-center">Remember me</label>
                            </div>
                            <div>
                                <Link to="/reset-password" className="text-green text-xs underline">Forgot password?</Link>
                            </div>
                        </div>
                        <div className="col-span-2 flex  flex-col  items-center gap-6 justify-center my-10">
                           
                                <ButtonComponent type="submit" className="bg-gray-lighter w-40 h-12 rounded-15 text-green font-semibold text-xs hover:bg-green hover:text-white" >Submit</ButtonComponent>
                                <p> 
                                    Don't have an account? <Link to="/register" className="text-green text-xs underline">Register</Link>
                                </p>
                        </div>

                        
                    </form>
                </CardInnerBorder>
            </Section>
        </Page>
    )
}

export default Login;