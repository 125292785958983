import React from "react";
import Page from "../components/Layout/Page";
import {
  BsBagPlusFill,
  BsCheck,
  BsFillLockFill,
  BsTruck,
  BsUpcScan,
  BsX,
} from "react-icons/bs";
import Accordion from "../components/Accordion/Accordion";
import { IconButtonOutline } from "../components/Button/IconButton";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import Section from "../components/Elements/Semantic/Section";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { clearCart, removeItemFromCart } from "../app/Cart/cartSlice";
import { isMobile } from "react-device-detect";
import Circle from "../components/Common/Circle";
import StockInfo from "../components/Common/StockInfo";
import NumberInput from "../components/Input/NumberInput";
import { ProductType, RelatedProductType } from "../types/Product";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ProductBarcode from "../components/Common/ProductBarcode";
import { notify } from "../utils/notify";
import priceFormatter from "../utils/priceFormatter";

type Props = {
  className?: string;
  children?: React.ReactNode;
  product?: ProductType & { quantity: number };
  relatedProduct?: boolean;
};

const Cart = (props: Props) => {
  const { items, totalPrice, totalQuantity, subtotal, taxAmount, taxRate } =
    useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });
  return (
    <Page>
      <Section title="Cart" subtitle="Review your order details">
        <div className="flex flex-col gap-4">
          <Accordion title="Selected products">
            <CardInnerBorder
              innerCard="p-0  group-hover:!bg-white"
              className=" col-span-full"
            >
              {items.map((item, index) => {
                return <CartItem key={index} product={item} />;
              })}

              {items.length > 0 ? (
                <div className="grid md:grid-cols-12 mt-6">
                  <div className=" md:col-start-8 md:col-end-11 flex flex-col gap-1 mx-14">
                    <div className="flex justify-between">
                      <h5>SubTotal</h5>
                      <h5>{priceFormatter.format(subtotal)}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h5>VAT cost ({taxRate * 100}%)</h5>
                      <h5> {priceFormatter.format(taxAmount)}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h4 className="font-semibold">Total</h4>
                      <h4 className="font-semibold">
                        {" "}
                        {priceFormatter.format(totalPrice)}
                      </h4>
                    </div>
                  </div>

                  <div className="md:col-start-11 md:col-end-13 mt-4 md:mt-0  flex justify-center md:justify-end px-4">
                    <IconButtonOutline
                      // @ts-ignore
                      onClick={() => dispatch(clearCart())}
                      className={`   hover:!bg-green hover:!text-white !bg-gray-lighter text-xs !w-fit  border-green text-green `}
                    >
                      Clear All
                    </IconButtonOutline>
                  </div>
                </div>
              ) : (
                /* No items in Cart component */
                <div className="w-full h-fit py-10">
                  <h5 className="text-center">No items in cart</h5>
                </div>
              )}
            </CardInnerBorder>
          </Accordion>

          <CardInnerBorder
            innerCard="px-10 pt-6  flex flex-col gap-2"
            className=""
          >
            <div className="flex flex-col md:flex-row gap-4  ">
              <div className="flex gap-4 items-center whitespace-nowrap">
                <BsFillLockFill />
                <h5> Secured payment</h5>
              </div>

              <div className="flex flex-wrap md:gap-6  md:ml-12  w-full h-fit">
                <img
                  className="object-contain"
                  src="/payment/mastercard.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/visa.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/american_express.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/maestro.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/ideal.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/bank.png"
                  alt=""
                />
                <img
                  className="object-contain"
                  src="/payment/klarna.png"
                  alt=""
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-2 mt-2">
              <div className="flex gap-4 items-center whitespace-nowrap">
                <BsTruck className="" />
                <h5>Shipment with trusted partners</h5>
              </div>

              <div className="flex md:gap-6  md:ml-12  w-full h-fit">
                <img className="object-contain" src="/cargo/fedex.png" alt="" />
                <img className="object-contain" src="/cargo/ups.png" alt="" />
                <img
                  className="object-contain"
                  src="/cargo/postnl.png"
                  alt=""
                />
                <img className="object-contain" src="/cargo/dhl.png" alt="" />
              </div>
            </div>
          </CardInnerBorder>

          <button
            onClick={() =>
              items.length > 0
                ? navigate("/checkout")
                : notify("No items in cart", "warning")
            }
            className="bg-gray-lighter pr-2 flex gap-2 items-center justify-center mx-auto mt-10  w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white"
          >
            <BsBagPlusFill className="text-inherit" />
            <h5 className="text-inherit pt-1">Checkout</h5>
          </button>
        </div>
      </Section>
    </Page>
  );
};

export default Cart;

export const CartItem = ({ children, className, product, ...props }: Props) => {
  const [inCart, setInCart] = React.useState(false);
  const [value, setValue] = React.useState(product?.quantity);
  const dispatch = useAppDispatch();

  useEffect(() => {}, [value]);

  if (isMobile) {
    return (
      <div className=" border border-gray rounded-40  cardInner py-4 hover:bg-[#FAFAFA]">
        <div className="grid grid-cols-12  md:px-4 px-2">
          <div className="col-span-2 ">
            <img
              className="object-contain my-auto w-16 h-16"
              src={product?.images[0]}
              alt=""
            />
          </div>
          <div className="col-span-10">
            <div className="flex  flex-col ml-2">
              <h5 className="font-semibold">{product?.brand?.name}</h5>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black">{product?.name}</p>
              </div>
              <div className="flex  justify-around items-center mt-2">
                <StockInfo stock={product?.stock_quantity} className="" />

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name}ml
                </div>

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  100ml
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 flex items-center justify-between  mt-4">
            <IconButtonOutline
              className={`md:ml-3 !border-green !bg-green-lighter `}
            >
              <BsUpcScan className="!bg-gray-lighter !text-green " />
            </IconButtonOutline>
            <h4 className="font-semibold text-xs">
              {priceFormatter(product?.price)}
            </h4>
            <div className="flex items-center gap-2 ">
              <NumberInput
                max={product?.stock_quantity}
                product={product}
                value={value}
                setValue={setValue}
              />

              <IconButtonOutline
                // @ts-ignore
                onClick={() => dispatch(removeItemFromCart(product?.id))}
                className={` hover:!bg-green hover:!text-white !bg-gray-lighter border-green text-green `}
              >
                <BsX className=" " />
              </IconButtonOutline>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-12 border border-gray rounded-40  cardInner hover:bg-[#FAFAFA] px-4">
        <div className="col-span-4 flex items-center gap-4  h-20">
          <Circle className=" productListCardCircle" />

          <div className=" px-2 bg-white  rounded-[20px]">
            <img
              className="object-contain  w-8 h-14 "
              src={product?.images?.[0]}
              alt=""
            />
          </div>

          <div>
            <h5>{product?.brand?.name}</h5>
            <p className="text-black">{product?.name}</p>
          </div>
        </div>
        <div className="col-span-5  flex justify-around items-center text-center ">
          <StockInfo stock={product?.stock_quantity} />
          <div>
            <p className="text-black">Size</p>
            <p className="text-black">{product?.size?.name}ml</p>
          </div>
          <div>
            <p className="text-black">Gender</p>
            <p className="text-black">Unisex</p>
          </div>
          <div>
            <ProductBarcode ean={product?.ean} />
          </div>
        </div>
        <div className="col-span-3 flex justify-between items-center">
          <h4 className="font-semibold">{priceFormatter(product?.price)}</h4>
          <div className="flex items-center gap-4">
            <NumberInput
              max={product?.stock_quantity}
              product={product}
              value={value}
              setValue={setValue}
            />
            <IconButtonOutline
              onClick={() => dispatch(removeItemFromCart(product?.id))}
              className={`   hover:!bg-green hover:!text-white !bg-gray-lighter border-green text-green `}
            >
              <BsX className=" " />
            </IconButtonOutline>
          </div>
        </div>
      </div>
    );
  }
};
