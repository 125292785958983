import React from 'react'
import Page from '../components/Layout/Page'
import Section from '../components/Elements/Semantic/Section'
import CardInnerBorder from '../components/Card/CardInnerBorder'
import ContactForm from '../components/Form/ContactForm'
import { aboutUsContent } from '../constants/content'

type Props = {}

const AboutUs = (props: Props) => {
  return (
    <Page>
        <Section title="About Us" subtitle="About Us">

            <CardInnerBorder  innerCard='px-4 sm:px-10 md:px-20 lg:px-36 py-12 line ' className="mt-12  lg:mx-32 mx-3">
               { 
                aboutUsContent.text
               }
            </CardInnerBorder>

        <CardInnerBorder className="mt-12 lg:mx-32 mx-3">
          <ContactForm title={"Contact Form"} />
        </CardInnerBorder>
            </Section>
    </Page>
  )
}

export default AboutUs