import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async (args: any,thunkAPI) => {
       
        try {
            const response = await axios.put(
                `https://fragrancedistri.com/wp/?rest_route=/simple-jwt-login/v1/user/reset_password&email=${args.email}
                &code=${args.code}&new_password=${args.password}&AUTH_KEY=wiaIwNTkxfQ.bJ0uPaqHk%`
            );
            return response.data;
        } catch (error: any) {
  
            return thunkAPI.rejectWithValue({ error: error.response });
        }
    });