import React, { useEffect } from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import { shopContent } from "../constants/content";
import Card from "../components/Card/Card";
import { ShopSearchInput } from "../components/Input/Input";
import { SelectInput } from "../components/Input/Select";
import Checkbox from "../components/Input/Checkbox";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { BsGridFill, BsHddStackFill, BsPlusLg } from "react-icons/bs";
import { ProductCard, ProductListCard } from "../components/Card/ProductCard";
import { SecondaryButton } from "../components/Button/SecondaryButton";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getProducts } from "../app/Product/actions/getProducts";
import useFilter from "../hooks/useFilter";
import { getAllFilters } from "../app/Filter/actions/getAllFilters";
import { useLocation, useSearchParams } from "react-router-dom";
import usePriceListFilter from "../hooks/usePriceListFilter";
import { IconButtonCircle } from "../components/Button/IconButton";
import ButtonComponent from "../components/Button/ButtonComponent";
import { clearSelectedFilters } from "../app/Filter/filterSlice";
type Props = {};

const Shop = (props: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // @ts-ignore
    dispatch(getAllFilters(Object.fromEntries(searchParams)));
  }, [searchParams]);

  const changeHeaderTitle = () => {
    if (searchParams.get("category") === "22226") return "Fragrances";
    if (searchParams.get("category") === "22220") return "Bath & Body";
    if (searchParams.get("category") === "22337") return "Skin Care";
    if (searchParams.get("category") === "22351") return "Makeup";
    if (searchParams.get("category") === "26642") return "Gift Sets";
    if (searchParams.get("category") === "309") return "Testers";
    else {
      return "All";
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state?.searchParams) {
      setSearchParams(location.state.searchParams);
    }
  }, [location.state, setSearchParams]);

  return (
    <Page>
      <Section
        title={"Catalog | " + changeHeaderTitle() + " Products Wholesale"}
        subtitle="Search, filter or browse our catalog"
      >
        <GenderCheckboxes />
        <FilterMenu />

        <div className="md:flex hidden   mt-8  justify-between w-full border-b-2 border-gray-lighter">
          {shopContent.subCategories.map((item, index) => {
            return (
              <ShopCategoryButton value={item.value} key={index}>
                {item.label}
              </ShopCategoryButton>
            );
          })}
        </div>

        <ProductList />
      </Section>
    </Page>
  );
};

export default Shop;

const ShopCategoryButton = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  className?: string;
  value: any;
}) => {
  const [selected, setSelected] = React.useState(false);

  const { searchParams, setSearchParams } = useFilter();
  const handleSelect = () => {
    if (value === "all") {
      searchParams.forEach((value, key) => {
        searchParams.delete(key);
      });
      setSearchParams(searchParams);
    } else {
      searchParams.set("category", value);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (searchParams.get("category") == value) {
      setSelected(true);
    } else {
      if (value == "all" && searchParams.get("category") == null) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [searchParams]);

  return (
    <div
      className={` pb-4   border-b px-2 group hidden md:block  hover:border-green ${
        selected ? "border-green" : "border-gray-lighter"
      }  `}
    >
      <SecondaryButton
        onClick={handleSelect}
        className={`w-36 h-10 p-0  group-hover:bg-green group-hover:text-white ${
          selected && "bg-green text-white "
        } `}
      >
        {children}
      </SecondaryButton>
    </div>
  );
};

export const GenderCheckboxes = () => {
  const { searchParams, setSearchParams } = usePriceListFilter();

  function handleOptionClick(value: string | number) {
    const title = "pa_gender";
    if (searchParams.getAll(title).includes(value.toString())) {
      const afterDelete = searchParams
        .getAll(title)
        .filter((_value) => _value !== value.toString());
      searchParams.delete(title);
      if (afterDelete.length > 0)
        afterDelete.map((_value) => {
          searchParams.append(title, _value);
        });
      setSearchParams(searchParams);
    } else {
      // @ts-ignore
      searchParams.append(title, value);
      setSearchParams(searchParams);
    }
  }

  return (
    <div className="md:flex gap-14 my-4 w-full   flex-row  hidden ">
      {shopContent.genderCheckboxes.map((item, index) => {
        return (
          <div key={index} className="flex items-center gap-4">
            <Checkbox
              // @ts-ignore
              action={() => handleOptionClick(item.value)}
              type="checkbox"
              className="mr-2"
              name={item.label}
              id={item.label}
            />
            <label className="text-sm" htmlFor={item.label}>
              {item.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const FilterMenu = ({
  children,
  className,
  select,
}: {
  children?: React.ReactNode;
  className?: string;
  select?: boolean;
}) => {
  const { brand_list, color_list, memory_list, category_list, order_list } =
    useAppSelector((state) => state.filter);

  const { searchParams, setSearchParams } = useFilter();
  const dispatch = useAppDispatch();

  const handleClear = () => {
    // @ts-ignore
    dispatch(clearSelectedFilters());

    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  if (isMobile) {
    return (
      <div className=" flex flex-col gap-4 ">
        <ShopSearchInput className="w-full " />

        <div className=" pb-60 -mb-60 flex justify-between  appearance-none  gap-4 overflow-x-scroll overflow-y-visible  hide-scrollbar  pr-4  w-full ">
          <SelectInput
            type="pa_brand"
            options={brand_list}
            placeholder="Brand"
          />
          <SelectInput
            type="pa_color"
            options={color_list}
            placeholder="Color"
          />
          <SelectInput
            type="pa_size"
            options={memory_list}
            placeholder="Size"
          />
          <SelectInput
            type="category"
            options={category_list
              .filter((item: any) => item.id != 315)
              .filter((item: any) => item.name.includes("/"))}
            placeholder="Category"
          />

          <SelectInput
            type="orderby"
            options={order_list}
            placeholder="Order"
          />

          <div className="flex items-center">
            <ButtonComponent
              className={`!w-40 h-10 text-xs border border-gray-lighter md:my-auto flex  items-center justify-center rounded-15 hover:bg-green hover:text-white  `}
              onClick={handleClear}
            >
              Clear
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Card className="flex items-center w-full gap-4  !py-1  px-10 hide-scrollbar   bg-transparent ">
        <ShopSearchInput className="w-full" />
        <div className="flex gap-4 ml-auto">
          <SelectInput
            type="pa_brand"
            options={brand_list}
            placeholder="Brand"
          />
          <SelectInput
            type="pa_color"
            options={color_list}
            placeholder="Color"
          />
          <SelectInput
            type="pa_size"
            options={memory_list}
            placeholder="Size"
          />
          <SelectInput
            type="category"
            options={category_list
              .filter((item: any) => item.id != 315)
              .filter((item: any) => !item.name.includes("/"))}
            placeholder="Category"
          />

          <SelectInput
            type="orderby"
            options={order_list}
            placeholder="Order"
          />

          <ButtonComponent
            className="w-20 h-10 text-xs border my-auto border-gray-lighter rounded-15 hover:bg-green hover:text-white "
            onClick={handleClear}
          >
            Clear
          </ButtonComponent>
        </div>
      </Card>
    );
  }
};

const ProductList = ({}: {}) => {
  const [view, setView] = React.useState<"grid" | "list">("list");
  const { endpoint, searchParams, setSearchParams } = useFilter();
  const dispatch = useAppDispatch();
  const { products, total_pages, total_products } = useAppSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (
      endpoint !==
      "https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?per_page=20"
    ) {
      // @ts-ignore
      dispatch(getProducts(endpoint));
    } else {
      if (Array.from(searchParams).length === 0) {
        dispatch(
          getProducts(
            // @ts-ignore
            "https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?per_page=20&page=3"
          )
        );
      }
    }
  }, [endpoint]);

  const handleAddMoreProduct = () => {
    const perPage = searchParams.get("per_page");
    const perPageValue = perPage ? parseInt(perPage, 10) : 20;
    searchParams.set("per_page", String(perPageValue + 20));
    setSearchParams(searchParams);
  };

  return (
    <div className={`grid md:grid-cols-2 gap-4  md:mt-4   `}>
      <div className="col-span-full flex justify-between items-center">
        <p>{total_products} products result</p>

        <div className="flex">
          <SecondaryButton
            onClick={() => setView("grid")}
            className={`!w-10 !h-10 ${
              view == "grid" ? "bg-white shadow-default" : ""
            }`}
          >
            <BsGridFill
              className={` text-base ${
                view == "grid" ? "text-green-dark " : " text-gray-disabled"
              }`}
            />
          </SecondaryButton>

          <SecondaryButton
            onClick={() => setView("list")}
            className={`!w-10 !h-10 ml-2 ${
              view == "list" ? "bg-white shadow-default" : ""
            } `}
          >
            <BsHddStackFill
              className={` text-base ${
                view == "list" ? "text-green-dark " : "text-gray-disabled"
              }`}
            />
          </SecondaryButton>
        </div>
      </div>
      {view == "grid" ? (
        <>
          {products.map((item, index) => {
            return <ProductCard key={index} product={item} />;
          })}
        </>
      ) : (
        <CardInnerBorder
          innerCard=" !p-0  group-hover:!bg-white "
          className=" col-span-full"
        >
          {products.map((item, index) => {
            return <ProductListCard key={index} product={item} />;
          })}
        </CardInnerBorder>
      )}

      <div className="col-span-full flex justify-center items-center my-8">
        <IconButtonCircle onClick={handleAddMoreProduct} text={"Load more"}>
          <BsPlusLg className="font-bold" />
        </IconButtonCircle>
      </div>
    </div>
  );
};
