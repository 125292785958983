import React from 'react'
import { BsEye, BsEyeFill } from 'react-icons/bs';

function PasswordInput({ ...props }) {
    const [type, setType] = React.useState("password")
    return (
        <div className='relative'>
            <input type={type} className={`h-10 w-full text-xs text-gray-dark bg-gray-bg  border border-gray-light outline-gray-dark rounded-15 px-6  `} {...props} />
            <div className='absolute top-0 right-0 h-full w-10 text-center text-gray-darker flex items-center justify-center'>
                <button type='button' onClick={() => setType(type === "password" ? "text" : "password")} className='focus:outline-none'>
                    {type === "password" ? <BsEye className='' /> : <BsEyeFill className='' />}
                </button>
            </div>
        </div>
    )
}
export default PasswordInput;