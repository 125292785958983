import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

function Slider({ children }: { children: any }) {
  return (
    <div className="relative">
      

      <Swiper
        modules={[Navigation]}
        slidesPerView={8}
        centeredSlides={false}
        spaceBetween={5}
        grabCursor={false}
        breakpoints={{
          // when window width is >= 640px
          0: {
            slidesPerView: 1,
          },
          360: {
            slidesPerView: 2,
          },
            480: {
            slidesPerView: 3,
          },
         
          768: {
            slidesPerView: 5,
          },
          1340: {
            slidesPerView: 8,
          },

          1520: {
            slidesPerView: 8,
          },
        }}
        navigation={{
          nextEl: ".product-swiper-button-next",
          prevEl: ".product-swiper-button-prev",
        }}
        className={"mySwiper  lg:!-my-3 lg:!mx-auto sm:!mx-10 !pb-7"}
      >
       
        {children}

      </Swiper>
    </div>
  );
}

export default Slider;
