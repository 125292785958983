import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signIn } from "./actions/signIn";
import { signOut } from "./actions/signOut";
import { signUp } from "./actions/signUp";
import { updateAddress } from "./actions/updateAddress";
import { getAddresses } from "./actions/getAdresses";
import { toast } from "react-toastify";
import { notify, toastOptions } from "../../utils/notify";
import { sendVerificationCode } from "./actions/sendVerificationCode";
import { resetPassword } from "./actions/resetPassword";
import { checkVatNumber } from "./actions/checkVatNumber";
import { getUserInfo } from "./actions/getUserInfo";
import Cookies from "js-cookie";
import SignUpEvent from "../../utils/google-analytics/events/SignUpEvent";

const initialState = {
  message: "",
  user: (Cookies.get("fd-user") && JSON.parse(Cookies.get("fd-user"))) || null,
  token: Cookies.get("fd-token") || null,
  loading: false,
  error: null,
  loggedIn: Cookies.get("fd-token") ? true : false,
  status: "idle",
  newRegister: false,
  resetPasswordStatus: false,
  isReseted: false,
  differentAddress: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAddressInfo: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      Cookies.set("fd-user", JSON.stringify(state.user));
    },
    setUserInfo: (state, action) => {
      state = { ...state, ...action.payload };
      Cookies.set("fd-user", JSON.stringify(state.user));
    },

    setDifferentAddress: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.differentAddress = true;
      Cookies.set("fd-user", JSON.stringify(state.user));
    },
  },
  extraReducers: (builder) => {
    builder

      /* SIGN IN */
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.loggedIn = true;
        toast("You have successfully signed in.", toastOptions);
        Cookies.set("fd-token", action.payload.token);
        Cookies.set("fd-user", JSON.stringify(action.payload.user));
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        notify(action.payload, "danger");
        state.error = action.payload;
      })

      /* SIGN OUT */
      .addCase(signOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.loggedIn = false;

        localStorage.removeItem("fd-token");
        localStorage.removeItem("fd-user");
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.loggedIn = false;
        toast(action.payload.error.data.data.message, toastOptions);
        localStorage.removeItem("fd-token");
        localStorage.removeItem("fd-user");
      })

      /* SIGN UP */

      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        console.log("true");
        SignUpEvent();
        state.loading = false;
        state.token = action.payload.jwt;
        state.loggedIn = true;
        state.user = action.payload.user;
        state.newRegister = true;
        toast("You have successfully signed up.");
        Cookies.set("fd-token", action.payload.jwt);
        Cookies.set("fd-user", JSON.stringify(action.payload.user));
      })
      .addCase(signUp.rejected, (state, action) => {
        if (
          action.payload.error.data.data.message ===
          "User role is not approved."
        ) {
          toast("User role is not approved.", "error");
        }
        toast(action.payload.error.data.data.message, "error");
      })

      /*  UPDATE ADDRESS */

      .addCase(updateAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.user = { ...state.user, ...action.payload };
        Cookies.set("fd-user", JSON.stringify(state.user));
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /* GET ADDRESSES */

      .addCase(getAddresses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.user = { ...state.user, ...action.payload };
        Cookies.set("fd-user", JSON.stringify(state.user));
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      /* SEND VERIFICATION CODE */

      .addCase(sendVerificationCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendVerificationCode.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "success";
        state.resetPasswordStatus = true;
        toast("Verification code has been sent to your email.", "success");
      })
      .addCase(sendVerificationCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notify("something went wrong", "warning");
      })

      /*  RESET PASSWORD */

      .addCase(resetPassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "success";
        state.resetPasswordStatus = false;
        state.isReseted = true;
        toast("Password has been changed successfully.", "success");
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notify("something went wrong", "warning");
      })

      /*  CHECK VAT */

      .addCase(checkVatNumber.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkVatNumber.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.user.vat_number = action.meta.arg.vat_number;
        state.user.vat_is_valid = action.payload;
      })
      .addCase(checkVatNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.user.vat_number.is_valid = false;
        notify("something went wrong", "warning");
      })

      /*  GET USER INFO */

      .addCase(getUserInfo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        Cookies.set("fd-user", JSON.stringify(state.user));
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        if (state.loggedIn) {
          notify("something went wrong", "warning");
        }
      });
  },
});

export const { setAddressInfo, setUserInfo, setDifferentAddress } =
  userSlice.actions;
export default userSlice.reducer;
