import React from "react";
import { SecondaryInput } from "../Input/Input";
import Modal from "./Modal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateAddress } from "../../app/User/actions/updateAddress";
import { BsChevronDown } from "react-icons/bs";
import SelectCountry from "../Input/SelectCountry";
import { setUserInfo } from "../../app/User/userSlice";

const AddresForm = ({ title, showModal, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const { loggedIn, user } = useAppSelector((state) => state.user || {});
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let address = {};
    let vat_number = {};
    Object.keys(e.target).map((key) => {
      if (e.target[key].name != "" && e.target[key].name != undefined) {
        address = { ...address, [e.target[key].name]: e.target[key].value };
      }
    });
    dispatch(setUserInfo(vat_number));

    // @ts-ignore
    dispatch(updateAddress({ address, change: title }));
    setShowModal(false);
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={title + " Address"}
    >
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <SecondaryInput
          defaultValue={user?.[title]?.first_name}
          required
          name="first_name"
          placeholder="First name*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.last_name}
          required
          name="last_name"
          placeholder="Last name*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.phone}
          required
          name="phone"
          placeholder="Phone*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.company}
          name="company"
          placeholder="Company*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.address_1}
          required
          name="address_1"
          placeholder="Address*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.adress_2}
          name="address_2"
          placeholder="Address 2"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.city}
          required
          name="city"
          placeholder="City*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.postcode}
          required
          name="postcode"
          placeholder="Postcode*"
        />
        <SelectCountry
          defaultValue={user?.[title]?.country}
          required
          name="country"
          placeholder="Country*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.state}
          required
          name="state"
          placeholder="State*"
        />
        {title == "billing" && (
          <SecondaryInput
            defaultValue={user?.vat_number?.number || user?.vat_number}
            required
            name="vat_number"
            placeholder="VAT number*"
          />
        )}

        <div className="col-span-full flex justify-center">
          <button className=" bg-gray-lighter p-3 w-40 rounded-15 text-green hover:bg-green hover:text-white ">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddresForm;
