import React from "react";
import { BsCheckCircleFill, BsPen, BsXCircleFill } from "react-icons/bs";
import { IconButton } from "../Button/IconButton";
import AddresForm from "../Modal/AddresForm";
import CardInnerBorder from "./CardInnerBorder";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { getAddresses } from "../../app/User/actions/getAdresses";
import { checkVatNumber } from "../../app/User/actions/checkVatNumber";

type Props = {};

export const AddresCard = ({ children, className, title, ...props }: any) => {
  const [showModal, setShowModal] = React.useState(false);
  const { loggedIn, user } = useAppSelector((state) => state.user || {});
  const dispatch = useAppDispatch();
  useEffect(() => {
    // @ts-ignore
    dispatch(getAddresses());
  }, []);
  console.log(user);
  return (
    <CardInnerBorder innerCard="py-6 px-9">
      <AddresForm
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
      />
      {user?.[title] ? (
        <div className="grid grid-cols-2">
          <div className="col-span-2 flex justify-between">
            <div>
              <h5>{user?.[title].company}</h5>
              <p className="text-black">{user?.[title].address_1}</p>
              <p className="text-black">
                {user?.[title].postcode}, {user?.[title].city}
              </p>
              <p className="text-black">{user?.[title].postcode.country}</p>
            </div>

            <IconButton onClick={() => setShowModal(true)} className="h-fit">
              <BsPen />
            </IconButton>
          </div>

          <div className="col-span-2 flex justify-between">
            <div className="mt-8">
              <p className="text-black">Email: {user?.[title].email}</p>
              <p className="text-black">Phone: {user?.[title].phone}</p>
            </div>

            <div className="bg-gray-bg rounded-30 flex flex-col justify-center items-center w-52 h-20 border-gray-dark">
              <h5>VAT Number</h5>
              <p className="text-black">
                {user?.vat_number || user?.vat_number.number}
              </p>
              <span className="">
                {" "}
                {user?.vat_is_valid == "true" ? (
                  <div className="flex item-center gap-2">
                    <p>Valid</p>
                    <BsCheckCircleFill className="text-green" />
                  </div>
                ) : (
                  <div className="flex item-center gap-2">
                    <p>Invalid</p>
                    <BsXCircleFill className="text-danger" />
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <h5 className="text-black">You are not logged in</h5>
          <p className="text-black">Please login to see your address</p>

          <div className="flex flex-col  items-center">
            <p className="text-center">
              {" "}
              If you want to checkout as guest, please add your address by
              clicking the button below
            </p>

            <IconButton
              onClick={() => setShowModal(true)}
              className="h-fit text-xs"
            >
              Add Address
            </IconButton>
          </div>
        </div>
      )}
    </CardInnerBorder>
  );
};
