import { platformIcon, pricesIcon, watchIcon, wholesaleIcon } from "./icons";
import { site } from "./websiteInfo";

export const homeContent = {
  brands: {
    title: "Popular Brands",
    subtitle: "View the most popular brands",
    items: [
      {
        id: 26347,
        title: "Burberry",
        hoverImage: "/brands/burberry-white.png",
        image: "/brands/burberry-black.png",
      },
      {
        id: 31392,
        title: "Giorio Armani",
        hoverImage: "/brands/armani-white.png",
        image: "/brands/armani-black.png",
      },
      {
        id: 26639,
        title: "Calvin Klein",
        hoverImage: "/brands/ck-white.png",
        image: "/brands/ck-black.png",
      },
      {
        id: 26456,
        title: "Bvlgari",
        hoverImage: "/brands/bulgari-white.png",
        image: "/brands/bulgari-black.png",
      },
      {
        id: 30404,
        title: "Dolce & Gabbana",
        hoverImage: "/brands/D&G-white.png",
        image: "/brands/D&G-black.png",
      },
      {
        id: 34089,
        title: "Guerlain",
        hoverImage: "/brands/guerlain-white.png",
        image: "/brands/guerlain-black.png",
      },
      {
        id: 34771,
        title: "Hugo Boss",
        hoverImage: "/brands/hugo-white.png",
        image: "/brands/hugo-black.png",
      },
      {
        id: 51374,
        title: "Yves Saint Laurent",
        hoverImage: "/brands/ysl-white.png",
        image: "/brands/ysl-black.png",
      },
    ],
  },
  productSelection: {
    title: "Product Selection",
    subtitle: "Discover our entire product catalog",
  },
  benefits: {
    title: "Our Services Benefits",
    subtitle: "Benefit from our wholesaler experience",
    items: [
      {
        title: "Available Wholesale Stock",
        description:
          "More than 60.000 products in stock and in different categories as Fragrances, Cosmetics, Bath & Body, and more for Women and Men. Select between more than 1000 original brands.",
        icon: wholesaleIcon,
      },
      {
        title: "B2B Supplier Experience",
        description:
          "For years, we have increased our supplier network to provide our customers with the best products at the best prices. We implement a very new B2B sales platform in perfumery and beauty.",
        icon: platformIcon,
      },
      {
        title: "Competitive Prices",
        description:
          "Our catalog offers you a variety of stock at very competitive prices. We compare the different prices on the market to select and offer you the cheapest. Do not search anymore, we compare for you.",
        icon: pricesIcon,
      },
      {
        title: "Regular Updates",
        description:
          "The stock of the Fragrances are regularly updated on our website to make sure to offer you the most updated stocklist. Our strong system keep all product information up to date.",
        icon: watchIcon,
      },
    ],
  },
  subscribe: {
    title: "B2B Stocklist",
    subtitle: "Susbcribe to receive daily updated B2B stocklist",
    text: {
      header: "Newsletter",
      slogan: "Subscribe now and start receiving B2B offers!",
      paragraph:
        "The prices and stocks of our products are regularly updated. We adjust our inventory several times a day to bring you the most current offers on the market.",
      paragraphBold:
        "Subscribe to receive our catalog daily and never miss a thing.",
    },
  },
  about: {
    title: "More About Us",
    subtitle: "We are a wholesale company",
    text: [
      {
        title: "Wide range of products with large stocks",
        paragraphs: [
          "As Fragrance Distri, we are assertive about our product range and the prices we offer. Our expert teams carefully stock our curated collection in our different warehouses. Thanks to online wholesale shopping that provides easy access from all over the world, we offer unique opportunities with over 1000 genuine brands and 50.000 products. All of these are just a click away!",
          " Fragrancedistri is a wholesaler webshop where you can place a wholesale order. We wholesale all brands of perfumes and cosmetics on our site. The prices that you are seeing and buying from are the wholesale prices.",
        ],
      },
      {
        title: "Bulk Orders",
        paragraphs: [
          "Whether you have a small business and need to replenish stock at a competitive price or whether you need large quantities from wide range of products, you can find anything you need and more here at Fragrancedistri.",
        ],
      },
    ],
  },
  faq: [
    {
      question: "What is the minimum order amount?",
      answer: "The minimum order amount is 1.000€.",
    },

    {
      question: "How long does it take for the orders to be delivered?",
      answer:
        "Delivery time for orders is typically 4-5 days, but may vary depending on the location. ",
    },

    {
      question: "Can I cancel my fragrance wholesale order? ",
      answer:
        "Unfortunately, wholesale orders cannot be cancelled once they have been placed. ",
    },

    {
      question: "Can I return my fragrance wholesale order? ",
      answer:
        "Unfortunately, wholesale orders cannot be returned once they have been shipped. ",
    },

    {
      question:
        "What happens if my fragrance wholesale order is damaged or defective? ",
      answer:
        "Unfortunately, there is no warranty or guarantee on fragrance wholesale orders. However, if the products arrive damaged or defective, please contact customer service immediately to discuss possible solutions. ",
    },
    {
      question: "How can I pay for my fragrance wholesale order? ",
      answer:
        "Payment can be made via credit card, bank transfer, or other accepted forms of payment. Please check with the different payment methods to see specific payment options. ",
    },
    {
      question: "Are the products in fragrance wholesale orders original? ",
      answer:
        "All the products on our webshop are original products. We only carry genuine brand name perfumes,colognes and cosmetics.",
    },
    {
      question: "Can the orders be shipped internationally? ",
      answer:
        "Yes, orders can be shipped internationally, but shipping times and costs may vary depending on the destination. Please check with different shipping options. ",
    },
    {
      question: "Who is responsible for customs fees on international orders? ",
      answer:
        "The customer is usually responsible for any customs fees, duties, or taxes that may be imposed by their country's customs department. Please check with the supplier for their specific policies regarding customs fees and international orders.",
    },
    {
      question: "In how many days can I report damage?",
      answer: "You can report the damage within 24 hours after the delivery.",
    },
  ],
};

export const shopContent = {
  section: {
    title: "Find your product",
    subtitle: "Search, filter or browse our catalog",
  },
  genderCheckboxes: [
    {
      label: "Women",
      value: 22302,
    },
    {
      label: "Men",
      value: 22212,
    },
    {
      label: "Unisex",
      value: 22217,
    },
    {
      label: "Kids",
      value: 22386,
    },
  ],
  shopGenderCheckboxes: [
    {
      label: "Women",
      value: 22302,
    },
    {
      label: "Men",
      value: 22212,
    },
    {
      label: "Unisex",
      value: 22217,
    },
    {
      label: "Kids",
      value: 22386,
    },
  ],

  dropdownFilters: [
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Brand",
      value: "pa_brand",
    },
    {
      label: "Size",
      value: "pa_size",
    },
    {
      label: "Sort",
      value: "sort",
    },
  ],
  subCategories: [
    {
      label: "All products",
      value: "all",
    },
    {
      label: "Fragrances",
      value: 22226,
    },
    {
      label: "Bath & Body",
      value: 22220,
    },
    {
      label: "Skin Care",
      value: 22337,
    },
    {
      label: "Makeup",
      value: 22351,
    },
    {
      label: "Gift Sets",
      value: 26642,
    },
    {
      label: "Testers",
      value: 309,
    },
  ],
};

export const aboutUsContent = {
  text: (
    <p className="text-sm font-normal text-black leading-8">
      {" "}
      Welcome to our fragrance wholesale company, where we provide high-quality
      fragrances from top brands at competitive prices. Our inventory includes a
      wide selection of perfumes, colognes, and body sprays for men and women,
      as well as seasonal and niche scents. As a dedicated wholesale company, we
      aim to appeal to everyone with one of the widest product catalogs in the
      market. We specialize in the wholesale of large quantities.
      <br /> As a leading fragrance wholesaler, we understand the importance of
      staying up-to-date with the latest fragrance trends. That's why we
      constantly update our inventory with new and exciting fragrances to ensure
      that our clients have access to the most in-demand scents.
      <br />
      <br /> Our experienced professionals are dedicated to providing
      exceptional customer service and support. We work closely with our clients
      to understand their needs and provide personalized solutions to help them
      succeed.
      <br />
      <br /> At our fragrance wholesale company, we offer competitive pricing
      and flexible payment options to make it easy for businesses of all sizes
      to stock up on popular fragrances and introduce new scents to their
      customers.
      <br />
      <br /> If you're looking for a reliable fragrance wholesaler that offers
      high-quality products, exceptional customer service, and competitive
      pricing, look no further. Contact us today to learn more about our
      products and services and how we can help your business grow.{" "}
    </p>
  ),
};

export const termsAndConditionsContent = {
  text: (
    <p className="text-sm font-normal text-black leading-8">
      Please read the following terms and conditions carefully before using our
      cosmetics and beauty wholesaler website. By accessing or using our
      website, you agree to be bound by these terms and conditions. If you do
      not agree with any part of these terms, please do not use our website.
      <br /> <br />
      <span className="font-semibold">
        1. Product Availability and Description:
      </span>{" "}
      <br />
      a. We strive to ensure that all products listed on our website are
      available for purchase. However, product availability may be subject to
      change without prior notice. We do not guarantee the availability of any
      specific product.
      <br />
      b. We make every effort to accurately display the colors, descriptions,
      and images of our products. However, we cannot guarantee that the
      representation of products on our website is entirely accurate, as
      individual monitors may display colors differently.
      <br /> <br />
      <span className="font-semibold">2. Ordering and Pricing:</span> <br />
      a. To place anorder, you must be of legal age in your jurisdiction.
      <br />
      b. The prices displayed on our website are in EURO and do not include
      applicable taxes or shipping charges.
      <br />
      c. We reserve the right to change the prices of our products at any time
      without prior notice.
      <br />
      d. In the event that a product is listed at an incorrect price, we reserve
      the right to refuse or cancel any orders placed for that product, whether
      or not the order has been confirmed.
      <br /> <br />
      <span className="font-semibold">3. Payment and Security:</span>
      <br />
      a. Payment for all orders must be made in full before the order is
      processed and shipped.
      <br />
      b. We accept payment through the methods specified on our website.
      <br />
      We use industry-standard security measures t protect your payment
      information. However, we cannot guarantee the security of data transmitted
      over the internet, and you acknowledge that you provide your payment
      information at your own risk.
      <br /> <br />
      <span className="font-semibold">4. Shipping and Delivery:</span>
      <br />
      a. We strive to process and ship all orders in a timely manner. However,
      we do not guarantee specific delivery times and are not responsible for
      any delays caused by third-party shipping carriers.
      <br />
      b. The risk of loss or damage to the products passes to you upon delivery.
      It is your responsibility to inspect the products upon receipt and report
      any damages within 24 hours of delivery.
      <br /> <br />
      <span className="font-semibold">5. Returns and Exchanges:</span>
      <br />
      a. All sales are final, and we do not accept returns or exchanges. We
      encourage you to carefully review your order before making a purchase.
      <br />
      b. In the event that you receive damaged or defective products, please
      report the issue within 24 hours of delivery. We may request photographic
      evidence of the damage before determining the appropriate resolution.
      <br /> <br />
      <span className="font-semibold">6. Customs and Duties:</span>
      <br />
      a. If your order is subject to customs fees or import duties, these
      charges will be your responsibility. You are advised to check with your
      local customs office for more information on potential fees before placing
      an order.
      <br />
      b. We are not responsible for any customs fees, import duties, or related
      charges incurred on international orders.
      <br /> <br />
      <span className="font-semibold">7. Intellectual Property:</span>
      <br />
      a. All content on our website, including butnot limited to text, graphics,
      logos, images, and product descriptions, is the property of our company
      and protected by intellectual property laws.
      <br />
      b. You may not reproduce, distribute, modify, or otherwise use any content
      from our website without our prior written consent.
      <br /> <br />
      <span className="font-semibold">8. Limitation of Liability:</span>
      <br />
      a. To the maximum extent permitted by law, we shall not be liable for any
      direct,indirect, incidental, consequential, or punitive damages arising
      out of or related to the use of our website or the purchase and use of our
      products.
      <br />
      b. We make no warranties or representations, express or implied, about the
      accuracy, reliability, or suitability of the information and products
      available on our website.
      <br /> <br />
      <span className="font-semibold">9. Governing Law and Jurisdiction:</span>
      <br />
      a. These termsand conditions shall be governed by and constred in
      accordance with the laws of The Netherlands.
    </p>
  ),
};
