import React from 'react'
import Card, { CardProps } from './Card'



const CardInnerBorder = ({children, rounded = "rounded-40", className , innerCard , ...props}: CardProps & {innerCard?: string}) => {
  return (
    <Card {...props} className={  className + " group"} >
         <div className={`border border-gray-light  group-hover:bg-[#FAFAFA]   p-3 ${rounded}  ${innerCard} `}>
            {children}
        </div>
    </Card>
  )
}
export default CardInnerBorder






