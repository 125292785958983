import React from "react";
import { SecondaryButton } from "../Button/SecondaryButton";
import { IconButton } from "../Button/IconButton";
import { Bs4SquareFill, BsChevronDown, BsChevronUp } from "react-icons/bs";

type Props = {
    title: string;
    button?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Accordion = ({title, children ,button}: Props) => {
  const [show, setShow] = React.useState(true);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center border-b-2  border-gray-light mb-4">
        <div className="border-b-2 pb-4 border-green">
          <SecondaryButton active className="w-72 h-10">
            <h5> {title}</h5>
          </SecondaryButton>
        </div>
         {
          button ? <div className="flex gap-2">

          {button}

<IconButton onClick={() => setShow((prev) => !prev)}>
          {show ? (
            <BsChevronUp className="text-inherit" />
          ) : (
            <BsChevronDown className="text-inherit" />
          )}
        </IconButton>
          </div> : <IconButton onClick={() => setShow((prev) => !prev)}>
          {show ? (
            <BsChevronUp className="text-inherit" />
          ) : (
            <BsChevronDown className="text-inherit" />
          )}
        </IconButton>
         }
      </div>

       { show && children }
    </div>
  );
};

export default Accordion;
