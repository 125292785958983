import React from 'react'

type Props = { stock: number | undefined } & React.InputHTMLAttributes<HTMLInputElement>;

const StockInfo = ({ stock = 0, className }: Props) => {
  const bg_c = () => {
    if (stock > 10) return " !bg-success "
    else if (stock > 5) return " !bg-warning "
    else return " !bg-danger "
  }
  const text_c = () => {
    if (stock > 10) return " !text-success "
    else if (stock > 5) return " !text-warning "
    else return " !text-danger "
  }
  return (
    <div className={`flex items-center  gap-2 ${className}`}>
      <div className={"w-3 h-3 bg-success rounded-full" + bg_c()} />
      <p className={`text-xs text-success ${text_c()} `}>{stock < 0 ? 0 : stock} In Stock</p>
    </div>
  )
}

export default StockInfo