import Page from '../components/Layout/Page';
import { termsAndConditionsContent } from '../constants/content';
import Section from '../components/Elements/Semantic/Section';
import CardInnerBorder from '../components/Card/CardInnerBorder';

function TermsConditions() {

    return (
        <Page>
            <Section title="Terms And Conditions" subtitle="">
                <CardInnerBorder innerCard='px-4 sm:px-10 md:px-20 lg:px-36 py-12 line ' className="mt-12  lg:mx-32 mx-3">
                    {
                        termsAndConditionsContent.text
                    }
                </CardInnerBorder>
            </Section>
        </Page>
    )
}

export default TermsConditions;