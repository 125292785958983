import { Link } from "react-router-dom";
import { EmailInput } from "../../Input/Input";
import {
  BsArrowRight,
  BsEnvelopeFill,
  BsInstagram,
  BsPhoneFill,
  BsTelephoneFill,
} from "react-icons/bs";
import {
  Address,
  Facebook,
  Instagram,
  Linkedin,
  Logo,
  Phone,
} from "../../../constants/StaticComponents";
// @ts-ignore
import { HashLink } from "react-router-hash-link";
type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="mt-20">
      <div className=" bg-white rounded-t-50 px-2 pt-2 md:px-4 md:pt-4  ">
        <div className="w-full bg-gray-lighter  flex justify-center gap-8 md:gap-16 rounded-t-40 py-6">
          <Instagram />
          <Facebook />
          <Linkedin />
          <Link to={"/contact"} className="text-green text-2xl">
            <BsEnvelopeFill className="text-green text-2xl" />
          </Link>
          <Link to={"/contact"} className="text-green text-2xl">
            <BsTelephoneFill className="text-green text-2xl" />
          </Link>
        </div>
      </div>
      <div className="bg-green ">
        <div className="default-container  grid  md:grid-cols-5  w-full  py-12">
          <div className="text-sm order-2 md:order-1 col-span-1  mt-8 flex gap-4">
            <div className="w-1/2">
              <img src="/logo/logo_white2.png" alt="" />
              <p className="text-white mt-4 text-base">Fragrance Distri</p>

              <Address className="text-white text-xs" />
            </div>
            <div className="flex flex-col gap-4 items-center md:items-start">
              <Link to={"/about-us"} className="text-white">
                About Us
              </Link>
              <Link to={"/catalog"} className="text-white">
                Catalog
              </Link>
              <Link to={"/my-account"} className="text-white">
                My account
              </Link>
              <HashLink to={"/#faq"} className="text-white">
                FAQ
              </HashLink>
            </div>
          </div>
          <div className="border order-1  col-span-1 md:col-span-3 md:order-2 border-white text-white py-10 px-12 rounded-50 flex flex-col gap-4 items-center">
            <h2>Get Updates</h2>

            <span className="text-sm  justify-center">
              Subscribe to our newsletter to receive updates and special
              announcements.
            </span>

            <EmailInput
              placeholder="Enter your email"
              className="w-full mt-4"
            />
          </div>
          <div className="flex   cols-span-1 justify-center  md:order-3 order-3 md:justify-end">
            <div className="text-sm  font-medium flex flex-col gap-6 mt-8  ">
              <div className=" flex gap-4 items-center text-end ">
                <Link to={"/contact"} className=" flex gap-4 text-white">
                  <span>Send us a message</span>
                </Link>
                <BsArrowRight className="text-white" />
              </div>

              <Phone className="text-white justify-start text-end" />

              <div className="text-white flex flex-col gap-1 text-end">
                <h4>Online support</h4>
                <p className="text-white">From Mon - Fri 09:30 to 17:00 </p>
                <p className="text-white">Saturday from 10:00 to 15:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green-dark">
        <div className="default-container flex flex-col md:flex-row gap-2 md:gap-0 items-center justify-between w-full py-6 text-white text-xs ">
          <div>© 2023 Fragrancedistri. All right reserved</div>

          <div className="flex justify-between "></div>

          <div className=" flex  gap-4">
            <Link to={"/terms-conditions"} className="text-white">
              Terms & Conditions
            </Link>

            <Link to={"/privacy-policy"} className="text-white">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
