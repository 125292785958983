
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllProducts = createAsyncThunk(
    "products/getAllProducts",
    async (args, { rejectWithValue }) => {
        try {
            const response = await axios.get("https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?per_page=-1");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);