/*  single product slice  */
import { createSlice } from "@reduxjs/toolkit";
import { getSingleProduct } from "../actions/getSingleProduct";
import { getRelatedProducts } from "../actions/getRelatedProducts";

const initialState = {
  product: {
    id: "",
    name: "",
    price: 0,
    sku: "",
    description: "",
    brand: {
      id: 0,
      name: "",
      letter: "",
    },
    size: {
      id: 0,
      name: "",
    },
    color: {
      id: 0,
      name: "",
    },
    ean: "",
    stock_quantity: 0,
    images: [
    ],
    categories: [
      {
        id: 0,
        name: "",
      },
      {
        id: 0,
        name: "",
      },
      {
        id: 0,
        name: "",
      },
      {
        id: 0,
        name: "",
      },
    ],
    related_products: [
      {
        id: 0,
        name: "",
        price: 0,
        image: "",
      },
    ],
    gender: {
      name: "",
      id: 0,
    }
  },
  loading: false,
  error: null,
  relatedProducts: [],
};

const singleProductSlice = createSlice({
  name: "singleProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSingleProduct.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleProduct.fulfilled, (state, action) => {
      state.product = action.payload;
      state.loading = false;
    });
    builder.addCase(getSingleProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getRelatedProducts.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRelatedProducts.fulfilled, (state, action) => {
      state.relatedProducts = action.payload;
      state.loading = false;
    });
    builder.addCase(getRelatedProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default singleProductSlice.reducer;
