import {createSlice} from "@reduxjs/toolkit"
import { searchProductsInline } from "../actions/searchProductsInline"


const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchResult : [] ,
        category : null,
        isLoading: false
    },
    reducers : {
        clearSearch : (state) =>{
            state.searchResult = []
        },
        setCategory : (state, action) =>{
            state.category = action.payload
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(searchProductsInline.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(searchProductsInline.fulfilled, (state, {payload, meta}) => {
               
                state.searchResult = payload.products
                state.isLoading = false
            })
            .addCase(searchProductsInline.rejected, (state, action) => {
                state.isLoading = false
            })
    },

})

export default searchSlice.reducer;
export const { clearSearch, setCategory} = searchSlice.actions