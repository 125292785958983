import InitializeGoogleAnalytics, { TrackGoogleAnalyticsEvent } from "..";

const NewsletterEvent = () => {
  InitializeGoogleAnalytics();
  TrackGoogleAnalyticsEvent(
    "Newsletter",
    "Newsletter_Submit",
    "Newsletter Form Submitted"
  );
};

export default NewsletterEvent;
