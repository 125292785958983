import React from 'react'
import { BsX } from 'react-icons/bs'

type Props = {} & React.HTMLAttributes<HTMLDivElement>

const Alert = ({children, className}: Props) => {
    const [show, setShow] = React.useState(true)

  return (
    <div className={` px-10 py-6  w-full shadow-default rounded-[24px]  flex gap-2  flex-col md:flex-row ${!show && "hidden" }   ${className}`}>
        
          <div className='order-2 md:order-1'> 
           { children}
          </div>
        

        <div onClick={() => setShow(false)} className=' ml-auto flex items-center text-green order-1'>
            <a className='underline   text-xs' >
                Hide
            </a>
            <BsX className='' />
        </div>
    </div>
  )
}

export default Alert