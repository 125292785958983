import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const signOut = createAsyncThunk(
  "user/signOutUser",
  async (args, thunkAPI) => {
    const token = Cookies.get("fd-token");

    try {
      const response = await axios.post(
        "https://www.fragrancedistri.com/wp/wp?rest_route=/simple-jwt-login/v1/auth/revoke&AUTH_KEY=wiaIwNTkxfQ.bJ0uPaqHk%",
        { jwt: token }
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
