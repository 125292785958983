import React from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import { SecondaryButton } from "../components/Button/SecondaryButton";
import {
  BsBagPlusFill,
  BsCheck,
  BsChevronDown,
  BsChevronUp,
  BsCloudDownloadFill,
  BsPlusLg,
  BsTruck,
  BsUpcScan,
} from "react-icons/bs";
import {
  IconButton,
  IconButtonCircle,
  IconButtonOutline,
} from "../components/Button/IconButton";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import OrderStatus from "../components/Common/OrderStatus";
import Circle from "../components/Common/Circle";
import StockInfo from "../components/Common/StockInfo";
import Card from "../components/Card/Card";
import { SecondaryInput } from "../components/Input/Input";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { getOrders } from "../app/Order/actions/getOrders";
import Modal from "../components/Modal/Modal";
import { OrderType } from "../types/Order";
import ProductBarcode from "../components/Common/ProductBarcode";
import { AddresCard } from "../components/Card/AddressCard";
import { addItemToCart } from "../app/Cart/cartSlice";
import NumberInput from "../components/Input/NumberInput";
import { removeFromWishlist } from "../app/Wishlist/wishlistSlice";
import { resetPassword } from "../app/User/actions/resetPassword";
import { sendVerificationCode } from "../app/User/actions/sendVerificationCode";
import PasswordInput from "../components/Input/PasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import priceFormatter from "../utils/priceFormatter";
import { signOut } from "../app/User/actions/signOut";

const MyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(
    location.search.replace("?", "") || "info"
  );
  console.log(useLocation().search);

  useEffect(() => {
    const currentTab = location.search.replace("?", "");
    if (currentTab !== selectedTab) {
      setSelectedTab(currentTab || "info");
    }
  }, [location]);

  const handleTabChange = (tab: string) => {
    navigate(`?${tab}`);
    setSelectedTab(tab);
  };

  useEffect(() => {
    const handleLocationChange = () => {
      const newTab = location.search.replace("?", "");
      setSelectedTab(newTab || "info");
    };

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, [location.search]);

  const renderTabContent = () => {
    switch (selectedTab) {
      case "info":
        return <InfoTab />;
      case "change":
        return <ChangeTab />;
      case "wishlist":
        return <WishlistTab />;
      case "orders":
        return <OrdersTab />;
      default:
        return null;
    }
  };

  return (
    <Page>
      <Section
        title="My account"
        subtitle="View and manage your account information"
      >
        <div className="grid grid-cols-4 my-4">
          <div
            className={`w-full border-b-2 h-fit hover:border-green  pr-1 md:pr-2 ${
              selectedTab == "info" ? "border-green" : "border-gray-light "
            }`}
          >
            <SecondaryButton
              onClick={() => handleTabChange("info")}
              className={`mb-4 w-full h-10 text-[9px] md:font-normal md:text-xs   hover: bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
                selectedTab == "info"
                  ? " bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] !font-bold  "
                  : ""
              }`}
            >
              Profile
            </SecondaryButton>
          </div>

          <div
            className={`w-full border-b-2 h-fit hover:border-green border-gray-light px-1 md:px-2  ${
              selectedTab == "orders" ? "border-green" : "border-gray-light "
            } `}
          >
            <SecondaryButton
              onClick={() => handleTabChange("orders")}
              className={`mb-4 w-full h-10 text-[9px] md:font-normal md:text-xs  text-xs hover:bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
                selectedTab == "orders"
                  ? "bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] !font-bold"
                  : ""
              }`}
            >
              My orders
            </SecondaryButton>
          </div>

          <div
            className={`w-full border-b-2 h-fit hover:border-green px-2 md:px-2 ${
              selectedTab == "change" ? "border-green" : "border-gray-light "
            }`}
          >
            <SecondaryButton
              onClick={() => handleTabChange("change")}
              className={`mb-4 w-full h-10 text-[9px] md:font-normal md:text-xs  text-xs  hover:bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
                selectedTab == "change"
                  ? " bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] !font-bold  "
                  : ""
              }`}
            >
              Login
            </SecondaryButton>
          </div>

          <div
            className={`w-full border-b-2 h-fit hover:border-green border-gray-light pl-1 md:pl-2  ${
              selectedTab == "wishlist" ? "border-green" : "border-gray-light "
            } `}
          >
            <SecondaryButton
              onClick={() => handleTabChange("wishlist")}
              className={`mb-4 w-full h-10 text-[9px] md:font-normal md:text-xs  text-xs hover:bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
                selectedTab == "wishlist"
                  ? "bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] !font-bold"
                  : ""
              }`}
            >
              Wishlist
            </SecondaryButton>
          </div>
        </div>
        {renderTabContent()}
      </Section>
    </Page>
  );
};

export default MyAccount;

const InfoTab = () => {
  return (
    <div className="grid md:grid-cols-2 gap-4 ">
      <div>
        <h5 className="text-gray-dark my-4 pl-6">Billing Address</h5>
        <AddresCard title={"billing"} />
      </div>

      <div>
        <h5 className="text-gray-dark my-4 pl-6">Shipping Address</h5>
        <AddresCard title={"shipping"} />
      </div>
    </div>
  );
};

const OrdersTab = () => {
  const { orders, filteredOrders, showFiltered } = useAppSelector(
    (state) => state.order
  );
  const user_id = useAppSelector((state) => state.user.user.user_id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // @ts-ignore
    dispatch(getOrders(user_id));
  }, []);

  return (
    <div className=" flex flex-col  gap-4 mt-8">
      {orders.length > 0 ? (
        // @ts-ignore
        orders.map((order) => <Order order={order} />)
      ) : (
        <h5 className="text-center">No orders yet</h5>
      )}

      <div className="flex justify-center mt-8 mb-12">
        {" "}
        <IconButtonCircle text={"Load more"}>
          <BsPlusLg className="font-bold" />
        </IconButtonCircle>
      </div>
    </div>
  );
};

const WishlistTab = () => {
  const products = useAppSelector((state) => state.wishlist.products);

  return (
    <div>
      <CardInnerBorder
        innerCard="p-0  group-hover:!bg-white"
        className=" col-span-full"
      >
        {products.length > 0 ? (
          products.map((product: any) => <WishlistItem product={product} />)
        ) : (
          <h5 className="text-center">No products in wishlist</h5>
        )}
      </CardInnerBorder>
    </div>
  );
};

const ChangeTab = () => {
  const { user } = useAppSelector((state) => state.user);
  const { resetPasswordStatus } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    dispatch(
      resetPassword({
        email: user?.email,
        password: e.target.password.value,
        code: e.target.code.value,
      })
    );
  };
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <CardInnerBorder innerCard="py-6 px-8">
        <h5 className="mb-4">Reset Email Address</h5>

        <form className="flex flex-col gap-4">
          <SecondaryInput defaultValue={user?.email} />
          <SecondaryInput required placeholder="New email address*" />
          <SecondaryInput required placeholder="Confirm new email address*" />

          <button className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-4 w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white">
            <h5 className="text-inherit">Save</h5>
          </button>
        </form>
      </CardInnerBorder>
      <CardInnerBorder innerCard="py-6 px-8 h-full">
        <h5 className="mb-4">Reset Password</h5>

        {resetPasswordStatus ? (
          <form onSubmit={handleResetPassword} className="flex flex-col  gap-4">
            <PasswordInput
              name="password"
              required
              placeholder="New password*"
            />
            <PasswordInput
              name="password_confirm"
              required
              placeholder="Confirm new password*"
            />
            <SecondaryInput
              name="code"
              required
              placeholder="Verification code*"
            />
            <button className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-4 w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white">
              <h5 className="text-inherit">Save</h5>
            </button>
          </form>
        ) : (
          <button
            onClick={() =>
              dispatch(sendVerificationCode({ email: user.email }))
            }
            className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-4 w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white"
          >
            <h5 className="text-inherit">Send verification code</h5>
          </button>
        )}
      </CardInnerBorder>

      <div className="md:col-span-2 flex justify-center mt-4">
        <button
          onClick={() => dispatch(signOut())}
          className="bg-danger/20  flex gap-4 items-center justify-center mx-auto my-4 w-60 h-11 rounded-15 text-danger hover:bg-danger hover:text-white"
        >
          <h5 className="text-inherit">Logout</h5>
        </button>
      </div>
    </div>
  );
};

const Order = ({ order }: { order: OrderType }) => {
  const [show, setShow] = React.useState(false);

  return (
    <div className="w-full">
      <div
        onClick={() => setShow((prev) => !prev)}
        className="flex justify-between items-center border-b  border-gray-light mb-4"
      >
        <div className="border-b  pb-4 border-green">
          <button
            className={`flex items-center justify-between  gap-2 md:gap-0 rounded-15 bg-white shadow-default md:w-72 h-10 md:px-8 px-2 w-fit `}
          >
            <h5 className="text-[9px] font-semibold md:text-xs">
              Order #{order.id}
            </h5>
            <OrderStatus status={order.status} />
          </button>
        </div>

        <div className="flex items-center gap-2 md:gap-4 pb-4">
          <p className="hidden md:block">
            <span className="text-gray-dark font-semibold ">
              {" "}
              € {order.total}{" "}
            </span>{" "}
            {order.date.date.split(" ")[0]}
          </p>
          <IconButton>
            <BsCloudDownloadFill className="text-inherit " />
          </IconButton>

          <IconButton>
            <BsTruck className="text-inherit" />
          </IconButton>
          <IconButton onClick={() => setShow((prev) => !prev)}>
            {show ? (
              <BsChevronUp className="text-inherit" />
            ) : (
              <BsChevronDown className="text-inherit" />
            )}
          </IconButton>
        </div>
      </div>

      {show && (
        <Card className=" md:!p-0">
          <CardInnerBorder
            innerCard=" !p-0  group-hover:!bg-white"
            className=" col-span-full"
          >
            {order.products.map((product) => (
              <OrderItem product={product} />
            ))}
          </CardInnerBorder>

          <div className="md:grid md:grid-cols-12 my-10 pb-4 px-4">
            <div className=" md:col-start-8 md:col-end-11 flex flex-col gap-1 px-4 md:px-0">
              <div className="flex justify-between">
                <h5>SubTotal</h5>
                <h5>€ {order.subtotal}</h5>
              </div>

              <div className="flex justify-between">
                <h5>VAT cost (21%)</h5>
                <h5> € {order.tax}</h5>
              </div>

              <div className="flex justify-between">
                <h4 className="font-semibold">Total</h4>
                <h4 className="font-semibold"> € {order.total}</h4>
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

const OrderItem = ({
  children,
  className,
  product,
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
  product: any;
}) => {
  const [inCart, setInCart] = React.useState(false);

  if (isMobile) {
    return (
      <div className=" border border-gray rounded-40  cardInner py-4 hover:bg-[#FAFAFA]">
        <div className="grid grid-cols-12 px-2 md:px-4">
          <div className="col-span-2 ">
            <img
              className="object-contain my-auto w-16 h-16"
              src={product?.image}
              alt=""
            />
          </div>
          <div className="col-span-10">
            <div className="flex  flex-col ml-2">
              <h5 className="font-semibold">{product?.brand}</h5>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black">{product?.name}</p>
              </div>
            </div>
            <div className="flex  justify-around items-center mt-2">
              <div className="px-4 py-1 text-[9px] md:font-normal md:text-xs flex ite ms-center justify-center border-gray-light bg-gray rounded-15 ">
                {product.quantity + " pieces"}
              </div>

              <div className="px-4 py-1 text-[9px] md:font-normal md:text-xs flex ite ms-center justify-center border-gray-light bg-gray rounded-15 "></div>
            </div>
          </div>

          <div className="col-span-12 flex items-center md:justify-between justify-center  flex-row-reverse md:flex-row gap-4  mt-4">
            <IconButtonOutline
              className={`md:ml-3 !border-green !bg-green-lighter `}
            >
              <BsUpcScan className="!bg-gray-lighter !text-green " />
            </IconButtonOutline>
            <h4 className="font-semibold">{priceFormatter(product?.price)}</h4>
            <h5>{product.quantity + " pieces"}</h5>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-12 border border-gray rounded-40  cardInner hover:bg-[#FAFAFA] px-4">
        <div className="col-span-4 flex items-center gap-4  h-20">
          <Circle className=" productListCardCircle" />

          <div className=" px-2 bg-white  rounded-[20px]">
            <img
              className="object-contain  w-8 h-14 "
              src={product?.image}
              alt=""
            />
          </div>

          <div>
            <h5>{product?.brand}</h5>
            <p className="text-black">{product?.name}</p>
          </div>
        </div>
        <div className="col-span-5  flex justify-around items-center text-center ">
          <div>
            <p className="text-black">Size</p>
            <p className="text-black">{product.size}</p>
          </div>
          <div>
            <p className="text-black">Gender</p>
            <p className="text-black">{product.gender}</p>
          </div>
          <div>
            <ProductBarcode ean={product?.ean} />
          </div>
        </div>
        <div className="col-span-3 flex justify-around items-center">
          <h4 className="font-semibold">€ {product.price}</h4>
          <h5>{product.quantity + " pieces"}</h5>
        </div>
      </div>
    );
  }
};

const WishlistItem = ({ children, className, product, ...props }: any) => {
  const [inCart, setInCart] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.cart.items);
  const [showModal, setShowModal] = React.useState(false);
  const [isInWishlist, setIsInWishlist] = React.useState(false);
  const wishlistItems = useAppSelector((state) => state.wishlist.products);

  useEffect(() => {
    if (product) {
      // @ts-ignore
      const cartItem = cartItems.find((item) => item.id == product.id);
      if (cartItem) {
        // @ts-ignore
        setValue(cartItem.quantity);
      }
    }
  }, [product]);
  const handleAddToCart = () => {
    dispatch(addItemToCart({ item: product, quantity: value }));
    setInCart(true);
    setTimeout(() => {
      setInCart(false);
    }, 500);
  };

  useEffect(() => {
    wishlistItems.forEach((item: any) => {
      if (item.id === product.id) {
        setIsInWishlist(true);
      }
    });
  }, []);

  if (isMobile) {
    return (
      <div className=" border border-gray rounded-40  cardInner py-4 hover:bg-[#FAFAFA]">
        <Modal
          title={"Scan Product"}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <div className="flex justify-center flex-col items-center">
            <ProductBarcode ean={product?.ean} />

            <p className="text-danger text-xs pt-8">
              You need to use your mobile device camera to scan the product you
              would like to search.
            </p>
          </div>
        </Modal>
        <div className="grid grid-cols-12  md:px-4 px-2">
          <div className="col-span-2 ">
            <img
              className="object-contain my-auto w-16 h-16"
              src={product?.images[0]}
              alt=""
            />
          </div>
          <div className="col-span-10">
            <div className="flex  flex-col ml-2">
              <h5 className="font-semibold">{product?.brand?.name}</h5>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black">{product?.name}</p>
              </div>
              <div className="flex  justify-around items-center mt-2">
                <StockInfo stock={product?.stock_quantity} className="" />

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name} ml
                </div>

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name} ml
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 flex items-center justify-between  mt-4">
            <IconButtonOutline
              onClick={() => setShowModal(true)}
              className={`md:ml-3 !border-green !bg-green-lighter `}
            >
              <BsUpcScan className="!bg-gray-lighter !text-green " />
            </IconButtonOutline>
            <h4 className="font-semibold text-xs">
              {priceFormatter(product?.price)}
            </h4>
            <div className="flex items-center  ">
              {inCart ? (
                <div className="md:w-36 w-32 h-10 border border-green  rounded-[17px]  flex items-center justify-center ">
                  <BsCheck className="mr-2" />
                  <span className="text-xs">Added to cart</span>
                </div>
              ) : (
                <NumberInput
                  max={product?.stock_quantity}
                  value={value}
                  setValue={setValue}
                />
              )}
              <IconButtonOutline
                onClick={handleAddToCart}
                className={`ml-3 border-green ${
                  inCart ? "!bg-green !text-white" : ""
                }`}
              >
                <BsBagPlusFill className=" " />
              </IconButtonOutline>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-12 border border-gray rounded-40  cardInner hover:bg-[#FAFAFA] px-4">
        <div className="col-span-4 flex items-center gap-4  h-20">
          <Circle
            onClick={() => dispatch(removeFromWishlist(product))}
            className={` ${isInWishlist ? "bg-green" : ""} `}
          />

          <div className="px-2 bg-white  rounded-[20px]">
            <img
              className="object-contain  w-8 h-14 "
              src={product?.images[0]}
              alt=""
            />
          </div>

          <div>
            <h5>{product?.brand?.name}</h5>
            <p className="text-black">{product?.name}</p>
          </div>
        </div>
        <div className="col-span-5  flex justify-around items-center text-center ">
          <StockInfo stock={product?.stock_quantity} />
          <div>
            <p className="text-black">Size</p>
            <p className="text-black">100ml</p>
          </div>
          <div>
            <p className="text-black">Gender</p>
            <p className="text-black">Unisex</p>
          </div>
          <div>
            <ProductBarcode ean={product?.ean} />
          </div>
        </div>
        <div className="col-span-3 flex justify-around items-center">
          <h4 className="font-semibold">{priceFormatter(product?.price)}</h4>
          <div className="flex items-center">
            <NumberInput
              max={product?.stock_quantity}
              small
              value={value}
              setValue={setValue}
            />
            <IconButtonOutline
              onClick={handleAddToCart}
              className={`ml-3 ${
                inCart
                  ? "!bg-green !text-white"
                  : "!bg-gray-lighter border-green text-green"
              }`}
            >
              <BsBagPlusFill />
            </IconButtonOutline>
          </div>
        </div>
      </div>
    );
  }
};
