import { useState } from 'react';
import countries, { european_countries } from '../../constants/countries';
import { BsChevronDown } from 'react-icons/bs';
type Props = {} & React.InputHTMLAttributes<HTMLSelectElement>;




const SelectCountry = ({ className, name, defaultValue, ...props }: Props) => {
    const [country, setCountry] = useState(defaultValue || "NL");
    return (
        <div className="flex justify-between">
            <select
            {...props}
                className={`h-10 w-full text-xs text-gray-dark bg-gray-bg  border-y border-l border-gray-light outline-gray-dark rounded-l-15 px-6  ${className} `}
                name={name}
                placeholder={"placeholder"}
                defaultValue={defaultValue}
            >
                {countries.map((country: any) => {
                    return <option className="text-start" value={country[1]}>{country[0]}</option>;
                })}
            </select>
            <div className="pr-4 flex place-items-center bg-gray-bg w-fit h-full rounded-r-15 border-r border-y border-gray-light ">
                <BsChevronDown className="text-green text-sm " />
            </div>
        </div>
    );
}

export default SelectCountry;   