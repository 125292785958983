export const paymentMethods = [
  {
    name: "Bank Transfer",
    id: "BANKTRANS",
  },
  {
    name: "Master Card",
    id: "MASTERCARD",
  },
  {
    name: "Visa",
    id: "VISA",
  },
  {
    name: "American Express",
    id: "AMEX",
  },
 /*  {
    name: "Klarna",
    id: "KLARNA",
  }, */
  {
    name: "Ideal",
    id: "IDEAL",
  },
  {
    name: "Apple Pay",
    id: "APPLEPAY",
  },
  {
    name: "Google Pay",
    id: "GOOGLEPAY",
  },
];
