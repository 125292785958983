import { Bs0Circle, BsCheck, BsExclamation, BsExclamationTriangleFill } from "react-icons/bs";
import {toast} from "react-toastify";

export const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",

}

export const notify = (message, status = "success") =>  toast(
    <div className="flex items-center">
        <div className={`w-8 h-8  ${status == "success" ? "bg-success" : "bg-danger"}  flex items-center justify-center rounded-full mr-3`}>
            {
                status === "success" ? <BsCheck className="text-white" /> : <BsExclamationTriangleFill className="text-white" />
            }
        </div>
      <div className="text-sm">
      { message}
      </div>
    </div>, toastOptions);