import Page from "../components/Layout/Page";
import { homeContent } from "../constants/content";
import Section from "../components/Elements/Semantic/Section";
import Card from "../components/Card/Card";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import { cardAnimation } from "../constants/animationConfig";
import { EmailInput } from "../components/Input/Input";
import { title } from "process";
import { ProductCard, ProductListCard } from "../components/Card/ProductCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import Accordion from "../components/Accordion/Accordion";
import { useEffect, useState } from "react";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { IconButton } from "../components/Button/IconButton";
import { SecondaryButton } from "../components/Button/SecondaryButton";
import Circle from "../components/Common/Circle";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { getProducts } from "../app/Product/actions/getProducts";
import Slider from "../components/Slider/Slider";
import { SwiperSlide } from "swiper/react";
import { shopContent } from "../constants/content";
import useFilter from "../hooks/useFilter";
type Props = {};

const Home = (props: Props) => {
  const { products } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      getProducts(
        //@ts-ignore
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?per_page=20&orderby=popularity"
      )
    );
  }, []);
  return (
    <Page banner={<HomeBanner />}>
      <Section
        title={homeContent.brands.title}
        subtitle={homeContent.brands.subtitle}
      >
        <Slider>
          {homeContent.brands.items.map((brand) => (
            <SwiperSlide>
              <CategoryCard key={brand.title} {...brand} />
            </SwiperSlide>
          ))}
        </Slider>
        <Link
          to="/catalog"
          className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-4  w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white"
        >
          <h5 className="text-inherit">View all brands</h5>
        </Link>
      </Section>
      <Section
        title="Product Selection"
        subtitle="Discover our entire product catalog"
      >
        <div className="md:flex hidden   mt-8  justify-between w-full border-b-2 border-gray-lighter">
          {shopContent.subCategories.map((item, index) => {
            return (
              <HomeCategoryButton value={item.value} key={index}>
                {item.label}
              </HomeCategoryButton>
            );
          })}
        </div>
        <div className="grid  gap-4 overflow-hidden p-4 bg-white rounded-30 shadow-md mt-4">
          {products.slice(0, 5).map((item, index) => {
            return <ProductListCard key={index} product={item} />;
          })}
        </div>

        <div className="flex justify-center mt-8">
          <Link
            to="/catalog"
            className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-4  w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white"
          >
            <h5 className="text-inherit">View all products</h5>
          </Link>
        </div>
      </Section>
      <Section
        title={homeContent.benefits.title}
        subtitle={homeContent.benefits.subtitle}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4  rounded-10">
          {homeContent.benefits.items.map((benefit) => (
            <ServiceCard
              key={benefit.title}
              title={benefit.title}
              icon={benefit.icon}
              description={benefit.description}
            />
          ))}
        </div>
      </Section>
      <Section
        title={homeContent.subscribe.title}
        subtitle={homeContent.subscribe.subtitle}
      >
        <SubscribeCard {...homeContent.subscribe.text} />
      </Section>
      <Section
        title={homeContent.about.title}
        subtitle={homeContent.about.subtitle}
      >
        <AboutCard text={homeContent.about.text} />
      </Section>
      <Section
        title="Ready to get started?"
        subtitle="Join us  and don't miss anything"
      >
        <div className="flex justify-center">
          <CardInnerBorder
            innerCard="w-full flex  flex-col justify-center items-center gap-10 px-24 py-12"
            className=" md:w-1/2"
          >
            <p className="font-semibold">B2B Wholesale Offers</p>
            <span className="text-sm  text-center">
              Join our partner network by registering for free on our platform.
              Benefit from the most current offers at advantageous prices.
            </span>
            <Link to="/register">
              <button className="bg-gray-lighter  flex gap-4 items-center justify-center  w-40 h-10 rounded-15 text-green hover:bg-green hover:text-white">
                <h5 className="text-inherit">Join us</h5>
              </button>
            </Link>
          </CardInnerBorder>
        </div>
      </Section>

      <Section id="faq" title="FAQs" subtitle="Frequently Asked Questions">
        <div className="grid md:grid-cols-2 gap-x-6 gap-4">
          {homeContent.faq.map((faq, i: number) => (
            <FaqAccordion key={i} {...faq} />
          ))}
        </div>
      </Section>

      <Section
        title={"Our business service partners"}
        subtitle={"We are working with trusted partners"}
      >
        <div className="grid md:grid-cols-2 gap-4">
          <Card className="flex items-center justify-center">
            <img src="/partners/payment.jpg" className="my-3" alt="" />
          </Card>
          <Card className="flex items-center justify-center">
            <div className="flex justify-between w-full md:px-6 px-4">
              <img
                src="/partners/dhl.png"
                className=" w-10 md:w-20 !h-10 object-contain"
                alt=""
              />
              <img
                src="/partners/ups.png"
                className=" w-10 md:w-20 !h-10 object-contain"
                alt=""
              />
              <img
                src="/partners/fedex.png"
                className=" w-10 md:w-20 !h-10 object-contain"
                alt=""
              />
              <img
                src="/partners/postnl.png"
                className=" w-10 md:w-20 !h-10 object-contain"
                alt=""
              />
              <img
                src="/partners/dpd.png"
                className=" w-10 md:w-20 !h-10 object-contain"
                alt=""
              />
            </div>
          </Card>
        </div>
      </Section>
    </Page>
  );
};

export default Home;

const HomeBanner = () => {
  return (
    <div className="    my-4 ">
      <div className="default-container relative bg-white shadow-md md:rounded-60 p-2 px-4">
        <img
          src="/hero/hero.png"
          className=" w-fit md:rounded-60  mx-auto py-2 "
          alt="hero"
        />

        <div className="absolute top-0  pl-4 md:right-32 bottom-0 flex flex-col justify-center items-center">
          <h1 className=" text-center  text-xs md:text-2xl font-semibold">
            100% Originals perfumes
            <br />
            Wholesaler
          </h1>

          <Link to="/catalog">
            <button className="bg-green text-white text-xs font-normal hover:bg-white hover:text-green md:text-lg px-8 py-2 rounded-15 mt-4">
              Shop now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: JSX.Element;
}) => {
  return (
    <Card>
      <div className="flex flex-col items-center justify-center px-8 py-6">
        <div className="border border-green p-2.5 rounded-30">
          <div className="w-20 h-20   bg-green flex justify-center items-center  rounded-30">
            {icon}
          </div>
        </div>
        <h4 className="text-center  my-4">{title}</h4>
        <p className="">{description}</p>
      </div>
    </Card>
  );
};

const AboutCard = ({
  text = [{ title: "", paragraphs: [""] }],
}: {
  text: { title: string; paragraphs: string[] }[];
}) => {
  return (
    <Card className=" !py-12 !pl-0 lg:!pr-12 md:flex grid grid-cols-1 items-center md:justify-between gap-8">
      <div className="order-2 md:order-1 md:w-2/3 col-span-1">
        {text.map((section) => (
          <div key={section.title} className=" pl-12 border-l-4 border-green">
            <h4 className="font-semibold py-4">{section.title}</h4>
            {section.paragraphs.map((paragraph) => (
              <p key={paragraph} className="text-[13px] py-2">
                {paragraph}
              </p>
            ))}
          </div>
        ))}
        <Link to="/about-us">
          <SecondaryButton className="mt-8 ml-12 w-40 h-10 rounded-15 bg-gray-lighter text-green  hover:bg-green  hover:text-white">
            <h5 className="text-inherit font-normal">Learn more</h5>
          </SecondaryButton>
        </Link>
      </div>

      <div className="order-1 md:order-2 border border-green p-6 rounded-60 col-span-1 place-self-center w-60 h-70  md:w-70 md:h-84  lg:w-80 lg:h-96  md:block">
        <img className="" src="/hero/about_card.png" alt="" />
      </div>
    </Card>
  );
};

const SubscribeCard = ({
  header,
  slogan,
  paragraph,
  paragraphBold,
}: {
  header: string;
  slogan: string;
  paragraph: string;
  paragraphBold: string;
}) => {
  return (
    <Card className="md:px-12  px-6 py-6 !bg-green  rounded-50  grid md:grid-cols-2 md:gap-12 gap-6">
      <div>
        <p className="text-white font-semibold">
          {header} <span className=" text-base font-normal">{slogan}</span>
        </p>
        <EmailInput className="mt-4" />
      </div>

      <div className="my-auto flex flex-col  gap-4">
        <p className="text-white">{paragraph}</p>
        <p className="text-white font-semibold text-sm">{paragraphBold}</p>
      </div>
    </Card>
  );
};

const CategoryCard = ({
  title,
  image,
  hoverImage,
  id,
}: {
  title: string;
  image: string;
  id: number;
  hoverImage: string;
}) => {
  return (
    <Link className="flex justify-center" to={`/catalog/?pa_brand=${id}`}>
      <Card className="flex flex-col items-center justify-center group w-fit">
        <div className="w-28  h-24 rounded-30  flex justify-center bg-gray-lighter  group-hover:bg-green items-center ">
          <img
            className=" w-24 h-16  object-contain hidden group-hover:block absolute "
            src={hoverImage}
            alt=""
          />
          <img
            className=" w-24 h-16  object-contain  group-hover:opacity-0  "
            src={image}
            alt=""
          />
        </div>
        <h4 className="text-center mt-6 mb-4">View products</h4>

        <Circle className=" group-hover:bg-green" />
      </Card>
    </Link>
  );
};

const FaqAccordion = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [show, setShow] = useState(false);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center border-b gap-4 border-gray-light mb-4">
        <div className="border-b  pb-3 border-green  w-full ">
          <SecondaryButton
            onClick={() => setShow((prev) => !prev)}
            active
            className=" !w-full px-10 !justify-start py-1 h-fit lg:h-10"
          >
            <h5 className="font-normal"> {question}</h5>
          </SecondaryButton>
        </div>
        <IconButton className="mb-2" onClick={() => setShow((prev) => !prev)}>
          {show ? (
            <BsChevronUp className="text-inherit" />
          ) : (
            <BsChevronDown className="text-inherit" />
          )}
        </IconButton>
      </div>

      {show && <Card className="py-4 px-12 text-xs">{answer}</Card>}
    </div>
  );
};

const HomeCategoryButton = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: any;
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelect = () => {
    if (value === "all") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", value);
    }
    navigate("/catalog", { state: { searchParams: searchParams.toString() } });
  };

  return (
    <div
      className={`pb-4 border-b px-2 group hidden md:block hover:border-green border-gray-lighter`}
    >
      <SecondaryButton
        onClick={handleSelect}
        className={`w-36 h-10 p-0 group-hover:bg-green group-hover:text-white`}
      >
        {children}
      </SecondaryButton>
    </div>
  );
};
