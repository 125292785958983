import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo ",
  async (args: any, thunkAPI) => {
    try {
      const token = Cookies.get("fd-token");
      const userInfoResponse = await axios.post(
        `https://fragrancedistri.com/wp/wp-json/wp/v3/customer/info?JWT=${token}`
      );
      const vat_number = userInfoResponse.data?.vat_number?.number;
      const response = await axios.post(
        `https://www.fragrancedistri.com/wp/wp-json/wp/v3/customer/info/update?JWT=${token}`,
        {
          vat_number,
        }
      );

      return { ...userInfoResponse.data, ...response.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response });
    }
  }
);
