import React, { useState } from "react";
import { Bs1Circle, BsX } from "react-icons/bs";
import { IconButton, IconButtonOutline } from "../Button/IconButton";

const Modal = ({ children, title, showModal, setShowModal }: any) => {
  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${!showModal && "hidden"}`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 backdrop-filter backdrop-brightness-50">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 opacity-25 bg-gray-800" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white md:p-10 p-4 md:w-auto md:h-auto  rounded-30 md:rounded-50 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="flex justify-end ">
            <IconButton
              onClick={() => setShowModal(false)}
              className="text-gray-500"
            >
              <BsX className="" />
            </IconButton>
          </div>

          <p className="text-center my-4 first-letter:uppercase">{title}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
