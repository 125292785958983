import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const searchProductsInline = createAsyncThunk(
  "search/searchProductsInline",
  async (args: any, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://www.fragrancedistri.com/wp/wp-json/wp/v3/products?search_product=${args?.search}`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
