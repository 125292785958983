import { createSlice } from "@reduxjs/toolkit";
import CalculateVAT from "../../utils/CalculateVAT";
import { getCargoMethods } from "./actions/getCargoMethods";
import { getShippingPrice } from "./actions/getShippingPrice";
import { notify } from "../../utils/notify";
import Cookies from "js-cookie";

const updateTotals = (state) => {
  state.totalQuantity = state.items.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  state.subtotal = state.items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  state.taxRate = CalculateVAT();
  state.taxAmount = Number((state.subtotal * CalculateVAT()).toFixed(2));
  state.totalPrice = state.subtotal + state.taxAmount + state.shipping;
  state.subtotal = Number(state.subtotal.toFixed(2));
  state.totalPrice = Number(Number(state.totalPrice).toFixed(2));
};

const initialState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0,
  subtotal: 0,
  shipping: 0,
  taxRate: 0,
  taxAmount: 0,
  cargoMethods: [],
  selectedCargoMethod: null,
  paymentFee: 0,
};

Cookies.get("fd-cart") &&
  Object.assign(initialState, {
    ...JSON.parse(Cookies.get("fd-cart")),
    selectedCargoMethod: {},
  });

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const newItem = action.payload.item;
      const itemQuantity = action.payload.quantity;
      if (itemQuantity < 1) {
        notify(`Choose a number greater than 0.`);
        return;
      }

      const existingItem = state.items.find((item) => item.id === newItem.id);
      if (!existingItem) {
        state.items.push({ ...newItem, quantity: itemQuantity });
      } else {
        existingItem.quantity += itemQuantity;
      }
      updateTotals(state);
      Cookies.set("fd-cart", JSON.stringify(state));

      notify(
        `${itemQuantity}x ${action.payload.item.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} added to cart`
      );
    },
    removeItemFromCart: (state, action) => {
      const itemId = action.payload;
      const existingItem = state.items.find((item) => item.id === itemId);

      if (existingItem) {
        state.items = state.items.filter((item) => item.id !== itemId);
      }

      updateTotals(state);
      Cookies.set("fd-cart", JSON.stringify(state));
      notify(
        `${existingItem.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} removed from cart`
      );
    },
    updateQuantity: (state, action) => {
      const itemId = action.payload.item.id;
      const itemQuantity = action.payload.quantity;
      const existingItem = state.items.find((item) => item.id === itemId);

      if (existingItem) {
        itemQuantity < 1
          ? (state.items = state.items.filter((item) => item.id !== itemId))
          : (existingItem.quantity = itemQuantity);
      }

      updateTotals(state);
      Cookies.set("fd-cart", JSON.stringify(state));
      notify(
        `${action.payload.item.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} updated to ${itemQuantity}x in cart`
      );
    },
    setShipping(state, action) {
      state.shipping = Number(action.payload);
      updateTotals(state);
      Cookies.set("fd-cart", JSON.stringify(state));
    },
    clearCart: (state, action) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.subtotal = 0;
      state.shipping = 0;
      state.taxAmount = 0;
      state.selectedCargoMethod = {};
      state.cargoMethods = [];
      Cookies.set("fd-cart", JSON.stringify(state));
    },
    setSelectedCargoMethod: (state, action) => {
      state.selectedCargoMethod = action.payload;
    },
    updateTotalsCart: (state, action) => {
      state.totalQuantity = state.items.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      state.subtotal = state.items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      state.taxRate = CalculateVAT(
        action.payload.country,
        action.payload.vat_is_valid
      );
      state.taxAmount = state.subtotal * state.taxRate;

      state.totalPrice =
        state.subtotal + state.taxAmount + state.shipping + state.paymentFee;

      state.subtotal = state.subtotal.toFixed(2);
      state.taxAmount = state.taxAmount.toFixed(2);
      state.totalPrice =
        state.totalPrice && parseFloat(state.totalPrice).toFixed(2);
    },
    setPaymentFee: (state, action) => {
      state.paymentFee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCargoMethods.pending, (state, action) => {
        state.shipping = 0;
        state.cargoMethods = [];
      })
      .addCase(getCargoMethods.fulfilled, (state, action) => {
        state.cargoMethods = action.payload;
      })
      .addCase(getCargoMethods.rejected, (state, action) => {
        state.shipping = 0;
        state.cargoMethods = [];
      });

    /*     .addCase(getShippingPrice.pending, (state, action) => {
        state.shipping = 0;
      })
      .addCase(getShippingPrice.fulfilled, (state, action) => {
        console.log(action.payload);
        state.shipping = action.payload?.price
          ? Number(Number(action.payload.price).toFixed(2))
          : 0;
      })
      .addCase(getShippingPrice.rejected, (state, action) => {
        state.shipping = 0;
      }); */
  },
});

export const {
  addItemToCart,
  updateQuantity,
  updateTotalsCart,
  removeItemFromCart,
  setShipping,
  clearCart,
  setSelectedCargoMethod,
  setPaymentFee,
} = cartSlice.actions;
export default cartSlice.reducer;
