import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import Alert from "../components/Alert/Alert";
import Accordion from "../components/Accordion/Accordion";
import Card from "../components/Card/Card";
import Circle from "../components/Common/Circle";
import { nanoid } from "@reduxjs/toolkit";
import { BsCloudDownloadFill, BsX } from "react-icons/bs";
import RangeSlider from "react-range-slider-input";
import 'react-range-slider-input/dist/style.css';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getAllFilters } from "../app/Filter/actions/getAllFilters";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import usePriceListFilter from "../hooks/usePriceListFilter";
import { getProducts } from "../app/Product/actions/getProducts";
import { shopContent } from "../constants/content";
import Checkbox from "../components/Input/Checkbox";
import PriceFilter from "../components/Input/PriceFilter";
import useFilter from "../hooks/useFilter";
import exportCSV from "../utils/exportCSV";
import { useSelector } from "react-redux";
import axios from "axios";
import { notify } from "../utils/notify";


const PriceList = (props) => {

  const products = useAppSelector((state) => state.product.products);
  const dispatch = useAppDispatch();
  const { endpoint, searchParams, setSearchParams } = useFilter();
  const { loggedIn, user } = useAppSelector((state) => state.user || {});
  const { loading } = useSelector(state => state.filter)

  useEffect(() => {
    // @ts-ignore
    dispatch(getAllFilters(Object.fromEntries(searchParams)))
  }, [searchParams]
  )

  const download_pricelist = () => {

    /* 
    https://catalog.fragrancedistri.com/nu4y9ekfx1ktut75 */
    const url = `https://www.fragrancedistri.com/wp/wp-json/wp/v3/pricelist`
    window.open(url);
  }

  return (
    <Page>
      <Section
        title="Price List"
        subtitle="Download a personalized pricelist with filters is easy! Follow these simple steps"
      >
        <Alert className="bg-gray-lighter my-8">
          <p className="text-black">
            Use the filters to narrow down the products you want to see. You can
            filter by product category, price range, and other criteria. Or
            click <a onClick={
              download_pricelist
            } className="font-semibold text-green underline cursor-pointer">HERE</a> to
            download the whole catalog.{" "}
          </p>
        </Alert>

        <div className="flex flex-col gap-12">
          <Accordion title="Gender">
            <GenderCheckboxes />
          </Accordion>

          <FilterAccordion endpoint={endpoint} title="Category" />

          <FilterAccordion endpoint={endpoint} title="Brand" />

          {/*<Accordion title="Stock">*/}
          {/*  <Card>*/}
          {/*    <div className="px-5 py-6">*/}
          {/*      <RangeSlider value={[40, 60]} min={0} max={100} />*/}
          {/*    </div>*/}
          {/*  </Card>*/}
          {/*</Accordion>*/}

          <Accordion title="Price">
            <PriceFilter />
          </Accordion>

          <div className="mb-16" disabled={loading}>
            <button onClick={async () => {
              await dispatch(getProducts(endpoint));
              exportCSV("products")
            }}
              className="bg-gray-lighter  flex gap-4 items-center justify-center  w-60 h-11 mx-auto rounded-15 text-green hover:bg-green hover:text-white">
              <BsCloudDownloadFill className="text-inherit" />
              <h5 className="text-inherit">Download Pricelist</h5>
            </button>


            <button onClick={() => {
              searchParams.forEach((value, key) => {
                searchParams.delete(key)
              }
              )
              setSearchParams(searchParams)
            }}
              className="bg-gray-lighter  mt-4  flex gap-4 items-center justify-center  w-60 h-11 mx-auto rounded-15 text-green hover:bg-green hover:text-white">

              <h5 className="text-inherit">Clear Filters</h5>
            </button>

            <div className="flex  justify-center flex-col  items-center mt-3">
              <h5 className="font-semibold">OR</h5>

              <div onClick={download_pricelist} className="text-xs text-green  hover:font-semibold hover:underline  mt-2">Click here to download the whole catalog</div>
            </div>

          </div>
        </div>
      </Section>
    </Page>
  );
};

export default PriceList;



const FilterAccordion = ({
  title,
  children,
  endpoint,
  setEndpoint
}) => {
  const { brand_list, category_list, loading } = useAppSelector((state) => state.filter);

  const [selectedLabels, setSelectedLabels] = useState([]);

  useEffect(() => {

  }, [endpoint]);

  return (
    <Accordion title={title}>
      <div className="grid md:grid-cols-2 gap-4" disabled={loading}>
        <Card className=" p-6 pr-2">
          <div className="flex flex-col gap-2 h-[350px]  overflow-y-scroll">
            {
              title == "Brand" ?
                brand_list?.map((brand) => (
                  <Option

                    title={"pa_brand"}
                    key={nanoid()}
                    label={brand.name}
                    value={brand.id}
                    setSelectedLabels={setSelectedLabels}
                  />
                ))
                :
                category_list?.map((category) => (
                  <Option
                    title={"category"}
                    key={nanoid()}
                    label={category.name}
                    value={category.id}
                    setSelectedLabels={setSelectedLabels}
                  />
                ))
            }
          </div>
        </Card>

        <div className="mt-4">
          <h5 className="text-gray-dark">Your filters</h5>
          <div className="flex gap-4  h-fit mt-4 flex-wrap">
            {
              selectedLabels.map((label) => (
                <FilterTag label={label} title={title == "Brand" ? "pa_brand" : "category"} setSelectedLabels={setSelectedLabels} />
              ))
            }
          </div>
        </div>
      </div>
    </Accordion>
  )
}





const Option = ({
  label,
  value,
  title,
  setSelectedLabels
}) => {
  const { searchParams, setSearchParams } = usePriceListFilter()
  const [active, setActive] = useState(false);



  function handleOptionClick(value) {
    if (searchParams.getAll(title).includes(value.toString())) {
      const afterDelete = searchParams.getAll(title).filter(_value => _value !== value.toString())
      searchParams.delete(title);
      if (afterDelete.length > 0) afterDelete.map((_value) => {
        searchParams.append(title, _value)
      })
      setSelectedLabels(prevState => [...prevState.filter((_label) => _label.label !== label)])
      setSearchParams(searchParams);
    } else {
      searchParams.append(title, value);
      setSelectedLabels(prevState => [...prevState, { label, value }])
      setSearchParams(searchParams);
    }


  };


  useEffect(() => {
    if (searchParams.getAll(title).includes(value.toString())) {
      setActive(true);
    }
    else {
      setActive(false);
    }
  }, [searchParams.get(title)]);


  return (
    <div
      key={nanoid()}
      onClick={() => handleOptionClick(value)}
      className={`flex items-center gap-2.5 w-full hover:bg-gray-lighter  rounded-15 p-2.5 ${active && "bg-gray-lighter"
        }`}
    >
      <Circle active={active} />
      <label className="text-xs">{label}</label>
    </div>
  );

}


const FilterTag = ({
  label,
  title,
  setSelectedLabels
}) => {
  const { searchParams, setSearchParams } = usePriceListFilter()
  function deleteParam() {
    const afterDelete = searchParams.getAll(title).filter(_value => _value !== label.value.toString())
    searchParams.delete(title);
    if (afterDelete.length > 0) afterDelete.map((_value) => {
      searchParams.append(title, _value)
    })
    setSelectedLabels(prevState => [...prevState.filter((_label) => _label.label !== label.label)])

    setSearchParams(searchParams);
  }
  return (
    <div
      key={nanoid()}
      className={`flex items-center gap-2.5 w-fit hover:bg-gray-lighter  rounded-15 p-2.5 
      bg-gray-lighter
    `}
    >
      <Circle active />
      <label className="text-xs">{label.label}</label>
      <BsX onClick={() => deleteParam()} className="ml-auto text-green" />
    </div>
  );
};


export const GenderCheckboxes = () => {

  const { searchParams, setSearchParams } = usePriceListFilter()

  function handleOptionClick(value) {
    const title = "pa_gender"
    if (searchParams.getAll(title).includes(value.toString())) {
      const afterDelete = searchParams.getAll(title).filter(_value => _value !== value.toString())
      searchParams.delete(title);
      if (afterDelete.length > 0) afterDelete.map((_value) => {
        searchParams.append(title, _value)
      })
      setSearchParams(searchParams);
    } else {
      // @ts-ignore
      searchParams.append(title, value);
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-14 my-4 w-full ">
      {shopContent.genderCheckboxes.map((item, index) => {
        return (
          <div key={index} className="flex items-center gap-4">
            <Checkbox
              // @ts-ignore
              action={() => handleOptionClick(item.value)}
              type="checkbox"
              className="mr-2"
              name={item.label}
              id={item.label}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        );
      })}
    </div>
  );
};