import store from "../../store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {useSearchParams} from "react-router-dom";


export const getAllFilters = createAsyncThunk(
  "getAllFilters",
  async (args, thunkAPI) => {
    const selected_filters = args;

    try {

      const brand_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=brand",
        { params: { selected_filters } }
      );

      const brand_with_letter_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=brand",
        { params: { "letters" : true }  }
    );

      const category_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=product_cat",
        { params: { selected_filters } }
      );
    
      const color_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=color",
        { params: { selected_filters } }
      );

      const memory_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=size",
        { params: { selected_filters } }
      );

      const price_list_response = await axios.get(
        "https://www.fragrancedistri.com/wp/wp-json/wp/v3/filters?filterType=price",
        { params: { selected_filters } }
      );
        
      return {
        brand_list: brand_list_response.data,
        brand_with_letter_list: brand_with_letter_list_response.data,
        category_list: category_list_response.data,
        color_list: color_list_response.data,
        memory_list: memory_list_response.data,
        price_list: price_list_response.data,

      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
