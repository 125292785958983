import React from 'react'
import Section from '../components/Elements/Semantic/Section'
import CardInnerBorder from '../components/Card/CardInnerBorder'
import Page from '../components/Layout/Page'
import { termsAndConditionsContent } from '../constants/content'

type Props = {}

const PrivacyPolicy = (props: Props) => {
  return (
    <Page>
    <Section title="Privacy Policy" subtitle="">
        <CardInnerBorder innerCard='px-4 sm:px-10 md:px-20 lg:px-36 py-12 line ' className="mt-12  lg:mx-32 mx-3">
       <p className='text-sm font-normal text-black leading-8'>
       This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our cosmetics and beauty wholesaler website. By using our website, you consent to the practices described in this Privacy Policy.<br /> <br />
<b>Information We Collect:</b> <br /> <br />a. Personal Information: We may collect personal information, such as your name, email address, shipping address, and payment details when you place an order on our website. <br /> <br />b. Usage Information: We may collect non-personal information about your interactions with our website, such as your IP address, browser type, referring/exit pages, and operating system, to analyze trends and improve our website's functionality. <br /> <br />c. Cookies: Our website uses cookies to enhance your browsing experience. You have the option to disable cookies in your browser settings, but this may limit certain features and functionality of our website.<br /> <br />
<b>Use of Information:</b> <br /> <br />a. We use personal information to process your orders, provide customer support, and communicate with you regarding your purchases. <br /> <br />b. Usage information helps us analyze website traffic, track user preferences, and improve our website's performance and content. <br /> <br />c. We may use your email address to send you promotional offers, updates, and newsletters. You have the option to opt out of receiving such communications.<br /> <br />
<b>Information Sharing: </b><br /> <br />a. We may share your personal information with third-party service providers who assist us in operating our website, processing payments, and delivering orders. These service providers are obligated to maintain the confidentiality and security of your information. <br /> <br />b. We may disclose personal information if required by law or to protect our rights, property, or safety, or the rights, property, or safety of others.<br /> <br />
<b>Data Security:</b> <br /> <br />a. We implement industry-standard security measures to protect your personal information from unauthorized access, use, disclosure, or alteration. <br /> <br />b. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure. While we strive to protect your personal information, we cannot guarantee absolute security.<br /> <br />
<b>Third-Party Links:</b> <br /> <br />a. Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites. We encourage you to review the privacy policies of those third-party websites before providing any personal information.<br /> <br />
<b>Children's Privacy: </b><br /> <br />a. Our website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from individuals under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to remove that information from our systems.<br /> <br />
<b>Changes to the Privacy Policy:</b> <br /> <br />a. We reserve the right to modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically.<br /> <br />
<b>Contact Us: </b><br /> <br />a. If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at contact@fragrancedistri.com.
       </p>
        </CardInnerBorder>
    </Section>
</Page>
  )
}

export default PrivacyPolicy