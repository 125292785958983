import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Shop from "../pages/Shop";
import SingleProduct from "../pages/SingleProduct";
import PriceList from "../pages/PriceList.jsx";

import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import MyAccount from "../pages/MyAccount";
import Contact from "../pages/Contact";
import AboutUs from "../pages/AboutUs";
import Register from "../pages/Register";
import Login from "../pages/Login/Login";
import ResetPassword from "../pages/Login/ResetPassword";
import ResetLinkSent from "../pages/Login/ResetLinkSent";
import TermsConditions from "../pages/TermsConditions";
import RequireAuth from "../utils/RequireAuth";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CheckoutConfirmation from "../pages/CheckoutConfirmation";
import RegisterConfirmation from "../pages/RegisterConfirmation";
import OrderImport from "../pages/OrderImport";
import ReactGA from "react-ga";

const TRACKING_ID = "G-5SFHNMTRJD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
type Props = {};

const DefaultRoutes = (props: Props) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/catalog" element={<Shop />} />
      <Route path="/product/:slug" element={<SingleProduct />} />
      <Route path="/pricelist" element={<PriceList />} />
      <Route path="/order-import" element={<OrderImport />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/checkout-confirmation" element={<CheckoutConfirmation />} />
      <Route
        path="/my-account"
        element={
          <RequireAuth>
            <MyAccount />
          </RequireAuth>
        }
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register-confirmation" element={<RegisterConfirmation />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-link-sent" element={<ResetLinkSent />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default DefaultRoutes;
