/*  async thunk get products function */

// Path: src\app\Product\actions\getProducts.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (base_url, { rejectWithValue }) => {
    try {
      const response = await axios.get(base_url);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
