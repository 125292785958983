function priceFormatter(
  price: number | string | undefined,
  currency: string = "EUR",
  locale: string = "de-DE",
  options: Intl.NumberFormatOptions = {},
  withoutCurrencySymbol: boolean = false
): string {
  const _price = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currency,
    ...options,
  }).format(Number(price) || 0);
  return withoutCurrencySymbol ? _price.replace("€", "").trim() : _price;
}

export default priceFormatter;
