import { createSlice, current } from "@reduxjs/toolkit";
import { getAllFilters } from "./actions/getAllFilters";

const initialState = {
  color_list: [],
  brand_list: [],
  memory_list: [],
  category_list: [],
  alphabetical_brand_list: {},
  order_list: [
    {
      id: "price",
      name: "Price",
    },
    {
      id: "popularity",
      name: "Popularity",
    },
    {
      id: "data",
      name: "New Arrrivals",
    },
  ],
  orderby_list: [
    {
      id: "ASC",
      name: "Ascending",
    },
    {
      id: "DESC",
      name: "Descending",
    },
  ],
  priceFilter: { min: 0, max: 9999999 },
  selectedFilters: {},
  loading: false,
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    clearSelectedFilters: (state, action) => {
      state.selectedFilters = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFilters.fulfilled, (state, action) => {
      state.color_list = action.payload.color_list;
      state.brand_list = action.payload.brand_list;
      state.memory_list = action.payload.memory_list;
      state.category_list = action.payload.category_list;
      state.priceFilter = action.payload.price_list;
      state.alphabetical_brand_list = action.payload.alphabetical_brand_list;
      state.loading = false;
    });
    builder.addCase(getAllFilters.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllFilters.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setSelectedFilters, clearSelectedFilters } = filterSlice.actions;

export default filterSlice.reducer;
