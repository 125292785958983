export const site = {
  title: "Fragrance Distri",
  description: "Fragrance Distri",
  email: "contact@fragrancedistri.com",
  vat: "FR 00 000000000",
  coc: "00000000000000",
  address: "Joan Muyskenweg 137 1115 RC Amsterdam Netherlands",
  phone: "+31 6 25395762",
  facebook: "",
  twitter: "",
  instagram: "",
};


