import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCargoMethods = createAsyncThunk(
  "cart/getCargoMethods",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `https://fragrancedistri.com/wp/wp-json/sendcloud/v1/shipping_methods`,
        args
      );
      const filteredData = response?.data?.data?.filter(
        (method: any) =>
          !method.name.includes("Unstamped") &&
          !method.name.includes("Drop Off") &&
          !method.name.includes("Dropoff")
      );
      return filteredData;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
