import React, { useEffect, useRef, useState } from "react";
import { BsSearch, BsSendFill } from "react-icons/bs";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { IconButton } from "../Button/IconButton";
import { SecondaryButton } from "../Button/SecondaryButton";
import useFilter from "../../hooks/useFilter";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { newsletterAPI } from "../../app/Contact/newsletterAPI";
import { searchProductsList } from "../../app/Product/actions/searchProductList";
import { Link, useLocation } from "react-router-dom";
import { searchProductsInline } from "../../app/Product/actions/searchProductsInline";
import Card from "../Card/Card";
import Circle from "../Common/Circle";
import CardInnerBorder from "../Card/CardInnerBorder";
import { clearSearch } from "../../app/Product/Slices/searchSlice";
import { notify } from "../../utils/notify";

type Props = {} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({ className, ...props }: Props) => {
  return <input className={`${className} outline-none`} {...props} />;
};

export default Input;

export const SearchInput = ({ className, ...props }: Props) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { searchResult } = useAppSelector((state) => state.search);
  const location = useLocation().pathname;

  const myRef = React.useRef<HTMLInputElement>(null);
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (myRef.current && !myRef.current.contains(e.target)) {
      setOpen(false);
      /* dispatch(clearSearch()) */
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 1) {
        // @ts-ignore
        dispatch(searchProductsInline({ search, category: null }));
      }
    }, 1000);
    if (search.length >= 1) {
      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);

  useEffect(() => {
    if (searchResult.length > 1) {
      setOpen(true);
    }
  }, [searchResult]);

  useEffect(() => {
    setOpen(false);
    dispatch(clearSearch());
  }, [location]);

  return (
    <div
      ref={myRef}
      className={` relative flex items-center h-fit bg-gray px-2 rounded-15 py-1 border border-gray-light w-full my-auto ${className}`}
    >
      <Input
        className={`p-2 text-xs w-full block  bg-gray`}
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search your product, EAN, Brand, etc..."
      />
      <button onClick={() => setOpen(true)} className="ml-auto mr-4 ">
        <BsSearch className="text-green" />
      </button>

      {open && (
        <CardInnerBorder
          innerCard="!p-0"
          className="absolute flex-col top-14 -left-20 rounded-40 z-40 max-h-80 overflow-y-scroll "
        >
          <ul id="style-5" className="lg:h-auto ">
            {searchResult.map((product: any) => (
              <Link to={`/product/${product.id}`}>
                <li className="  lg:text-start text-left text-gray-700 p-4 rounded-40 bg-white text-xs  hover:bg-gray-lighter   w-full ">
                  <div className="grid grid-cols-12 gap-4 ">
                    <div className="col-span-3 gap-2 flex items-center justify-between">
                      <Circle className="" />
                      <img
                        className="w-12 h-12 p-2 object-contain bg-white rounded-15"
                        src={product.images[0]}
                        alt=""
                      />
                    </div>
                    <div className="col-span-9">
                      <div className="">{product.name}</div>
                      <span className=" text-sm font-semibold ">
                        {"€ " + product.price}
                      </span>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </CardInnerBorder>
      )}
    </div>
  );
};

export const EmailInput = ({ className, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const email = e.target["email"].value;
    if (!email.trim()) {
      notify("Please enter your email address.", "danger");
      return;
    }
    if (isSubmitting) {
      notify("Please wait a moment and try again.", "danger");
      return;
    }
    setIsSubmitting(true);
    // @ts-ignore
    dispatch(newsletterAPI({ email }));

    setTimeout(() => {
      setIsSubmitting(false);
    }, 3000);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={` bg-white flex items-center text-black  rounded-15 p-1 ${className}`}
    >
      <Input
        className={`py-2 px-4 text-xs w-full`}
        type="email"
        name="email"
        placeholder="Email address"
      />

      <IconButton type="submit" className="">
        <BsSendFill className="text-inherit " />
      </IconButton>
    </form>
  );
};

export const ShopSearchInput = ({ className, ...props }: Props) => {
  const { searchParams, setSearchParams } = useFilter();
  const [searchInput, setSearchInput] = React.useState<any>("");
  const dispatch = useAppDispatch();
  const handleChange = (e: any) => {
    if (e.target.value.length == undefined) {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  useEffect(() => {
    if (searchInput != undefined && searchInput != "") {
      searchParams.set("search_product", searchInput);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search_product");
      setSearchParams(searchParams);
    }
  }, [searchInput]);

  return (
    <div className={` bg-white flex items-center rounded-15 p-1 ${className}`}>
      <Input
        onChange={handleChange}
        value={searchInput}
        className={`py-2 px-4 text-xs w-full`}
        type="email"
        placeholder="Search your product, EAN, Brand, etc..."
      />

      <IconButton className="">
        <BsSearch className="text-inherit" />
      </IconButton>
    </div>
  );
};

export const InputOutline = ({ className, ...props }: Props) => {
  return (
    <div
      className={`flex items-center h-fit border rounded-40   border-gray-light  w-full my-auto ${className} px-4`}
    >
      <Input className={`py-2 text-xs w-full`} type="number" {...props} />
    </div>
  );
};

export const SecondaryInput = ({ className, ...props }: Props) => {
  return (
    <input
      className={` h-10 w-full text-xs text-gray-dark bg-gray-bg  border border-gray-light outline-gray-dark rounded-15 px-6 ${className}`}
      {...props}
    />
  );
};

export const SecondaryTextArea = ({ className, ...props }: Props) => {
  return (
    // @ts-ignore
    <textarea
      className={` h-72 w-full text-xs text-gray-dark bg-gray-bg py-4  border border-gray-light outline-gray-dark rounded-15 px-6 ${className}`}
      {...props}
    />
  );
};
