import { createSlice } from "@reduxjs/toolkit";
import { sendEmail } from "./sendEmail";
import { newsletterAPI } from "./newsletterAPI";
import { toast } from "react-toastify";
import NewsletterEvent from "../../utils/google-analytics/events/NewsletterEvent";
import ContactFormEvent from "../../utils/google-analytics/events/ContactFormEvent";

const initialState = {
  sended: false,
  loading: false,
  newsletter: null,
};

const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(sendEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.sended = !!action.payload;
      ContactFormEvent();
      toast(`Your message has been sent`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.loading = false;
      state.sended = false;
    });
    builder.addCase(newsletterAPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(newsletterAPI.fulfilled, (state, action) => {
      state.loading = false;
      NewsletterEvent();
      state.newsletter = action.payload;
      toast(`Subscribed to newsletter`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
    builder.addCase(newsletterAPI.rejected, (state, action) => {
      state.loading = false;
      state.newsletter = action.payload;
      toast(`${action.payload.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  },
});

export default contactSlice.reducer;
