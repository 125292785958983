import React from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import { SecondaryButton } from "../components/Button/SecondaryButton";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { IconButton } from "../components/Button/IconButton";
import { BsEnvelopeFill, BsGeoAltFill, BsTelephoneFill } from "react-icons/bs";
import { Address, Email, Phone } from "../constants/StaticComponents";
import ContactForm from "../components/Form/ContactForm";
import { site } from "../constants/websiteInfo";

type Props = {};

const Contact = (props: Props) => {
  return (
    <Page>
      <Section title="Contact us" subtitle="Get in touch with us!">
        <div className="grid md:grid-cols-4 gap-4">
          <div className="grid md:grid-cols-1 w-full">
            <Item
              href={"mailto:" + site.email}
              title="Email address"
              subtitle={<Email className="text-xs" />}
              icon={<BsEnvelopeFill className="text-inherit" />}
            />
            <Item
              href={"tel:" + site.phone}
              title="Phone number"
              subtitle={<Phone className="text-xs" />}
              icon={<BsTelephoneFill className="text-inherit" />}
            />
            <Item
              href={site.address}
              title="Address"
              subtitle={<Address className="text-xs text-center" />}
              icon={<BsGeoAltFill className="text-inherit" />}
            />
          </div>
          <CardInnerBorder className="col-span-3">
            <ContactForm title={"Contact Form"} />
          </CardInnerBorder>
        </div>

        <CardInnerBorder className="mt-8">
          <iframe
            className="w-full rounded-30"
            height="350px"
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.2291484975235!2d4.882587977004009!3d52.36626484759998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609e9cfaa05e9%3A0x9b1222897c99441e!2sKeizersgracht%20482%2C%201017%20EG%20Amsterdam%2C%20Netherlands!5e0!3m2!1sen!2str!4v1713168402258!5m2!1sen!2str"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </CardInnerBorder>
      </Section>
    </Page>
  );
};

export default Contact;

const Item = ({
  href,
  title,
  icon,
  subtitle,
}: {
  href: string;
  title: string;
  subtitle: string | React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <CardInnerBorder className="mt-4 w-full">
      <a href={href} className="flex flex-col py-4 items-center gap-4 w-full">
        <div className="flex flex-col items-center w-[60%] mx-auto border-b-2  pb-3 border-green">
          <h5> {title}</h5>
          <IconButton className="mt-1.5">{icon}</IconButton>
        </div>
        {subtitle}
      </a>
    </CardInnerBorder>
  );
};
