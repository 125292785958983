import React from 'react';
import {InputOutline} from "./Input";
import { isMobile, isDesktop } from 'react-device-detect';
function AddProduct({setSelectList,selectList,index}) {
    console.log((isDesktop && (selectList-1) == index) )
    return (
        <div className="inline-flex w-2/3 gap-4 mt-4 relative flex-wrap ">
            <div className="flex items-center gap-2 ">
                <h5 className="whitespace-nowrap">EAN number:</h5>
                <InputOutline name={"ean"} id={index} className="!w-64" placeholder="EAN number" />
            </div>

            <div className="flex items-center gap-2 ">
                <h5 className="whitespace-nowrap">Product Quantity:</h5>
                <InputOutline name={"quantity"} id={index}
                    className="!w-56"
                    placeholder="Product Quantity"
                />
            </div>

            {selectList === index && (
                <button onClick={()=>setSelectList(prevState => prevState+1)} className="place-self-center justify-center bg-gray-lighter px-4 py-3 rounded-xl hover:bg-green right-0 hover:text-gray-lighter lg:absolute">+</button>
            )}

            {
            ( (isDesktop && (selectList-1) == index) || (isMobile && selectList == index)) && (
                    <button onClick={()=>setSelectList(prevState => prevState-1)} className="place-self-center  justify-center bg-gray-lighter px-4 py-3 rounded-xl hover:bg-green right-0 hover:text-gray-lighter lg:absolute">-</button>
                )
            }
        </div>
    );
}

export default AddProduct;