import React from 'react'

type Props = {
    active?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Circle = ({active = false, className, ...props}: Props) => {
  return (
    <div {...props} className={`w-2.5 h-2.5 border border-green rounded-full ${active && "bg-green"}  ${className}`}></div>
  )
}

export default Circle