const paymentRates = {
  AMEX: 0.035,
  MASTERCARD: 0.029,
  VISA: 0.029,
  BANKTRANS: 0.02,
 /*  KLARNA: 0.02,  */
  IDEAL: 0.02,
  APPLEPAY: 0.02,
  GOOGLEPAY: 0.02, 
};

export default paymentRates;
