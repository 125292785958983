import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function CheckoutConfirmation() {
  const user = useSelector((state: any) => state?.user?.user);
  const orderID = useLocation().search.split("=")[1];
  return (
    <Page>
      <Section title="Checkout" subtitle="Review your order details">
        <CardInnerBorder
          innerCard="md:px-20 px-4 py-12   line "
          className="mt-12 mx-2.5 md:mx-20 lg:mx-40 "
        >
          <div className="flex flex-col place-items-center">
            <p className="leading-9 font-semibold text-[18px] md:text-[24px]">
              Thank you for your order!
            </p>
            <p className="leading-7 text-[14px] md:text-[18px] text-black mt-10">
              Your order #{orderID} has been placed!
            </p>
            <p className="flex text-center text-black text-[14px] mt-4 leading-5">
              We sent an email to {user?.email} with your order confirmation and
              receipt Don’t hesitate to contact us if you have any question.
            </p>
            <button
              onClick={() =>
                user?.user_id
                  ? window.open(`/my-account?orders=${orderID}`, "_blank")
                  : window.open(`/`, "_self")
              }
              className="bg-gray-lighter flex gap-4 items-center justify-center mx-auto mt-12 mb-3  w-40 h-11 rounded-15 text-green hover:bg-green hover:text-white"
            >
              <h5 className="text-inherit">
                {user?.user_id ? "View Order" : "Back to home"}
              </h5>
            </button>
            {user?.user_id && (
              <Link to="/">
                <p className="text-green underline">Back to home</p>
              </Link>
            )}
          </div>
        </CardInnerBorder>
      </Section>
    </Page>
  );
}

export default CheckoutConfirmation;
