import React from 'react'
import { useAppDispatch } from '../../app/hooks'
import { addItemToCart, updateQuantity } from '../../app/Cart/cartSlice'

type Props = {
    max?: number
    min?: number
    className?: string
}


type NumberInputProps = {
    max?: number
    min?: number
    value: any
    product?: any
    small?: boolean
    setValue: React.Dispatch<React.SetStateAction<any>>
}
const NumberInput = ({max = 100, min = 0,  value, setValue, product=false, small= false}: NumberInputProps) => {
    const dispatch = useAppDispatch()


    const handleIncrement = () => {

        if(value < max) {
      
            setValue(value + 1)
            product && dispatch(addItemToCart({item:product, quantity: 1}))
        }
    }

    const handleDecrement = () => {
        if(value > min) {
            setValue(value - 1)
            product && dispatch(updateQuantity({item:product, quantity: value -1}))
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target
        if(value.length > 0 && parseInt(value) <= max && parseInt(value) >= min) {
            
            product && dispatch(updateQuantity({item:product, quantity: parseInt(value)}))
        }
        setValue(parseInt(value))
    }


  return (
    <div className={` ${ small ? " md:w-24" : "md:w-36 "}  h-10  w-32 border border-gray-light flex  rounded-[17px] `}>
        <button onClick={handleDecrement} className={` ${small ? "w-8" : "w-12"} flex items-center justify-center`}>-</button>
        <input onChange={handleChange} className={` ${ small ? "w-8" : "w-16"} outline-none text-center bg-transparent`} type="number" value={value}  />
        <button onClick={handleIncrement} className={` ${ small ? "w-8" : "w-12"} flex items-center justify-center`}>+</button>
    </div>
  )
}

export default NumberInput



export const NumberInputSecondary = ({max = 100, min = 0,  value, setValue, product=false}: NumberInputProps) => {

    const dispatch = useAppDispatch()


    const handleIncrement = () => {
        if(value < max) {
            setValue(value + 1)
            product && dispatch(addItemToCart({item:product, quantity: 1}))
        }
    }

    const handleDecrement = () => {
        if(value > min) {
            setValue(value - 1)
            product && dispatch(updateQuantity({item:product, quantity: value - 1}))
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target
        console.log(value)
            setValue(parseInt(value))
            product && dispatch(updateQuantity({item:product, quantity: parseInt(value)}))

    }

  return (
    <div className={` w-36 h-10 border border-gray-light  bg-white flex  rounded-[17px] `} >
        <button onClick={handleDecrement} className='w-12 flex items-center justify-center'>-</button>
        <input onChange={handleChange} className='w-16 outline-none text-center bg-transparent' type="number" value={value}  />
        <button onClick={handleIncrement} className='w-12 flex items-center justify-center'>+</button>
    </div>
  )
}
