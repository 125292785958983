import React from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import Accordion from "../components/Accordion/Accordion";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { IconButton, IconButtonOutline } from "../components/Button/IconButton";
import {
  BsBagPlusFill,
  BsCheckCircleFill,
  BsFillLockFill,
  BsPen,
  BsTruck,
  BsXCircleFill,
} from "react-icons/bs";
import { CartItem } from "./Cart";
import Checkbox from "../components/Input/Checkbox";
import SecondarySelect from "../components/Input/SecondarySelect";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCargoMethods } from "../app/Cart/actions/getCargoMethods";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  setPaymentFee,
  setSelectedCargoMethod,
  setShipping,
  updateTotalsCart,
} from "../app/Cart/cartSlice";
import { paymentMethods } from "../constants/paymentMethods";
import { createOrder } from "../app/Order/actions/createOrder";
import { notify } from "../utils/notify";
import { Link } from "react-router-dom";
import { setDifferentAddress } from "../app/User/userSlice";
import { SecondaryInput } from "../components/Input/Input";
import SelectCountry from "../components/Input/SelectCountry";
import Modal from "../components/Modal/Modal";
import { getAddresses } from "../app/User/actions/getAdresses";
import { updateAddress } from "../app/User/actions/updateAddress";
import { checkVatNumber } from "../app/User/actions/checkVatNumber";
import { getUserInfo } from "../app/User/actions/getUserInfo";
import Cookies from "js-cookie";
import paymentRates from "../utils/paymentRates";

type Props = {};

const Checkout = (props: Props) => {
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const { user } = useAppSelector((state: any) => state.user);
  const {
    items,
    cargoMethods,
    selectedCargoMethod,
    shipping,
    totalPrice,
    subtotal,
    taxAmount,
    taxRate,
    paymentFee,
    totalQuantity,
  } = useAppSelector((state: any) => state.cart);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<any>();
  const payment_link = useAppSelector((state) => state.order.payment_link);
  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });
  useEffect(() => {
    if (payment_link != "" && payment_link != null) {
      window.location.replace(payment_link);
    }
  }, [payment_link]);

  /*  useEffect(() => {
    const shippingInfo = {
      shipping_method: selectedCargoMethod?.id,
      quantity: items.length,
      to: { country: user?.shipping?.country },
    };

    if (selectedCargoMethod) {
      // @ts-ignore
      dispatch(getShippingPrice(shippingInfo));
    }
  }, [selectedCargoMethod]); */

  const handleMethodChange = (e: any) => {
    const selectedMethod = cargoMethods?.find(
      (method: any) => method?.id == e.target.value
    );
    dispatch(
      setSelectedCargoMethod({
        name: selectedMethod?.name,
        id: selectedMethod?.id,
        price: Number(Number(selectedMethod?.countries?.[0]?.price).toFixed(2)),
      })
    );
    dispatch(setShipping(Number(selectedMethod?.countries?.[0]?.price) || 0));
  };
  const handlePaymentMethodChange = (e: any) => {
    setSelectedPaymentMethod(e.target.value);
    // @ts-ignore
    if (paymentRates[e.target.value]) {
      // @ts-ignore
      dispatch(
        setPaymentFee(
          (Number(subtotal) + Number(taxAmount) + shipping) *
            // @ts-ignore
            (paymentRates[e.target.value] || 0)
        )
      );
    } else {
      dispatch(setPaymentFee(0));
    }

    dispatch(
      updateTotalsCart({
        country: user?.shipping?.country,
        vat_is_valid: user?.vat_is_valid,
      })
    );
  };
/*   console.log(
    Object.keys(selectedCargoMethod).length == 0 ||
      selectedCargoMethod == "placeholder"
  ); */
  const handleCreateOrder = () => {
    const products = items.map((item: any) => item.id);
    const quantities = items.map((item: any) => item.quantity);
    const token = Cookies.get("fd-token");
    dispatch(
      // @ts-ignore
      createOrder({
        billing: user?.billing,
        shipping: user?.shipping,
        products: products,
        quantities: quantities,
        jwt: token,
        shipment_name: selectedCargoMethod?.name,
        shipment_id: selectedCargoMethod?.id,
        payment_method: selectedPaymentMethod,
        shipping_price: shipping,
      })
    );
  };
  const checkOrder = () => {
    if (items.length == 0) {
      notify("Your cart is empty", "danger");
      return;
    }
    if (!selectedPaymentMethod || selectedPaymentMethod == "placeholder") {
      notify("Please select a payment method", "danger");
      return;
    }

    if (
      (typeof selectedCargoMethod == "object" &&
        Object.keys(selectedCargoMethod)?.length == 0) ||
      selectedCargoMethod == "placeholder"
    ) {
      notify("Please select a shipping method", "danger");
      return;
      return;
    }

    if (user?.shipping == null) {
      notify("Please add a shipping address", "danger");
      return;
    }

    if (user.billing == null) {
      notify("Please add a billing address", "danger");
      return;
    }

     if (totalPrice < 500) {
      notify("Minimum order amount is €500", "danger");
      return;
    } 
    if (isConfirmed == false) {
      notify("Please confirm the terms and conditions", "danger");
      return;
    }

    if (
      selectedPaymentMethod &&
      selectedPaymentMethod != "placeholder" &&
      selectedCargoMethod != "placeholder" &&
      selectedCargoMethod &&
      items.length > 0 &&
      user?.shipping != null &&
      user?.billing != null &&
      /*     subtotal >= 1000 && */
      isConfirmed
    ) {
      handleCreateOrder();
    }
  };
  useEffect(() => {
    if (user?.shipping) {
      dispatch(
        // @ts-ignore
        getCargoMethods({
          to: {
            post_code: user?.shipping?.postcode,
            country: user?.shipping?.country,
          },
          quantity: totalQuantity,
        })
      );
    }
  }, [user?.shipping?.country, items?.length, totalQuantity]);

  return (
    <Page>
      <Section title="Checkout" subtitle="Review your order details">
        <div className="flex flex-col  gap-12">
          <div className="grid md:grid-cols-2 gap-4 ">
            <div>
              <h5 className="text-gray-dark my-4 pl-6">Billing Address</h5>
              <AddresCard title={"billing"} />
            </div>

            <div>
              <h5 className="text-gray-dark my-4 pl-6">Shipping Address</h5>
              <AddresCard title={"shipping"} />
            </div>
          </div>
          <Accordion title="Shipping Method">
            <CardInnerBorder innerCard="py-6  px-9 grid md:grid-cols-2  gap-12">
              <div className="flex flex-col gap-4">
                <h5>Carrier Service</h5>
                <SecondarySelect
                  onChange={(e: React.ChangeEvent) => handleMethodChange(e)}
                  options={cargoMethods}
                  className="!w-full"
                  placeholder="Carrier Service"
                />
              </div>
              <div className="flex items-center flex-col md:flex-row">
                <div className="flex  gap-4 items-center whitespace-nowrap">
                  <BsTruck />
                  <h5> Shipment with trusted partners</h5>
                </div>

                <div className="flex gap-6  md:ml-12  w-full h-fit">
                  <img
                    className="object-contain"
                    src="/cargo/fedex.png"
                    alt=""
                  />
                  <img className="object-contain" src="/cargo/ups.png" alt="" />
                  <img
                    className="object-contain"
                    src="/cargo/postnl.png"
                    alt=""
                  />
                  <img className="object-contain" src="/cargo/dhl.png" alt="" />
                </div>
              </div>
            </CardInnerBorder>
          </Accordion>

          <Accordion title="Payment Method">
            <CardInnerBorder innerCard="py-6 px-9 grid md:grid-cols-2  gap-12">
              <div className="flex flex-col gap-4">
                <h5>Payment Provider</h5>
                <SecondarySelect
                  onChange={(e: React.ChangeEvent) =>
                    handlePaymentMethodChange(e)
                  }
                  options={paymentMethods}
                  className=""
                  placeholder="Payment Provider"
                />
              </div>
              <div className="flex items-center flex-col gap-2 md:gap-0 md:flex-row">
                <div className="flex gap-4 items-center whitespace-nowrap">
                  <BsFillLockFill />
                  <h5> Secured payment</h5>
                </div>

                <div className="flex gap-4 md:gap-6  md:ml-12  w-full h-fit">
                  <img
                    className="object-contain"
                    src="/payment/mastercard.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/visa.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/american_express.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/maestro.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/ideal.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/bank.png"
                    alt=""
                  />
                  <img
                    className="object-contain"
                    src="/payment/klarna.png"
                    alt=""
                  />
                </div>
              </div>
            </CardInnerBorder>
          </Accordion>

          <Accordion title="Selected products">
            <CardInnerBorder
              innerCard="p-0  group-hover:!bg-white"
              className=" col-span-full"
            >
              {items.map((item: any, index: number) => {
                return <CartItem key={index} product={item} />;
              })}

              {items.length > 0 ? (
                <div className="grid md:grid-cols-12 mt-6">
                  <div className=" md:col-start-8 md:col-end-11 flex flex-col gap-1 mx-14">
                    <div className="flex justify-between">
                      <h5>Subtotal</h5>
                      <h5>{priceFormatter.format(subtotal)}</h5>
                    </div>
                    <div className="flex justify-between">
                      <h5>Shipping Price</h5>
                      <h5>{priceFormatter.format(shipping)}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h5>VAT cost ({taxRate * 100}%)</h5>
                      <h5> {priceFormatter.format(taxAmount)}</h5>
                    </div>
                    <div className="flex justify-between">
                      <h5>Payment fee</h5>
                      <h5> {priceFormatter.format(paymentFee)}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h4 className="font-semibold">Total</h4>
                      <h4 className="font-semibold">
                        {priceFormatter.format(totalPrice)}
                      </h4>
                    </div>
                  </div>

                  <div className="md:col-start-11 md:col-end-13 mt-4 md:mt-0  flex justify-center md:justify-end px-4">
                    <IconButtonOutline
                      // @ts-ignore
                      onClick={() => dispatch(clearCart())}
                      className={`   hover:!bg-green hover:!text-white !bg-gray-lighter text-xs !w-fit  border-green text-green `}
                    >
                      Clear All
                    </IconButtonOutline>
                  </div>
                </div>
              ) : (
                /* No items in Cart component */
                <div className="w-full h-fit py-10">
                  <h5 className="text-center">No items in cart</h5>
                </div>
              )}
            </CardInnerBorder>
          </Accordion>

          <div className="flex items-center">
            <Checkbox
              action={(checked: boolean) => {
                setIsConfirmed(checked);
                console.log(checked);
              }}
            />
            <p className="text-black ml-4">
              By clicking "I agree" or continuing to use this website, you are
              acknowledging that you have read, understood, and agree to abide
              by the{" "}
              <Link to={"/terms-conditions"} className="text-green  underline">
                {" "}
                terms and conditions
              </Link>{" "}
              outlined herein.
            </p>
          </div>
        </div>

        <button
          onClick={() => checkOrder()}
          className="bg-gray-lighter  flex gap-4 items-center justify-center mx-auto my-16  w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white"
        >
          <BsBagPlusFill className="text-inherit" />
          <h5 className="text-inherit">Confirm & Pay </h5>
        </button>
      </Section>
    </Page>
  );
};

const AddresCard = ({ children, className, title, ...props }: any) => {
  const [showModal, setShowModal] = React.useState(false);
  const { loggedIn, user, differentAddress } = useAppSelector(
    (state) => state.user || {}
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    // @ts-ignore
    dispatch(getAddresses());
    // @ts-ignore
    dispatch(getUserInfo());

    if (user?.vat_number) {
      dispatch(
        checkVatNumber({
          vat_number: user?.vat_number,
        })
      );
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(
      updateTotalsCart({
        country: user?.shipping?.country,
        vat_is_valid: user?.vat_is_valid,
      })
    );
  }, [user?.vat_number, user?.shipping?.country]);

  return (
    <CardInnerBorder innerCard="py-6 px-9">
      <AddresForm
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
      />
      {user?.[title] ? (
        <div className="grid grid-cols-2">
          <div className="col-span-2 flex justify-between">
            <div>
              <h5>{user?.[title].company}</h5>
              <p className="text-black">{user?.[title].address_1}</p>
              <p className="text-black">
                {user?.[title].postcode}, {user?.[title].city}
              </p>
              <p className="text-black">{user?.[title].postcode.country}</p>
            </div>

            <IconButton onClick={() => setShowModal(true)} className="h-fit">
              <BsPen />
            </IconButton>
          </div>

          <div className="col-span-2 flex justify-between">
            <div className="mt-8">
              <p className="text-black">
                Email: {loggedIn ? user?.email : user?.[title].email}
              </p>
              <p className="text-black">Phone: {user?.[title].phone}</p>
            </div>

            <div className="bg-gray-bg rounded-30 flex flex-col justify-center items-center w-52 h-20 border-gray-dark">
              <h5>VAT Number</h5>
              <div className="flex  items-center gap-2">
                <p className="text-black">
                  {user?.vat_number?.number || user?.vat_number}
                </p>{" "}
                <span className="">
                  {" "}
                  {user?.vat_is_valid == "true" ? (
                    <div className="flex item-center gap-2">
                      <p>Valid</p>
                      <BsCheckCircleFill className="text-green" />
                    </div>
                  ) : (
                    <div className="flex item-center gap-2">
                      <p>Invalid</p>
                      <BsXCircleFill className="text-danger" />
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <h5 className="text-black">You are not logged in</h5>
          <p className="text-black">Please login to see your address</p>

          <div className="flex flex-col  items-center">
            <p className="text-center">
              {" "}
              If you want to checkout as guest, please add your address by
              clicking the button below
            </p>

            <IconButton
              onClick={() => setShowModal(true)}
              className="h-fit text-xs"
            >
              Add Address
            </IconButton>
          </div>
        </div>
      )}
    </CardInnerBorder>
  );
};

const AddresForm = ({ title, showModal, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const { loggedIn, user } = useAppSelector((state) => state.user || {});
  useEffect(() => {}, [user?.[title]]);
  const handleSubmit = (e: any) => {
    e.preventDefault();

    let address = {};
    let vat_number = {};
    Object.keys(e.target).map((key) => {
      if (e.target[key].name != "" && e.target[key].name != undefined) {
        address = { ...address, [e.target[key].name]: e.target[key].value };
      }
    });
    if (loggedIn) {
      dispatch(
        // @ts-ignore
        updateAddress({
          address,
          change: title,
          // @ts-ignore
          vat_number: address?.vat_number,
        })
      );
    } else {
      // @ts-ignore
      dispatch(setDifferentAddress({ [title]: address }));
    }
    if (title == "billing") {
      // @ts-ignore
      dispatch(checkVatNumber({ vat_number: address?.vat_number }));
    }
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={title + " Address"}
    >
      <form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-4">
        <SecondaryInput
          defaultValue={user?.[title]?.first_name}
          required
          name="first_name"
          placeholder="First name*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.last_name}
          required
          name="last_name"
          placeholder="Last name*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.phone}
          required
          name="phone"
          placeholder="Phone*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.company}
          name="company"
          placeholder="Company"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.address_1}
          required
          name="address_1"
          placeholder="Address*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.adress_2}
          name="address_2"
          placeholder="Address 2"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.city}
          required
          name="city"
          placeholder="City*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.postcode}
          required
          name="postcode"
          placeholder="Postcode*"
        />
        <SelectCountry
          defaultValue={user?.[title]?.country}
          required
          name="country"
          placeholder="Country*"
        />
        <SecondaryInput
          defaultValue={user?.[title]?.state}
          required
          name="state"
          placeholder="State*"
        />
        {title == "billing" && (
          <SecondaryInput
            defaultValue={user?.vat_number}
            name="vat_number"
            placeholder="VAT number"
          />
        )}
        {title == "billing" && !loggedIn && (
          <SecondaryInput
            defaultValue={user?.[title]?.email}
            required
            name="email"
            placeholder="Email*"
          />
        )}

        <div className="col-span-full flex justify-center">
          <button className=" bg-gray-lighter p-3 w-40 rounded-15 text-green hover:bg-green hover:text-white ">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Checkout;
