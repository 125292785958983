import React from "react";
import { BsChevronDown } from "react-icons/bs";

type Props = {
  options: any[];
    className?: string;
    handleChange?: (e: any) => void;

} & React.InputHTMLAttributes<HTMLSelectElement>;

const SecondarySelect = ({ options, className, handleChange,placeholder, ...props }: Props) => {

  return (
    <div className="relative w-60 md:w-80 ">
      <select
        className={`h-10 w-full text-xs text-gray-dark bg-gray-bg  border-y border-l border-gray-light outline-gray-dark rounded-15 px-6  ${className} `}
        {...props}

      >
        <option value="placeholder" disabled selected  hidden>
          {placeholder}
        </option>
        {options?.map((option: any) => {
          return (
            <option  className="text-start  w-60 md:w-80" value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
   
        <BsChevronDown className=" text-sm  absolute  top-1/2 -translate-y-1/2 right-4" />

    </div>
  );
};

export default SecondarySelect;
