
import CardInnerBorder from "../../components/Card/CardInnerBorder";
import Section from "../../components/Elements/Semantic/Section";
import Page from "../../components/Layout/Page";
import { Link } from "react-router-dom";
import { SecondaryInput } from "../../components/Input/Input";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { IconButton } from "../../components/Button/IconButton";
import { BsX } from 'react-icons/bs'
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { sendVerificationCode } from "../../app/User/actions/sendVerificationCode";
import { useEffect } from "react";

function ResetPassword() {
    const { status } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const handleResetPassword = (e: any) => {
        e.preventDefault()
        dispatch(sendVerificationCode({ email: e.target.email.value }))
    }

    useEffect(() => {
        if (status === 'success') {
            window.location.href = '/reset-link-sent'
        }
    }, [status])


    return (
        <Page>
            <Section title="Login" subtitle="Welcome to Fragrancedistri!">
                <CardInnerBorder className="sm:mx-10 md:mx-30 lg:mx-60">
                    <form onSubmit={handleResetPassword} className="grid grid-cols-2 sm:px-5 md:px-10 lg:px-4">
                        <div className="col-span-2 place-self-end mt-2">
                            <Link to="/Login">
                                <IconButton> <BsX className="text-lg" /></IconButton>
                            </Link>
                        </div>
                        <div className="col-span-2 mt-2 mb-10">
                            <img className="w-[201px] h-[201px] m-auto" src="logo/logo_green.png" alt="logo" />
                        </div>
                        <div className="col-span-2">
                            <p className="text-xs mb-2">Enter your email address to reset your password</p>
                            <SecondaryInput type="email" name="email" placeholder="Email" />
                        </div>
                        <div className="col-span-2 flex justify-center mt-10 mb-7" >
                         
                                <ButtonComponent type="submit" className="bg-gray-lighter w-40 h-12 rounded-15 text-green font-semibold text-xs hover:bg-green hover:text-white" >Send</ButtonComponent>
                         
                        </div>
                    </form>
                </CardInnerBorder>
            </Section>
        </Page>
    )
}

export default ResetPassword;