import React from "react";
import { Action } from "@reduxjs/toolkit";

type Props = {
  action?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

// @ts-ignore
const Checkbox = ({ className, action, ...props }: Props) => {
  const [checked, setChecked] = React.useState(false);
  return (
    <div
      onClick={() => {
        setChecked((prev) => !prev);
        action && action(!checked);
      }}
      className="w-6 h-6 object-contain rounded-full bg-white border border-green p-1.5 flex justify-center items-center  shadow-[0px_4px_10px_3px_rgba(222,222,222,0.3)]"
      {...props}
    >
      <div
        className={`w-full h-full  ${checked ? "bg-green" : ""} rounded-full`}
      ></div>
    </div>
  );
};

export default Checkbox;
