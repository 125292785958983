import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import cartReducer from './Cart/cartSlice';
import contactSlice from './Contact/contactSlice';
import filterSlice from './Filter/filterSlice';
import orderSlice from './Order/orderSlice';
import productSlice from './Product/Slices/productSlice';
import searchSlice from './Product/Slices/searchSlice';
import singleProductSlice from './Product/Slices/singleProductSlice';
import userSlice from './User/userSlice';
import wishlistSlice from './Wishlist/wishlistSlice';
export const store = configureStore({
  reducer: {
    cart : cartReducer,
    contact : contactSlice,
    filter : filterSlice,
    order : orderSlice,
    product : productSlice,
    search : searchSlice,
    singleProduct : singleProductSlice,
    user : userSlice,
    wishlist : wishlistSlice,
  }, 

  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
