import React from "react";

export type CardProps = {
  children?: React.ReactNode;
  rounded?:
    | "rounded-40"
    | "rounded-20"
    | "rounded-10"
    | "rounded-5"
    | "rounded-0";
  productId?: any;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = ({
  children,
  rounded = "rounded-40",
  className,
  ...props
}: CardProps) => {
  return (
    <div
      {...props}
      className={`bg-white  shadow-default  p-3 ${rounded} ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
