import React from "react";
import { site } from "./websiteInfo";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

type Props = {
    className?: string;
};

export const Logo = ({className}: Props) => {
  return  <Link className={className} to="/">
    <img  className={className} src="/logo/logo_green4x.png" alt="" />
    
  </Link>
};

export const Email = (props : any) => {
  return <a {...props} href={"mailto:" + site.email}> {site.email}</a>;
};

export const Phone = (props : any) => {

  return  <a {...props} href={"tel:" + site.phone}> {site.phone}</a>


};

export const Address = (props: any) => {
  return <div {...props}>{
    site.address
  }</div>;
};

export const Instagram = () => {
  return <a href="">
    <BsInstagram  className="text-green text-2xl" />
  </a>;
};

export const Facebook = () => {
  return  <a href="">
    <BsFacebook  className="text-green text-2xl" />
  </a>
};

export const Linkedin = () => {
  return <a href="">
    <BsLinkedin  className="text-green text-2xl" />
  </a>
};


