import React from "react";
import { useNavigate } from "react-router-dom";
export type ButtonProps = {
  to?: string;
  color?:
    | "primary"
    | "secondary"
    | "danger"
    | "success"
    | "warning"
    | "info"
    | "light"
    | "dark";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function ButtonComponent({ children, className, onClick, to, type = "button" }: ButtonProps) {
  const navigate = useNavigate();
  return (
    <button
      type={type}
      onClick={(to && (() => navigate(to || "/"))) || onClick}
      className={`${className}`}
    >
      {children}
    </button>
  );
}



export default ButtonComponent;
