import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { store } from "../../store";
import { clearCart } from "../../Cart/cartSlice";

export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `https://fragrancedistri.com/wp/wp-json/wp/v3/orders/create`,
        args
      );
      if (response.status == 200) {
        store.dispatch(clearCart());
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
