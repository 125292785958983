import Cookies from "js-cookie";

function CalculateVAT(country, vat_is_valid) {
  var user = Cookies.get("fd-user") && JSON.parse(Cookies.get("fd-user"));
  var isValid = vat_is_valid || user?.vat_is_valid;
  var shippingCountry = country || user?.shipping?.country;
  var vatRate = 0.21;

  const europeanCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
  ];

  if (isValid === "true" || isValid === "1") {
    if (shippingCountry === "NL") {
      return vatRate;
    } else if (europeanCountries.includes(shippingCountry)) {
      return 0;
    }
    return 0;
  } else {
    if (europeanCountries.includes(shippingCountry)) {
      return vatRate;
    } else {
      return 0;
    }
  }
}

export default CalculateVAT;
