import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { setSelectedFilters } from "../app/Filter/filterSlice";
import { useDispatch } from "react-redux";

function usePriceListFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [endpoint, setEndpoint] = useState(
    "https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/"
  );
  const [filterURL, setFilterURL] = useState({});

  useEffect(() => {
    let tempURL = "";

    searchParams.forEach((value, key) => {
      if (value != null) {
        if (key === "category") {
          tempURL += `&${key}=${value}`;
        } else {
            tempURL += `&filter[${key}]=${value}`;}
      }
    });

    /* setEndpoint("https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?")
    searchParams.forEach((value, key) => {
      if (value != null) {
        if (key === "category" || key === "orderby" || key === "order"  || key === "search_product" ) {
          tempURL += `&${key}=${value}`;
        } else if( key === "per_page"){
          tempURL += `per_page=${value}`;
        }
        else tempURL += `&filter[${key}]=${value}`;
      }
    });
    dispatch(setSelectedFilters(Object.fromEntries(searchParams)));
    setFilterURL(tempURL);
    setEndpoint((base) => base + tempURL); */
  }, [searchParams]);

  return { endpoint, setSearchParams, searchParams, filterURL };
}

export default usePriceListFilter;
