import { createSlice } from "@reduxjs/toolkit";
import { getProducts } from "../actions/getProducts";
import { searchProductsList } from "../actions/searchProductList";
import { getAllProducts } from "../actions/getAllProducts";
const initialState = {
  products: [],
  loading: false,
  error: null,
  total_pages: 0,
  total_products: 0,
  allProducts: [],
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.products);
      state.products = action.payload.products;

      state.total_products = action.payload.total_products;
      state.total_pages = action.payload.total_pages;
    });

    builder.addCase(getProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(searchProductsList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      searchProductsList.fulfilled,
      (state, { payload, meta }) => {
        state.products = payload.products;
        state.loading = false;
      }
    );
    builder.addCase(searchProductsList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload.products.map((product) => {
        return { ...product, price: product.price.toFixed(2) };
      });
    });
  },
});

export default productSlice.reducer;
