import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const checkVatNumber = createAsyncThunk(
  "user/checkVatNumber",
  async (args: any, thunkAPI) => {
    try {
      console.log(args);
      const response = await axios.post(
        `https://fragrancedistri.com/wp/wp-json/wp/v3/vat_check`,
        args
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response });
    }
  }
);
