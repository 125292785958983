import React from "react";
import { Bs0CircleFill, BsCircleFill } from "react-icons/bs";

type Props = {
  status: string | undefined | null;
};

const OrderStatus = ({ status = "on-hold" }: Props) => {
  if (status == "pending")
    return (
      <div className="flex items-center text-xs">
        <BsCircleFill className="text-yellow-500 mr-2" />
        <span className="text-yellow-500">Pending</span>
      </div>
    );

  if (status === "on-hold")
    return (
      <div className="flex items-center text-xs">
        <BsCircleFill className="text-blue-500 mr-2" />
        <span className="text-blue-500">On hold</span>
      </div>
    );

  if (status === "paid")
    return (
      <div className="flex items-center text-xs">
        <BsCircleFill className="text-green-500 mr-2" />
        <span className="text-green-500">Completed</span>
      </div>
    );

  if (status == "completed")
    return (
      <div className="flex items-center text-xs">
        <BsCircleFill className="text-green-500 mr-2" />
        <span className="text-green-500">Completed</span>
      </div>
    );

  if (status === "cancelled")
    return (
      <div className="flex items-center text-xs">
        <BsCircleFill className="text-red-500 mr-2" />
        <span className="text-red-500">Cancelled</span>
      </div>
    );

  return (
    <div className="flex items-center text-xs">
      <BsCircleFill className="text-gray-500 mr-2" />
      <span className="text-gray-500">Unknown</span>
    </div>
  );
};

export default OrderStatus;
