import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { setSelectedFilters } from "../app/Filter/filterSlice";
import { useDispatch } from "react-redux";

import { store } from "../app/store";

function useFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [filterURL, setFilterURL] = useState({});
  const [endpoint, setEndpoint] = useState(
    "https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/?per_page=20"
  );

  useEffect(() => {
    let temp = "?per_page=20";
    let params = {
      per_page: "&per_page=",
      category: "&category=",
      pa_brand: "&filter[pa_brand]=",
      stock: "&stock_status=",
      min_price: "&filter[min_price]=",
      max_price: "&filter[max_price]=",
      pa_gender: "&filter[pa_gender]=",
      orderby: "&orderby=",
      search_product: "&search_product=",
      pa_size: "&filter[pa_size]=",
      pa_color: "&filter[pa_color]=",
      order: "&order=",
      page: "&page=",
    };

    Object.keys(params).forEach((key) => {
      if (searchParams.getAll(key).length > 0) {
        const combined = searchParams.getAll(key).join(",");
        if (combined !== "") {
          temp += params[key] + combined;
        }
      }
    });
    setFilterURL(temp);
    dispatch(setSelectedFilters(Object.fromEntries(searchParams)));
    setEndpoint(
      `https://www.fragrancedistri.com/wp/wp-json/wp/v3/products/${temp}`
    );
  }, [searchParams, location.pathname]);

  return { endpoint, setSearchParams, searchParams, filterURL };
}

export default useFilter;
