import React, { useEffect, useState } from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import Card from "../components/Card/Card";
import { IconButton, IconButtonCircle } from "../components/Button/IconButton";
import {
  BsArrowRight,
  BsBagPlusFill,
  BsChevronLeft,
  BsChevronRight,
  BsFillLockFill,
  BsPlusLg,
  BsTruck,
} from "react-icons/bs";
import { SecondaryButton } from "../components/Button/SecondaryButton";
import { NumberInputSecondary } from "../components/Input/NumberInput";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { ProductCard } from "../components/Card/ProductCard";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getSingleProduct } from "../app/Product/actions/getSingleProduct";
import ProductBarcode from "../components/Common/ProductBarcode";
import { addItemToCart } from "../app/Cart/cartSlice";

type Props = {};

const SingleProduct = (props: Props) => {
  const { slug } = useParams();
  const [number, setNumber] = useState(0);
  const dispatch = useAppDispatch();
  const product = useAppSelector((state) => state.singleProduct.product);
  console.log(slug, "slug");
  useEffect(() => {
    //@ts-ignore
    dispatch(getSingleProduct(slug));
  }, [slug]);

  return (
    <Page>
      <Section title="Product details" subtitle="Product details">
        <div className="grid md:grid-cols-2  gap-4">
          <ProductImages images={product.images} />
          <ProductDetails />
        </div>
      </Section>
      <RelatedProducts />
    </Page>
  );
};

export default SingleProduct;

const ProductImages = ({ images }: any) => {
  const [selectedImage, setSelectedImage] = React.useState(0);

  const handleImageChange = (direction: any) => {
    if (direction === "left") {
      if (selectedImage === 0) {
        setSelectedImage(images?.length - 1);
      } else {
        setSelectedImage(selectedImage - 1);
      }
    } else {
      if (selectedImage === images?.length - 1) {
        setSelectedImage(0);
      } else {
        setSelectedImage(selectedImage + 1);
      }
    }
  };
  return (
    <Card>
      <div className="w-full border border-gray rounded-40 flex items-center justify-between py-6 md:py-12 px-4">
        <button
          onClick={() => handleImageChange("left")}
          className="bg-white shadow-default rounded-full  w-10 h-10 flex items-center justify-center"
        >
          <BsChevronLeft />
        </button>
        <img
          className="w-44 h-72 object-contain"
          src={images?.[selectedImage]}
          alt=""
        />
        <button
          onClick={() => handleImageChange("right")}
          className="bg-white shadow-default rounded-full  w-10 h-10 flex items-center justify-center"
        >
          <BsChevronRight />
        </button>
      </div>

      <div className="flex items-center gap-8 mt-10 px-10">
        {images?.map((image: string, index: number) => (
          <img
            key={index}
            onClick={() => setSelectedImage(index)}
            className={`w-12 h-20 cursor-pointer ${
              selectedImage == index ? "border-2 border-green" : ""
            }`}
            src={image}
            alt=""
          />
        ))}
      </div>
    </Card>
  );
};

const ProductDetails = () => {
  const [selectedTab, setSelectedTab] = React.useState("product");
  const product = useAppSelector((state) => state.singleProduct.product);
  const [value, setValue] = React.useState(1);
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 ">
        <div
          className={`w-full border-b-2 h-fit hover:border-green  pr-2 ${
            selectedTab == "product" ? "border-green" : "border-gray-light "
          }`}
        >
          <SecondaryButton
            onClick={() => setSelectedTab("product")}
            className={`mb-4 w-full h-10  hover:bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
              selectedTab == "product"
                ? " bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)]  "
                : ""
            }`}
          >
            Product
          </SecondaryButton>
        </div>

        <div
          className={`w-full border-b-2 h-fit hover:border-green border-gray-light pl-2  ${
            selectedTab == "description" ? "border-green" : "border-gray-light "
          } `}
        >
          <SecondaryButton
            onClick={() => setSelectedTab("description")}
            className={`mb-4 w-full h-10 hover:bg-white hover:shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)] ${
              selectedTab == "description"
                ? "bg-white shadow-[-2px_5px_10px_2px_rgba(222,222,222,0.5)]"
                : ""
            }`}
          >
            Description
          </SecondaryButton>
        </div>
      </div>

      <div className="">
        {selectedTab == "product" ? (
          <Card className="">
            <div className="border border-gray-light px-2 md:px-10  py-8 rounded-40 hover:bg-[#FAFAFA]">
              <p>{product.name}</p>
              <h4 className="mt-4">
                <span className="font-normal">Brand:</span> {product.brand.name}
              </h4>
              <h4 className="mt-2">
                <span className="font-normal">Stock:</span>{" "}
                {product?.stock_quantity}
              </h4>

              <div className="rounded-30 bg-gray-bg p-2 mt-6 flex gap-4 px-6 md:gap-0 flex-wrap items-center justify-between border border-gray-light">
                <NumberInputSecondary
                  max={product?.stock_quantity}
                  value={value}
                  setValue={setValue}
                />
                <p className="font-semibold">€ {product.price}</p>
                <IconButton
                  onClick={() =>
                    dispatch(
                      addItemToCart({
                        item: product,
                        quantity: value,
                      })
                    )
                  }
                  className="w-full md:w-auto flex md:block justify-center "
                >
                  <div className="flex gap-2 px-6  ">
                    <BsBagPlusFill className="text-inherit" />
                    <h5 className="text-inherit">Add to cart</h5>
                  </div>
                </IconButton>
              </div>
            </div>

            <div className="mb-2 mt-4 flex  gap-4 md:px-10 px-2">
              <div>
                <ProductBarcode ean={product.ean} />
              </div>

              <SecondaryButton className="bg-gray-bg w-full">
                <h4>
                  <span className="font-normal"> Size:</span>{" "}
                  {product.size.name}
                </h4>
              </SecondaryButton>
              <SecondaryButton className="bg-gray-bg w-full">
                <h4>
                  <span className="font-normal"> Gender:</span>{" "}
                  {product.gender.name}
                </h4>
              </SecondaryButton>
            </div>
          </Card>
        ) : (
          <CardInnerBorder innerCard="p-9">
            <h3>Description</h3>
            <p className="mt-8 text-black h-40">{product.description}</p>
          </CardInnerBorder>
        )}
      </div>

      <CardInnerBorder innerCard="px-10 !pt-6 !pb-0" className=" h-f">
        <div className="flex flex-col gap-4  md:flex-row">
          <div className="flex gap-4 items-center whitespace-nowrap">
            <BsFillLockFill />
            <h5> Secured payment</h5>
          </div>

          <div className="flex justify-around  w-full h-fit">
            <img
              className="object-contain"
              src="/payment/mastercard.png"
              alt=""
            />
            <img className="object-contain" src="/payment/visa.png" alt="" />
            <img
              className="object-contain"
              src="/payment/american_express.png"
              alt=""
            />
            <img className="object-contain" src="/payment/maestro.png" alt="" />
            <img className="object-contain" src="/payment/ideal.png" alt="" />
            <img className="object-contain" src="/payment/bank.png" alt="" />
            <img className="object-contain" src="/payment/klarna.png" alt="" />
          </div>
        </div>

        <div className="flex mt-1 flex-col gap-2  md:flex-row">
          <div className="flex gap-4 items-center whitespace-nowrap">
            <BsTruck className="" />
            <h5>Shipment with trusted partners</h5>
          </div>

          <div className="flex justify-around  w-full  h-10">
            <img className="object-contain" src="/cargo/fedex.png" alt="" />
            <img className="object-contain py-2" src="/cargo/ups.png" alt="" />
            <img className="object-contain" src="/cargo/postnl.png" alt="" />
            <img className="object-contain" src="/cargo/dhl.png" alt="" />
          </div>
        </div>
      </CardInnerBorder>
    </div>
  );
};

const RelatedProducts = () => {
  const relatedProducts = useAppSelector(
    (state) => state.singleProduct.product.related_products
  );
  return (
    <Section title="Related products" subtitle="">
      <div className="grid md:grid-cols-2 gap-4">
        {relatedProducts.map((product, index) => {
          return (
            // @ts-ignore
            <ProductCard relatedProduct={true} key={index} product={product} />
          );
        })}
      </div>

      <div className="flex justify-center mt-8 mb-12">
        {" "}
        <IconButtonCircle text={"Load more"}>
          <BsPlusLg className="font-bold" />
        </IconButtonCircle>
      </div>
    </Section>
  );
};
