import React, { useEffect } from "react";
import CardInnerBorder from "../../components/Card/CardInnerBorder";
import Section from "../../components/Elements/Semantic/Section";
import Page from "../../components/Layout/Page";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Button/ButtonComponent";
import Input, { SecondaryInput } from "../../components/Input/Input";
import PasswordInput from "../../components/Input/PasswordInput";
import { resetPassword } from "../../app/User/actions/resetPassword";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";

function ResetLinkSent() {
  const dispatch = useDispatch();
  const { isReseted } = useAppSelector((state: any) => state.user);
  const navigate = useNavigate();
  const handleResetPassword = (e: any) => {
    e.preventDefault();

    dispatch(
      // @ts-ignore
      resetPassword({
        email: e.target.email.value,
        password: e.target.password.value,
        code: e.target.code.value,
      })
    );
  };

  useEffect(() => {
    if (isReseted) {
      navigate("/login");
    }
  }, [isReseted]);

  return (
    <Page>
      <Section title="Login" subtitle="Welcome to Fragrancedistri!">
        <CardInnerBorder className="sm:mx-10 md:mx-30 lg:mx-60">
          <form
            onSubmit={handleResetPassword}
            className="grid grid-cols-2 sm:px-5 md:px-10 lg:px-4"
          >
            <div className="col-span-2 mt-2 mb-10">
              <img
                className="w-[116px] h-[116px] m-auto"
                src="logo/logo_green.png"
                alt="logo"
              />
            </div>
            <div className="col-span-2">
              <p className="text-center">Verification Code Sent</p>
            </div>
            <div className="col-span-2 mt-4 gap-4 flex flex-col">
              <SecondaryInput
                name="code"
                type="text"
                placeholder="Enter Verification Code"
              />
              <PasswordInput
                name="password"
                type="password"
                placeholder="Enter New Password"
              />
              <PasswordInput
                name="password_confirm"
                type="password"
                placeholder="Confirm New Password"
              />
              <SecondaryInput
                name="email"
                type="email"
                placeholder="Enter Email address"
              />
            </div>
            <div className="col-span-2 flex justify-center mt-10 mb-7">
              <ButtonComponent
                type="submit"
                className="bg-gray-lighter w-40 h-12 rounded-15 text-green font-semibold text-xs hover:bg-green hover:text-white"
              >
                Login
              </ButtonComponent>
            </div>
          </form>
        </CardInnerBorder>
      </Section>
    </Page>
  );
}

export default ResetLinkSent;
