import CardInnerBorder from "./CardInnerBorder";
import StockInfo from "../Common/StockInfo";
import {
  BsEyeFill,
  BsUpcScan,
  BsHeartFill,
  BsBagPlusFill,
  BsX,
  BsCheck,
} from "react-icons/bs";
import { motion } from "framer-motion";
import NumberInput from "../Input/NumberInput";
import React from "react";
import Card, { CardProps } from "./Card";
import { IconButtonOutline } from "../Button/IconButton";
import Circle from "../Common/Circle";
import { isMobile } from "react-device-detect";
import { ProductType, RelatedProductType } from "../../types/Product";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addItemToCart, removeItemFromCart } from "../../app/Cart/cartSlice";
import { useEffect } from "react";
import { addToWishlist } from "../../app/Wishlist/wishlistSlice";
import Barcode from "react-barcode";
import ProductBarcode from "../Common/ProductBarcode";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import Modal from "../Modal/Modal";
import priceFormatter from "../../utils/priceFormatter";

type Props = {
  className?: string;
  children?: React.ReactNode;
  product?: ProductType & RelatedProductType;
  relatedProduct?: boolean;
};

export const ProductCard = ({
  children,
  className,
  product,
  relatedProduct = false,
  ...props
}: Props) => {
  const [isShown, setIsShown] = React.useState(isMobile);
  console.log(product, "product");
  if (isMobile) {
    return (
      <CardInnerBorder innerCard=" !bg-[#FAFAFA] !p-0 !pt-4">
        <div className="grid grid-cols-12  px-4">
          <Link
            to={`/product/${product?.slug}`}
            className="col-span-2 mr-2 md:mr-0"
          >
            <img
              className="object-contain w-16 h-16"
              src={relatedProduct ? product?.image : product?.images[0]}
              alt=""
            />
          </Link>
          <div className="col-span-10">
            <div className="flex  flex-col">
              <Link to={`/product/${product?.slug}`}>
                <h5 className="font-semibold">
                  {!relatedProduct && product?.brand?.name}
                </h5>
              </Link>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black ">
                  {isMobile &&
                  product?.name?.length &&
                  product?.name?.length > 20
                    ? product?.name.slice(0, 20) + "..."
                    : product?.name?.length && product?.name?.length > 40
                    ? product?.name.slice(0, 40) + "..."
                    : product?.name}
                </p>
                <div>
                  <h4 className="font-semibold">
                    {priceFormatter(product?.price)}
                  </h4>
                  <span className="text-[10px] text-[#7B7B7B]">Excl. VAT</span>
                </div>
              </div>
              <div
                className={`flex justify-between items-center mt-3  ${
                  isShown ? "relative" : ""
                }`}
              >
                {product?.stock_quantity ? (
                  <StockInfo stock={product?.stock_quantity} />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-1">
          {isShown ? (
            <ProductCardOptions product={product} productId={product?.id} />
          ) : (
            <div className="text-xs text-[#D4D4D4]">
              <BsEyeFill className="inline-block mr-3" />
              <BsUpcScan className="inline-block mr-3" />
              <BsHeartFill className="inline-block mr-3" />
              <BsBagPlusFill className="inline-block mr-3" />
            </div>
          )}
        </div>
      </CardInnerBorder>
    );
  } else {
    return (
      <CardInnerBorder
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <div className="grid grid-cols-12  px-4">
          <Link
            to={`/product/${product?.slug}`}
            className="col-span-2 mr-2 md:mr-0"
          >
            <img
              className="object-contain w-16 h-16"
              src={relatedProduct ? product?.image : product?.images[0]}
              alt=""
            />
          </Link>
          <div className="col-span-10">
            <div className="flex  flex-col">
              <Link to={`/product/${product?.slug}`}>
                <h5 className="font-semibold">
                  {!relatedProduct && product?.brand?.name}
                </h5>
              </Link>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black ">
                  {isMobile &&
                  product?.name?.length &&
                  product?.name?.length > 20
                    ? product?.name.slice(0, 20) + "..."
                    : product?.name?.length && product?.name?.length > 40
                    ? product?.name.slice(0, 40) + "..."
                    : product?.name}
                </p>
                <div>
                  <h4 className="font-semibold">
                    {priceFormatter(product?.price)}
                  </h4>
                  <span className="text-[10px] text-[#7B7B7B]">Excl. VAT</span>
                </div>
              </div>
              <div
                className={`flex justify-between items-center mt-3  ${
                  isShown ? "relative" : ""
                }`}
              >
                {product?.stock_quantity ? (
                  <StockInfo stock={product?.stock_quantity} />
                ) : (
                  <div></div>
                )}
                {isShown ? (
                  <ProductCardOptions
                    product={product}
                    productId={product?.id}
                  />
                ) : (
                  <div className="text-xs text-[#D4D4D4]">
                    <BsEyeFill className="inline-block mr-3" />
                    <BsUpcScan className="inline-block mr-3" />
                    <BsHeartFill className="inline-block mr-3" />
                    <BsBagPlusFill className="inline-block mr-3" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardInnerBorder>
    );
  }
};

const ProductCardOptions = ({
  children,
  className,
  productId,
  product,
  ...props
}: CardProps & { product: ProductType | undefined }) => {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [InWislist, setInWislist] = React.useState(false);
  const wishlistItems = useAppSelector((state) => state.wishlist.products);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (wishlistItems.find((item: any) => item.id == productId)) {
      setInWislist(true);
    } else {
      setInWislist(false);
    }
  }, [wishlistItems]);

  const handleOption = (option: string) => {
    switch (option) {
      case "add_to_cart":
        return (
          <AddToCartOption
            product={product}
            setSelectedOption={setSelectedOption}
          />
        );
      case "scan_product":
        return (
          <BarcodeOption
            ean={product?.ean}
            setSelectedOption={setSelectedOption}
          />
        );
      default:
        return (
          <>
            <Link to={`/product/${productId}`}>
              <IconButtonOutline
                className={"border-green"}
                text="Product details"
              >
                <BsEyeFill className=" " />
              </IconButtonOutline>
            </Link>
            <IconButtonOutline
              className={"border-green"}
              onClick={() => setSelectedOption("scan_product")}
              text="Product barcode"
            >
              <BsUpcScan className=" " />
            </IconButtonOutline>
            <IconButtonOutline
              onClick={() => dispatch(addToWishlist(product))}
              text={
                InWislist ? (
                  <div className="flex items-center justify-center gap-1 ">
                    <BsCheck className="!text-base" /> Added
                  </div>
                ) : (
                  "Add to wishlist"
                )
              }
              className={` border-green  ${
                InWislist ? "!bg-green !text-white " : ""
              }`}
            >
              <BsHeartFill className=" " />
            </IconButtonOutline>
            <IconButtonOutline
              className={"border-green"}
              onClick={() => setSelectedOption("add_to_cart")}
              text="Add to cart"
            >
              <BsBagPlusFill className=" " />
            </IconButtonOutline>
          </>
        );
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, x: 100, y: 10 },
        visible: { opacity: 1, x: 0, y: 5, transition: { duration: 0.2 } },
      }}
    >
      <Card
        className={`md:w-[400px] w-full rounded-t-none md:rounded-t-40 border md:border-none border-gray-lighter !shadow-none !gap-4 md:!gap-4  -right-10 -bottom-7  flex  ${
          selectedOption ? "justify-end" : "gap-4 justify-center"
        }  !p-2 md:absolute`}
      >
        {handleOption(selectedOption)}
      </Card>
    </motion.div>
  );
};

const AddToCartOption = ({
  children,
  className,
  setSelectedOption,
  product,
  ...props
}: CardProps & {
  setSelectedOption: Function;
  product: ProductType | undefined;
}) => {
  const [inCart, setInCart] = React.useState(false);
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);

  const handleAddToCart = () => {
    dispatch(addItemToCart({ item: product, quantity: value }));
    setInCart(true);
    setTimeout(() => {
      setInCart(false);
    }, 500);
  };

  return (
    <div className="flex items-center  mr-4 ">
      {inCart ? (
        <div className="w-36 h-10 border border-green  rounded-[17px]  flex items-center justify-center ">
          <BsCheck className="mr-2" />
          <span className="text-xs">Added to cart</span>
        </div>
      ) : (
        <NumberInput
          max={product?.stock_quantity}
          value={value}
          setValue={setValue}
        />
      )}
      <IconButtonOutline
        onClick={handleAddToCart}
        className={`ml-3 ${inCart ? "!bg-green !text-white" : ""}`}
      >
        <BsBagPlusFill className=" " />
      </IconButtonOutline>
      <button
        onClick={() => setSelectedOption("")}
        className="w-6 h-6  shadow-default rounded-full flex items-center justify-center  ml-3"
      >
        <BsX className="text-[8]" />
      </button>
    </div>
  );
};

const BarcodeOption = ({
  children,
  className,
  setSelectedOption,
  ean,
  ...props
}: CardProps & {
  setSelectedOption: Function;
  ean: number | undefined | string;
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const handleClick = () => {
    copyToClipboard(ean);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="flex items-center  justify-end mr-4">
      <div>
        {isCopied ? (
          <div className="w-36 h-10 border border-green  rounded-[17px]  flex items-center justify-center ">
            <BsCheck className="mr-2" />
            <span className="text-xs">Copied</span>
          </div>
        ) : (
          <div onClick={handleClick}>
            <ProductBarcode small ean={ean} />
          </div>
        )}
      </div>
      <IconButtonOutline
        onClick={handleClick}
        className={`ml-3 !border-green !bg-green-lighter `}
      >
        <BsUpcScan className="!bg-gray-lighter !text-green " />
      </IconButtonOutline>
      <button
        onClick={() => setSelectedOption("")}
        className="w-6 h-6  shadow-default rounded-full flex items-center justify-center  ml-3"
      >
        <BsX className="text-[8]" />
      </button>
    </div>
  );
};

export const ProductListCard = ({
  children,
  className,
  product,
  ...props
}: Props) => {
  const [inCart, setInCart] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.cart.items);
  const [showModal, setShowModal] = React.useState(false);
  const wishlistItems = useAppSelector((state) => state.wishlist.products);
  useEffect(() => {
    if (product) {
      // @ts-ignore
      const cartItem = cartItems.find((item) => item.id == product.id);
      if (cartItem) {
        // @ts-ignore
        setValue(cartItem.quantity);
      }
    }
  }, [product]);
  const handleAddToCart = () => {
    dispatch(addItemToCart({ item: product, quantity: value }));
    setInCart(true);
    setTimeout(() => {
      setInCart(false);
    }, 500);
  };
  if (isMobile) {
    return (
      <div className=" border border-gray rounded-40  cardInner py-4 hover:bg-[#FAFAFA]">
        <Modal
          title={"Scan Product"}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <div className="flex justify-center flex-col items-center">
            <ProductBarcode ean={product?.ean} />

            <p className="text-danger text-xs pt-8">
              You need to use your mobile device camera to scan the product you
              would like to search.
            </p>
          </div>
        </Modal>
        <div className="grid grid-cols-12  md:px-4 px-2">
          <Link to={`/product/${product?.slug}`} className="col-span-2 ">
            <img
              className="object-contain my-auto w-16 h-16"
              src={product?.images[0]}
              alt=""
            />
          </Link>
          <div className="col-span-10">
            <div className="flex  flex-col ml-2">
              <Link to={`/product/${product?.slug}`}>
                <h5 className="font-semibold">{product?.brand?.name}</h5>
              </Link>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black">{product?.name}</p>
              </div>
              <div className="flex  justify-around items-center mt-2">
                <StockInfo stock={product?.stock_quantity} className="" />

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name} ml
                </div>

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name} ml
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 flex items-center justify-between  mt-4">
            <IconButtonOutline
              onClick={() => setShowModal(true)}
              className={`md:ml-3 !border-green !bg-green-lighter `}
            >
              <BsUpcScan className="!bg-gray-lighter !text-green " />
            </IconButtonOutline>
            <div>
              <h4 className="font-semibold">
                {priceFormatter(product?.price)}
              </h4>
              <span className="text-[10px] text-[#7B7B7B]">Excl. VAT</span>
            </div>
            <div className="flex items-center  ">
              {inCart ? (
                <div className="md:w-36 w-32 h-10 border border-green  rounded-[17px]  flex items-center justify-center ">
                  <BsCheck className="mr-2" />
                  <span className="text-xs">Added to cart</span>
                </div>
              ) : (
                <NumberInput
                  max={product?.stock_quantity}
                  value={value}
                  setValue={setValue}
                />
              )}
              <IconButtonOutline
                onClick={handleAddToCart}
                className={`ml-3 border-green ${
                  inCart ? "!bg-green !text-white" : ""
                }`}
              >
                <BsBagPlusFill className=" " />
              </IconButtonOutline>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-12 border border-gray rounded-40  cardInner hover:bg-[#FAFAFA] px-4">
        <div className="col-span-4 flex items-center gap-4  h-20">
          <IconButtonOutline
            onClick={() => dispatch(addToWishlist(product))}
            text={
              wishlistItems.find((item: any) => item.id == product?.id) ? (
                <div className="flex items-center justify-center gap-1 ">
                  <BsCheck className="!text-base" /> Added
                </div>
              ) : (
                "Add to wishlist"
              )
            }
            className={` border-green  ${
              wishlistItems.find((item: any) => item.id == product?.id)
                ? "!bg-green !text-white "
                : ""
            }`}
          >
            <BsHeartFill className=" " />
          </IconButtonOutline>

          <Link
            to={`/product/${product?.slug}`}
            className=" px-2 bg-white  rounded-[20px]"
          >
            <img
              className="object-contain  w-8 h-14 "
              src={product?.images[0]}
              alt=""
            />
          </Link>

          <Link to={`/product/${product?.slug}`}>
            <h5>{product?.brand?.name}</h5>
            <p className="text-black">{product?.name}</p>
          </Link>
        </div>
        <div className="col-span-5  flex justify-around items-center text-center ">
          <StockInfo stock={product?.stock_quantity} />
          <div>
            <p className="text-black">Size</p>
            <p className="text-black">{product?.size.name}</p>
          </div>
          <div>
            <p className="text-black">Gender</p>
            <p className="text-black">Unisex</p>
          </div>
          <div>
            <ProductBarcode ean={product?.ean} />
          </div>
        </div>
        <div className="col-span-3 flex justify-around items-center">
          <div>
            <h4 className="font-semibold">{priceFormatter(product?.price)}</h4>
            <span className="text-[10px] text-[#7B7B7B]">Excl. VAT</span>
          </div>
          <div className="flex items-center">
            <NumberInput
              max={product?.stock_quantity}
              small
              value={value}
              setValue={setValue}
            />
            <IconButtonOutline
              onClick={handleAddToCart}
              className={`ml-3 ${
                inCart
                  ? "!bg-green !text-white"
                  : "!bg-gray-lighter border-green text-green"
              }`}
            >
              <BsBagPlusFill className=" " />
            </IconButtonOutline>
          </div>
        </div>
      </div>
    );
  }
};
