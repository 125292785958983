import React from "react";
import { SecondaryInput, SecondaryTextArea } from "../Input/Input";
import { useSelector } from "react-redux";
import { sendEmail } from "../../app/Contact/sendEmail";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

type Props = {
  title: string;
};

const ContactForm = ({ title }: Props) => {
  const dispatch = useAppDispatch();
  const { loading, sended } = useAppSelector((state) => state.contact);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let args = {};
    Object.values(e.target).map((value: any) => {
      const place = value.name;
      const value1 = value.value;
      if (place && value1) args = { ...args, [place]: value1 };
    });
    // @ts-ignore
    dispatch(sendEmail(args));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="grid md:grid-cols-2 gap-4 py-8 px-4 md:px-12 "
    >
      <p className="col-span-full mx-auto mb-4">{title}</p>
      <SecondaryInput name="name" required type="text" placeholder="Name*" />
      <SecondaryInput
        name="last_name"
        required
        type="text"
        placeholder="Last Name*"
      />
      <SecondaryInput name="email" required type="email" placeholder="Email*" />
      <SecondaryInput name="phone" type="tel" placeholder="Phone" />
      <SecondaryTextArea
        className="col-span-full"
        required
        name="message"
        placeholder="Message*"
      />

      <button className="h-12 w-40 col-span-full mx-auto mt-4  bg-gray-lighter rounded-15 text-green text-xs  hover:bg-green hover:text-white">
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
