import React from "react";
import { ButtonProps } from "../Button/ButtonComponent";

type Props = {
  text?: string | JSX.Element;
} & any;

const Tooltip = ({ children, text, className, ...props }: Props) => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <div
      {...props}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      className={`relative w-fit ${className}`}
    >
      {children}
      {isShown && text && (
        <div className="absolute top-11  left-1/2 -translate-x-1/2    bg-white default-shadow py-2 px-4  rounded-[20px] shadow-[0px_4px_10px_3px_rgba(222,222,222,0.3)]">
          <h6 className="text-[10px] whitespace-nowrap text-gray-dark">
            {text}
          </h6>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
