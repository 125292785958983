import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../components/Button/ButtonComponent";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import Section from "../components/Elements/Semantic/Section";
import Page from "../components/Layout/Page";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { setUserInfo } from "../app/User/userSlice";

function RegisterConfirmation() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUserInfo({ newRegister: false }));
  }, []);
  return (
    <Page>
      <Section title="Login" subtitle="Welcome to Fragrancedistri!">
        <CardInnerBorder className="sm:mx-10 md:mx-30 lg:mx-56">
          <div className="grid grid-cols-2 sm:px-5 md:px-16">
            <div className="col-span-2 mt-14 mb-10">
              <img
                className="w-[116px] h-[116px] m-auto"
                src="logo/logo_green.png"
                alt="logo"
              />
            </div>
            <div className="col-span-2">
              <p className="text-center font-semibold">Registration received</p>
            </div>
            <div className="col-span-2 mt-5">
              <p className="text-center text-black text-[14px]">
                We have successfully received your registration. A confirmation
                has been sent to your email address. We will review your
                application and get back to you as soon as possible.
              </p>
              <p className="text-center text-black text-[14px] mt-8">
                Don't hesitate to contact us if you have any question!
              </p>
            </div>
            <div className="col-span-2 flex justify-center mt-10 mb-12">
              <Link to="/">
                <ButtonComponent className="bg-gray-lighter w-40 h-12 rounded-15 text-green font-semibold text-xs hover:bg-green hover:text-white">
                  Back to home
                </ButtonComponent>
              </Link>
            </div>
          </div>
        </CardInnerBorder>
      </Section>
    </Page>
  );
}

export default RegisterConfirmation;
