import { Logo } from "../../../constants/StaticComponents";
import { SearchInput } from "../../Input/Input";
import { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  BsBagPlusFill,
  BsFilterLeft,
  BsHeartFill,
  BsHouseFill,
  BsPersonFill,
  BsUiChecks,
  BsX,
} from "react-icons/bs";
import { MenuItem } from "../../Sidebar/Sidebar";
import { useAppSelector } from "../../../app/hooks";
import Circle from "../../Common/Circle";

type Props = {};

const Navbar = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { items } = useAppSelector((state) => state.cart);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  if (isMobile) {
    return (
      <>
        <div className="flex bg-white w-full shadow-default justify-between items-center px-8">
          {isMenuOpen ? (
            <button className="w-10 h-10  rounded-15 bg-green  text-white flex items-center justify-center">
              <BsX
                onClick={() => setIsMenuOpen(false)}
                className="text-2xl cursor-pointer"
              />
            </button>
          ) : (
            <BsFilterLeft
              onClick={() => setIsMenuOpen(true)}
              className="text-2xl w-8 h-8 cursor-pointer"
            />
          )}
          <Link to="/">
            <img
              className="py-1 w-24 h-24 object-contain "
              src="/logo/logo_white.png"
              alt=""
            />
          </Link>
          <div className="flex gap-2">
            <MenuItem
              className="!bg-gray-lighter text-green"
              to="/cart"
              active={pathname == "/cart"}
            >
              <div className="relative p-1">
                <BsBagPlusFill className="text-inherit" />

                {items.length > 0 && (
                  <div className="absolute top-0 right-0 w-3 h-3 rounded-full text-[10px] flex items-center justify-center text-white bg-green">
                    {items.length}
                  </div>
                )}
              </div>
            </MenuItem>
            <MenuItem
              className="!bg-gray-lighter text-green"
              to="/my-account"
              active={searchParams.has("index")}
            >
              <BsPersonFill className="text-inherit" />
            </MenuItem>
          </div>
        </div>

        <div
          className={`absolute top-32 left-0 w-60 h-fit rounded-r-40 shadow-default bg-white z-50 p-2.5 pl-0 ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          <div className="border border-gray-light border-l-0 rounded-r-40">
            <MobileNavbarMenu />
          </div>
        </div>

        <div></div>
      </>
    );
  } else {
    return (
      <div className="flex bg-white w-full shadow-default ">
        <div className="default-container flex w-full items-center">
          <Link className="w-80 h-full" to="/">
            <Logo className="md:py-4 w-10 h-10 object-contain  sm:w-16 sm:h-16 md:w-20 md:h-20  lg:w-32 lg:h-32" />
          </Link>

          <NavbarMenu />
          <SearchInput className=" mx-6" />
          <div className="flex items-center gap-1">
            <MenuItem
              to="/my-account?wishlist"
              active={pathname == "/my-account"}
            >
              <BsHeartFill className="text-inherit" />
            </MenuItem>
            <MenuItem to="/cart" active={pathname == "/cart"}>
              <div className="relative p-1">
                <BsBagPlusFill className="text-inherit" />

                {items.length > 0 && (
                  <div className="absolute top-0 right-0 w-3 h-3 rounded-full text-[10px] flex items-center justify-center text-white bg-green">
                    {items.length}
                  </div>
                )}
              </div>
            </MenuItem>

            <MenuItem to="/my-account" active={searchParams.has("index")}>
              <BsPersonFill className="text-inherit" />
            </MenuItem>
          </div>
        </div>
      </div>
    );
  }
};

export default Navbar;

const NavbarMenu = () => {
  const { pathname } = useLocation();
  return (
    <ul
      className={`flex md:flex-row lg:gap-16 md:gap-10  gap-10 flex-col  my-auto  px-8 py-8   `}
    >
      <NavbarMenuItem to="/catalog" active={pathname == "/catalog"}>
        Product catalog
      </NavbarMenuItem>
      <NavbarMenuItem to="/pricelist" active={pathname == "/pricelist"}>
        Pricelist
      </NavbarMenuItem>
      <NavbarMenuItem to="/order-import" active={pathname == "/order-import"}>
        Order import
      </NavbarMenuItem>
      <NavbarMenuItem to="/contact" active={pathname == "/contact"}>
        Contact us
      </NavbarMenuItem>
    </ul>
  );
};

const NavbarMenuItem = ({ children, to, active }: MenuItemProps) => {
  return (
    <li
      className={`hover:text-green  whitespace-nowrap  hover:underline underline-offset-[12px]   decoration-green decoration-2 ${
        active ? "text-green underline underline-offset-[12px] " : ""
      }`}
    >
      <Link to={to}>{children}</Link>
    </li>
  );
};

const MobileNavbarMenu = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  return (
    <ul
      className={`flex md:flex-row lg:gap-16 md:gap-10  gap-10 flex-col  my-auto  px-8 py-8   `}
    >
      <MobileNavbarMenuItem to="/" active={pathname == "/"}>
        Home
      </MobileNavbarMenuItem>
      <MobileNavbarMenuItem to="/catalog" active={pathname == "/catalog"}>
        Product catalog
      </MobileNavbarMenuItem>
      <MobileNavbarMenuItem to="/pricelist" active={pathname == "/pricelist"}>
        Pricelist
      </MobileNavbarMenuItem>
      <MobileNavbarMenuItem
        to="/order-import"
        active={pathname == "/order-import"}
      >
        Order import
      </MobileNavbarMenuItem>
      <MobileNavbarMenuItem
        to="/my-account?wishlist"
        active={location.search == "?wishlist"}
      >
        Wishlist
      </MobileNavbarMenuItem>
      <li className="w-[150px] mx-auto flex md:hidden items-center place-content-center bg-gray-lighter text-green h-10 rounded-15 text-xs">
        <Link to="/contact-us">Contact us</Link>
      </li>
    </ul>
  );
};

type MenuItemProps = {
  children: React.ReactNode;
  to: string;
  active: boolean;
};

const MobileNavbarMenuItem = ({ children, to, active }: MenuItemProps) => {
  return (
    <li>
      <Link className="flex gap-4" to={to}>
        <Circle
          className={` group-hover:bg-green translate-y-1.5 ${
            active ? "bg-green" : ""
          }`}
        />
        <div
          className={`hover:text-green  whitespace-nowrap  hover:underline underline-offset-[12px] decoration-green decoration-2 ${
            active ? "text-green underline underline-offset-[12px] " : ""
          }`}
        >
          {children}
        </div>
      </Link>
    </li>
  );
};
