import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRelatedProducts = createAsyncThunk(
    "products/getRelatedProducts",
    async (base_url, { rejectWithValue }) => {
        try {
            const response = await axios.get(base_url);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);