import React from "react";
import Card from "../Card/Card";
import {
  BsChatTextFill,
  BsEnvelopeFill,
  BsTelephoneFill,
  BsX,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { WhatsappIcons } from "../Common/Icons";

type Props = {};

const Sidebar = (props: Props) => {
  const pathName = window.location.pathname;

  // @ts-ignore
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/62dc0e9237898912e95f4686/1g8lphrri";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    // @ts-ignore
    s0.parentNode.insertBefore(s1, s0);
  })();

  return (
    <div
      className="fixed right-6 z-50 top-1/2 transform -translate-y-1/8 md:-translate-y-1/2   
    "
    >
      <Card
        className={` w-16 h-fit   flex-col md:gap-4 gap-1 py-8 hidden md:flex  `}
      >
        <MenuItem to="/contact" active={pathName == "/contact"}>
          <BsTelephoneFill className="text-inherit" />
        </MenuItem>

        <MenuItem to="mailto:contact@fragrancedistri.com" active={false}>
          <BsEnvelopeFill className="text-inherit" />
        </MenuItem>
      </Card>

      <a
        href={`https://wa.me/+31625395762`}
        target="_blank"
        className={`w-14 h-14 mx-auto flex items-center justify-center   rounded-full bg-green  mt-4 `}
      >
        <WhatsappIcons />
      </a>

      <a
        className="w-14 h-14 mx-auto flex items-center justify-center mt-4  rounded-full bg-green "
        href="javascript:void(Tawk_API.toggle())"
      >
        <Tooltip text="Chat">
          <BsChatTextFill className="text-white text-xl" />
        </Tooltip>
      </a>
    </div>
  );
};

export default Sidebar;

export const MenuItem = ({
  children,
  active = false,
  to = "/",
  className,
}: {
  children: React.ReactNode;
  active?: boolean;
  to?: string;
  className?: string;
}) => {
  return (
    <Link
      to={to}
      className={
        "w-10 h-10 rounded-15 hover:bg-gray-lighter flex items-center hover:text-green text-gray-disabled justify-center " +
        (active && " bg-gray-lighter  text-green ") +
        (className && ` ${className}`)
      }
    >
      {children}
    </Link>
  );
};

const Tooltip = ({ children, text, ...props }: any) => {
  const [isShown, setIsShown] = React.useState(true);
  return (
    <div {...props} className="relative w-fit">
      {children}
      {isShown && text && (
        <div className="absolute top-11  left-1/2 -translate-x-1/2    bg-white default-shadow py-2 px-4  flex items-center rounded-[20px] shadow-[0px_4px_10px_3px_rgba(222,222,222,0.3)]">
          <h6 className="text-[10px] whitespace-nowrap text-gray-dark">
            {text}
          </h6>
          <BsX onClick={() => setIsShown(false)} />
        </div>
      )}
    </div>
  );
};
