import { BrowserRouter, useSearchParams } from "react-router-dom";
import "./App.css";
import DefaultRoutes from "./routes/DefaultRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <BrowserRouter>
          <DefaultRoutes />
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
