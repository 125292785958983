import Tooltip from "../Common/Tooltip";
import ButtonComponent, { ButtonProps } from "./ButtonComponent";

export const IconButton = ({ children, className, ...props }: ButtonProps) => {
  return (
    <ButtonComponent
      {...props}
      className={`bg-gray-lighter p-3 rounded-15 text-green hover:bg-green hover:text-white  ${className}`}
    >
      {children}
    </ButtonComponent>
  );
};

export const IconButtonOutline = ({
  children,
  className,
  text,
  ...props
}: ButtonProps & { text?: string | JSX.Element }) => {
  return (
    <Tooltip {...props} text={text}>
      <ButtonComponent
        className={`bg-white p-3 rounded-15 border-[0.7px] group hover:bg-gray-lighter hover:text-green w-10 h-10  flex items-center justify-center ${className}`}
      >
        {children}
      </ButtonComponent>
    </Tooltip>
  );
};

export const IconButtonCircle = ({
  children,
  className,
  text,
  ...props
}: ButtonProps & { text?: string | JSX.Element }) => {
  return (
    <Tooltip {...props} text={text}>
      <ButtonComponent
        className={`bg-white p-3 rounded-full text-green-dark  group hover:bg-gray-lighter hover:text-green w-10 h-10  shadow-default flex items-center justify-center ${className}`}
      >
        {children}
      </ButtonComponent>
    </Tooltip>
  );
};
