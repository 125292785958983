import React, {useEffect, useRef, useState} from 'react';
import RangeSlider from "react-range-slider-input";
import 'react-range-slider-input/dist/style.css';
import {useDispatch, useSelector} from "react-redux";
import {getAllFilters} from "../../app/Filter/actions/getAllFilters";
import {useSearchParams} from "react-router-dom";
import Card from "../Card/Card";
function PriceFilter() {
    const {min, max} = useSelector(state => state.filter.priceFilter)
    const {loading} = useSelector(state => state.filter)
    const [currentFilter, setCurrentFilter] = useState({min, max})
    const [searchParams, setSearchParams] = useSearchParams();
    const filterTimer = useRef(null);

    const dispatch = useDispatch()
    const onInputHandle = (e) => {
        setCurrentFilter({min : e[0], max: e[1]})
    }

    useEffect(()=>{
        setCurrentFilter({min : min, max: max})
    }, [min,max])

    const filterHandle = ()=>{
        if(filterTimer.current){
            clearTimeout(filterTimer.current)
        }
        filterTimer.current = setTimeout(() => {

            searchParams.set("min_price", currentFilter.min)
            searchParams.set("max_price", currentFilter.max)
            setSearchParams(searchParams)

        }, 2000)

    }
    useEffect(() => {

        if(searchParams.has("min_price") || searchParams.has("max_price")){
            const min_price = searchParams.get("min_price")
            const max_price = searchParams.get("max_price")

            if(min_price && min_price!==currentFilter.min && min_price >= min){
                setCurrentFilter((prevState) => {
                    return {min : min_price, max: prevState.max}
                } )
            } else{
                searchParams.delete("min_price")
            }
            if(max_price && max_price!==currentFilter.max && max_price <= max){
                setCurrentFilter((prevState) => {
                    return {min : prevState.min, max: max_price}
                } )
            }else{
                searchParams.delete("max_price")
            }

            setSearchParams(searchParams)
        }

    }, [searchParams])

    return (
        <Card>
            <div className="px-5 py-6" disabled={loading}>
                <RangeSlider onThumbDragEnd={filterHandle} value={[currentFilter.min, currentFilter.max]} onInput={onInputHandle} min={min} max={max}/>
                <div className="flex justify-between mt-4"><span>Min: €{currentFilter.min}</span><span>Max: {currentFilter.max}</span></div>
            </div>
        </Card>
    );
}

export default PriceFilter;