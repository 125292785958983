import React, { useEffect, useState } from "react";
import Select from "react-select";
import Checkbox from "./Checkbox";
import Circle from "../Common/Circle";
import { useAppSelector } from "../../app/hooks";
import useFilter from "../../hooks/useFilter";
import { nanoid } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import Tooltip from "../Common/Tooltip";

type Props = {
  placeholder: string;
  options: any;
  type: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const SelectInput = ({
  placeholder,
  className,
  options,
  type,
  ...props
}: Props) => {
  const [selected, setSelected] = useState([]);
  const { searchParams, setSearchParams } = useFilter();
  const myRef = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  const toggleOption = ({ id }: { id: any }) => {
    // @ts-ignore
    const newSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];
    setSelected(newSelected as any);

    // URLSearchParams'i güncelle
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(type);
    newSelected.forEach((item) => newSearchParams.append(type, item));
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (searchParams.get(type) === null) {
      setSelected([]);
    }
  }, [searchParams]);

  return (
    <>
      <div
        ref={myRef}
        onClick={() => setIsFocused(!isFocused)}
        className={`flex items-center  `}
      >
        <MultiSelectDropdown
          placeHolder={placeholder}
          options={options}
          selected={selected}
          toggleOption={toggleOption}
        />
      </div>
    </>
  );
};

const MultiSelectDropdown = ({
  options,
  selected,
  toggleOption,
  placeHolder,
}: any) => {
  return (
    <div className="c-multi-select-dropdown">
      <div className="c-multi-select-dropdown__selected">
        {selected.length > 0 ? (
          <div>{selected.length} selected</div>
        ) : (
          <div>{placeHolder}</div>
        )}
      </div>
      <ul className="c-multi-select-dropdown__options max-h-60 h-fit !w-60 overflow-y-scroll   overflow-x-hidden">
        {options.map((option: any) => {
          const isSelected = selected.includes(option.id);

          return (
            <Tooltip className={"!w-full "} key={nanoid()} title={option.name}>
              <li
                id={nanoid()}
                onClick={() => toggleOption({ id: option.id })}
                className=" flex items-center hover:bg-gray-lighter cursor-pointer   !w-full p-2"
              >
                <Circle active={isSelected} />
                <label
                  className="pl-2 w-full  whitespace-nowrap  "
                  htmlFor="select-option"
                >
                  {option.name}
                </label>
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </div>
  );
};
