import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const getOrders = createAsyncThunk(
    "orders/getOrders",
    async (user_id,thunkAPI) => {
        try {

            const response = await axios.get(
                `https://fragrancedistri.com/wp/wp-json/wp/v3/orders?user_id=${user_id}`
            );
     
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });