import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProductWithEan = async (eans) => {
    try {
        const response = await axios.get(`https://www.fragrancedistri.com/wp/wp-json/wp/v3/product_ean?ean_list=${eans}`);
        return response.data.products;
    } catch (error) {
        return error.response.data;
    }
}