import {notify} from "./notify";
import {store} from "../app/store";


function exportCSV( which ) {
    const state = store.getState();
    const items = which === "products" ? state.product.products : which === "orders" ? state.order.orders : [];
    if (items.length === 0) {
      notify(` No products to export`);
      return
    }
  const csv = convertArrayToCSV(items);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = which + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export default exportCSV;

export function convertArrayToCSV(array) {
  let csv = "";

  // Add the headers
  let headers = Object.keys(array[0]);
  delete headers[headers.indexOf("related_products")];
  delete headers[headers.indexOf("images")];
  delete headers[headers.indexOf("categories")];
  delete headers[headers.indexOf("description")];
  delete headers[headers.indexOf("brand")];
  delete headers[headers.indexOf("size")];
  delete headers[headers.indexOf("color")];
  delete headers[headers.indexOf("date")];
  delete headers[headers.indexOf("products")];
  delete headers[headers.indexOf("billing")];
  delete headers[headers.indexOf("shipping")];
  headers = headers.filter(n => n)

  csv += headers.join(",") + "\n";

  // Add the rows
  array.forEach((row) => {
    let rowValues = headers.map((header) => {
      if (typeof row[header] === "object") {
        return null;

      }
      const escapedValue = ("" + row[header]).replace(/"/g, '""');

      return `"${escapedValue}"`;
    });

    csv += rowValues.filter(n => n).join(",") + "\n";
  });

  return csv;
}
