import React, { useEffect, useState } from "react";
import Page from "../components/Layout/Page";
import Section from "../components/Elements/Semantic/Section";
import Alert from "../components/Alert/Alert";
import Accordion from "../components/Accordion/Accordion";
import Card from "../components/Card/Card";
import { InputOutline } from "../components/Input/Input";
import {
  BsBagPlusFill,
  BsCloudDownloadFill,
  BsCloudUploadFill,
  BsFillLockFill,
  BsTruck, BsUpcScan,
  BsX,
} from "react-icons/bs";
import CardInnerBorder from "../components/Card/CardInnerBorder";
import { CartItem } from "./Cart";
import { IconButtonOutline } from "../components/Button/IconButton";
import AddProduct from "../components/Input/AddProduct";
import { notify } from "../utils/notify";
import { getProductWithEan } from "../app/Product/actions/getProductWithEan";
import { parse } from "papaparse";
import { FileUploader } from "react-drag-drop-files";
import Modal from "../components/Modal/Modal";
import { getProducts } from "../app/Product/actions/getProducts";
import exportCSV from "../utils/exportCSV";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import { isMobile } from "react-device-detect";
import StockInfo from "../components/Common/StockInfo";
import NumberInput from "../components/Input/NumberInput";
import { addItemToCart, removeItemFromCart } from "../app/Cart/cartSlice";
import Circle from "../components/Common/Circle";
import ProductBarcode from "../components/Common/ProductBarcode";
import { ProductType } from "../types/Product";
import { useNavigate } from "react-router-dom";
import priceFormatter from "../utils/priceFormatter";



const OrderImport = (props) => {
  const [selectList, setSelectList] = React.useState(0);
  const fileTypes = ["CSV", "XLS", "XLSX"];
  const [file, setFile] = useState(null);
  const [beforeImportList, setBeforeImportList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [succeedProducts, setSucceedProducts] = useState([]);
  const [insufficientProducts, setInsufficientProducts] = useState([]);
  const [failedProducts, setFailedProducts] = useState([]);
  const [importedProducts, setImportedProducts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clearStates = () => {
    setBeforeImportList([]);
    setSucceedProducts([]);
    setInsufficientProducts([]);
    setFailedProducts([]);
    setImportedProducts([]);
    setSelectList(0);
    setFile(null);
  }
  const handleChange = (file) => {

    setFile(file);
    // @ts-ignore
    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const list = results.data.map((item) => {
          if (item["EAN"].length === 13 && Number(item["QUANTITY"]) > 0)
            return { ean: item["EAN"], quantity: Number(item["QUANTITY"]) }
          else {
            notify("Please enter a valid EAN and quantity ! The ean number must be 13 digits. and Quantity must be greater than 0. Ean :" + item?.["EAN"] + "  Quantity : " + item?.["QUANTITY"])
          }
        }).filter((item) => item !== undefined)
        addToImportList(list)
      }
    });
  }

  const addToImportList = (productList) => {
    let updatedAndNewProducts = productList.map((item) => {
      const findedProduct = beforeImportList.find((product) => product.ean === item.ean)
      if (findedProduct) {
        return { ean: item.ean, quantity: item.quantity + findedProduct.quantity }
      } else {
        return { ean: item.ean, quantity: item.quantity }
      }
    })
    const notUpdatedProducts = beforeImportList.filter((item) => !updatedAndNewProducts.find((product) => product.ean === item.ean))

    setBeforeImportList([...updatedAndNewProducts, ...notUpdatedProducts])
    return [...updatedAndNewProducts, ...notUpdatedProducts]
  }

  const [fileTypeError, setFileTypeError] = useState(false);

  async function importProducts(e) {
    e.preventDefault();

    let formData = [];

    for (let i = 0; i < e.target.elements.length; i = i + 2) {

      if (e.target.elements[i].value !== "" && e.target.elements[i + 1].value !== "") {
        if (e.target.elements[i].value.length === 13 && Number(e.target.elements[i + 1].value) > 0) {
          // @ts-ignore
          formData.push({ ean: e.target.elements[i].value, quantity: Number(e.target.elements[i + 1].value) });
        } else {
          notify("Please enter a valid EAN and quantity ! The ean number must be 13 digits. and Quantity must be greater than 0. Ean :" + e.target.elements[i].value + "  Quantity : " + e.target.elements[i + 1].value)
        }
      }

    }
    const productList = addToImportList(formData)

    let eans = ""
    productList.forEach((item) => {
      eans = eans + item.ean + ","
    })
    const products = await getProductWithEan(eans)

    let _succeedProducts = []
    let _insufficientProducts = []
    let _failedProducts = []
    products.map((item) => {
      const findedProduct = productList.find((product) => product.ean === item.ean)
      if (findedProduct) {
        if (item.stock_quantity >= findedProduct.quantity) {
          _succeedProducts.push({ item: item, quantity: findedProduct.quantity })
        } else {
          _insufficientProducts.push({ item: item, quantity: item.stock_quantity, desiredQuantity: findedProduct.quantity })
        }
      }
    })

    _failedProducts = productList.filter((item) => !products.find((product) => product.ean === item.ean))
    setSucceedProducts(_succeedProducts)
    setInsufficientProducts(_insufficientProducts)
    setFailedProducts(_failedProducts)
    setImportedProducts([..._succeedProducts, ..._insufficientProducts])
    setShowModal(true)
  }

  const calculateSubtotal = () => {
    let subtotal = 0
    importedProducts.forEach((item) => {
      subtotal = subtotal + item.item.price * item.quantity
    })
    return subtotal.toFixed(2)
  }

  const calculateVat = () => {
    // @ts-ignore
    let vat = calculateSubtotal() * 0.21
    return vat.toFixed(2)
  }

  const calculateTotal = () => {
    let total = Number(calculateSubtotal()) + Number(calculateVat())
    return total.toFixed(2)
  }
  const handleIncrement = (product, quantity) => {
    if (quantity < product.stock_quantity) {
      setImportedProducts(importedProducts.map((item) => {
        if (item.item.ean === product.ean) {
          return { item: item.item, quantity: item.quantity + 1 }
        } else {
          return item
        }
      }))
    }
  }

  const handleDecrement = (product, quantity) => {
    if (quantity > 2) {
      setImportedProducts(importedProducts.map((item) => {
        if (item.item.ean === product.ean) {
          return { item: item.item, quantity: item.quantity - 1 }
        } else {
          return item
        }
      }))
    }
  }

  const handleQuantityChange = (e, product, quantity) => {
    const { value } = e.target
    if (value.length > 0) {
      setImportedProducts(importedProducts.map((item) => {
        if (item.item.ean === product.ean) {
          return { item: item.item, quantity: Number(value) }
        } else {
          return item
        }
      }))
    } else {
      setImportedProducts(importedProducts.map((item) => {
        if (item.item.ean === product.ean) {
          return { item: item.item, quantity: 1 }
        } else {
          return item
        }
      }))
    }
  }
  const handleDelete = (product) => {
    setImportedProducts(importedProducts.filter((item) => item.item.ean !== product.ean))
  }

  return (
    <Page>
      <Section
        title="Order Import"
        subtitle="The easiest way to import your order!"
      >
        <Alert className="bg-gray-lighter">
          <p className="text-black">
            Import your order in only a few clicks, follow the steps below.
          </p>
        </Alert>
        <Modal title={"Imported quantity"} showModal={showModal} setShowModal={setShowModal}>
          {
            succeedProducts.length > 0 && (
              <Alert className="bg-gray-lighter my-8">
                <p className="text-black">
                  {succeedProducts.length} products have been imported successfully.
                </p>
              </Alert>
            )
          }

          {
            insufficientProducts.length > 0 && insufficientProducts.map((item) => {
              return (
                <Alert className="bg-[#FFD6D6] my-8">
                  <p className="text-black">
                    {item.item.name} : <b>{item.quantity}</b> of {item.desiredQuantity} products have been imported successfully.
                  </p>
                </Alert>
              )
            })
          }
          {
            failedProducts.length > 0 && failedProducts.map((item) => {
              return (
                <Alert className="bg-gray-lighter my-8">
                  <p className="text-black">
                    {item.ean} : The product does not exist.
                  </p>
                </Alert>
              )
            })
          }
        </Modal>
        <div className="flex flex-col my-8 gap-12">
          <form onSubmit={importProducts}>
            <Accordion title="Select products">
              <Card className="grid md:grid-cols-5 px-10 py-6 gap-4 ">
                <div className="col-span-3 ">
                  <h5>Add your products</h5>

                  {[...Array(selectList + 1)].map((x, i) =>
                    <AddProduct selectList={selectList} setSelectList={setSelectList} index={i} key={i} />
                  )}
                </div>

                <div className="col-span-2 ">
                  <h5>Upload your products</h5>

                  <h5 className="text-gray-dark mt-6">CSV sample file</h5>
                  <a href="https://fragrancedistri.com/wp/wp-content/uploads/2023/05/quickorder-csv.csv">
                    <p className="text-green mt-1">
                      Click <span className="underline">here</span> to download the
                      sample file
                    </p>
                  </a>
                  <h5 className="text-gray-dark mt-6">Upload your CSV file</h5>
                  <FileUploader children={
                    <>
                      <p className="text-green mt-1">
                        Browse to upload the CSV order file
                      </p>
                      {
                        fileTypeError && <p className="text-green mt-1">You can only upload CSV files</p>
                      }
                    </>
                  } multiple={false} handleChange={handleChange} name="file" onTypeError={() => {
                    setFileTypeError(true)
                    setTimeout(() => {
                      setFileTypeError(false);

                    }, 3000);
                  }} types={fileTypes} />


                </div>

                <div className="col-span-full mt-10">
                  <button type={"submit"} className="bg-gray-lighter  flex gap-4 items-center justify-center  w-60 h-11 mx-auto rounded-15 text-green hover:bg-green hover:text-white">
                    <BsCloudUploadFill className="text-inherit" />
                    <h5 className="text-inherit">Import Products</h5>
                  </button>
                </div>
                <div></div>
              </Card>
            </Accordion>
          </form>
          <Accordion button={<IconButtonOutline
            onClick={() => setImportedProducts([])}
            className={`   hover:!bg-green hover:!text-white !bg-gray-lighter border-green text-green `}
          >
            <BsX className=" " />
          </IconButtonOutline>} title="Added products">
            <CardInnerBorder
              innerCard="p-0  group-hover:!bg-white"
              className=" col-span-full"
            >
              <>
                {
                  importedProducts.length > 0 && importedProducts.map((item) => {
                    return (<ImportItem product={item.item} quantity={item.quantity}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      handleChange={handleQuantityChange}
                      handleDelete={handleDelete}
                    />)
                  })
                }

                <div className="grid grid-cols-12 mt-6">
                  <div className=" col-start-8 col-end-11 flex flex-col gap-1 mx-14">
                    <div className="flex justify-between">
                      <h5>SubTotal</h5>
                      <h5>€ {calculateSubtotal()}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h5>VAT cost (21%)</h5>
                      <h5> € {calculateVat()}</h5>
                    </div>

                    <div className="flex justify-between">
                      <h4 className="font-semibold">Total</h4>
                      <h4 className="font-semibold"> € {calculateTotal()}</h4>
                    </div>
                  </div>

                  <div className="col-start-11 col-end-13  flex justify-end px-4">

                  </div>
                </div>
              </>
            </CardInnerBorder>
          </Accordion>

          <div className="flex justify-center gap-6">
            <button onClick={() => {
              importedProducts.length > 0 && importedProducts.map((item) => {
                dispatch(addItemToCart(item))
              })
              clearStates()

            }} className="bg-gray-lighter  flex gap-4 items-center justify-center  w-60 h-11 rounded-15 text-green hover:bg-green hover:text-white">
              <BsBagPlusFill className="text-inherit" />
              <h5 className="text-inherit">Add to cart</h5>
            </button>

            <button onClick={() => {
              importedProducts.length > 0 && importedProducts.map((item) => {
                dispatch(addItemToCart(item))
              })
              clearStates()
              navigate("/checkout")
            }} className="flex gap-4 items-center justify-center  w-60 h-11 rounded-15  bg-green text-white">
              <BsBagPlusFill className="text-inherit" />
              <h5 className="text-inherit">Checkout</h5>
            </button>
          </div>

        </div>
      </Section>
    </Page>
  );
};

/* type ImportItemProps = {
  className?: string;
  children?: React.ReactNode;
  product?: ProductType & { quantity: number };
  quantity?: number;
  handleDecrement?,
  handleIncrement,
  handleChange,
  handleDelete,
}; */

const ImportItem = ({
  children,
  className,
  product,
  quantity,
  handleDecrement,
  handleIncrement,
  handleChange,
  handleDelete,
  ...props
}) => {

  const [value, setValue] = React.useState(quantity);
  const dispatch = useAppDispatch();

  useEffect(() => {

  }, [value]);

  if (isMobile) {
    return (
      <div className=" border border-gray rounded-40  cardInner py-4 hover:bg-[#FAFAFA]">
        <div className="grid grid-cols-12  md:px-4 px-2">
          <div className="col-span-2 ">
            <img
              className="object-contain my-auto w-16 h-16"
              src={product?.images[0]}
              alt=""
            />
          </div>
          <div className="col-span-10">
            <div className="flex  flex-col ml-2">
              <h5 className="font-semibold">{product?.brand?.name}</h5>
              <div className="flex justify-between items-center mt-1">
                <p className="text-black">{product?.name}</p>
              </div>
              <div className="flex  justify-around items-center mt-2">
                <StockInfo stock={product?.stock_quantity} className="" />

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  {product?.size.name}ml
                </div>

                <div className="px-4 py-1 text-[10px] flex items-center justify-center border-gray-light bg-gray rounded-15 ">
                  100ml
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 flex items-center justify-between  mt-4">
            <IconButtonOutline
              className={`md:ml-3 !border-green !bg-green-lighter `}
            >
              <BsUpcScan className="!bg-gray-lighter !text-green " />
            </IconButtonOutline>
            <h4 className="font-semibold text-xs">{priceFormatter(product?.price)}</h4>
            <div className="flex items-center gap-2 ">

              <div className={` "md:w-36 "}  h-10  w-32 border border-gray-light flex  rounded-[17px] `}>
                <button onClick={() => handleDecrement(product, quantity)} className={`"w-12"} flex items-center justify-center`}>-</button>
                <input onChange={(e) => handleChange(e, product, quantity)} className={`"w-16"} outline-none text-center bg-transparent`} type="number" value={quantity} />
                <button onClick={() => handleIncrement(product, quantity)} className={` "w-12"} flex items-center justify-center`}>+</button>
              </div>

              <IconButtonOutline
                // @ts-ignore
                onClick={() => handleDelete(product)}
                className={` hover:!bg-green hover:!text-white !bg-gray-lighter border-green text-green `}
              >
                <BsX className=" " />
              </IconButtonOutline>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-12 border border-gray rounded-40  cardInner hover:bg-[#FAFAFA] px-4">
        <div className="col-span-4 flex items-center gap-4  h-20">
          <Circle className=" productListCardCircle" />

          <div className=" px-2 bg-white  rounded-[20px]">
            <img
              className="object-contain  w-8 h-14 "
              src={product?.images?.[0]}
              alt=""
            />
          </div>

          <div>
            <h5>{product?.brand?.name}</h5>
            <p className="text-black">{product?.name}</p>
          </div>
        </div>
        <div className="col-span-5  flex justify-around items-center text-center ">
          <StockInfo stock={product?.stock_quantity} />
          <div>
            <p className="text-black">Size</p>
            <p className="text-black">{product?.size?.name}ml</p>
          </div>
          <div>
            <p className="text-black">Gender</p>
            <p className="text-black">Unisex</p>
          </div>
          <div>
            <ProductBarcode ean={product?.ean} />
          </div>
        </div>
        <div className="col-span-3 flex justify-between items-center">
          <h4 className="font-semibold">{priceFormatter(product?.price)}</h4>
          <div className="flex items-center gap-4">
            <div className={` md:w-36   h-10  w-32 border border-gray-light flex  rounded-[17px] `}>
              <button onClick={() => handleDecrement(product, quantity)} className={` w-12 flex items-center justify-center`}>-</button>
              <input onChange={(e) => handleChange(e, product, quantity)} className={` w-16 outline-none text-center bg-transparent`} type="number" value={quantity} />
              <button onClick={() => handleIncrement(product, quantity)} className={` w-12  flex items-center justify-center`}>+</button>
            </div>
            <IconButtonOutline
              onClick={() => handleDelete(product)}
              className={`   hover:!bg-green hover:!text-white !bg-gray-lighter border-green text-green `}
            >
              <BsX className=" " />
            </IconButtonOutline>
          </div>
        </div>
      </div>
    );
  }
};
export default OrderImport;
