import React, { useEffect, useState, useRef } from "react";
import ButtonComponent from "../components/Button/ButtonComponent";
import Card from "../components/Card/Card";
import Section from "../components/Elements/Semantic/Section";
import { SecondaryInput } from "../components/Input/Input";
import Page from "../components/Layout/Page";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../app/User/actions/signUp";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { notify } from "../utils/notify";
import PasswordInput from "../components/Input/PasswordInput";
import SelectCountry from "../components/Input/SelectCountry";

function Register() {
  const navigate = useNavigate();
  const { loggedIn, newRegister } = useSelector((state: any) => state.user);
  const { state } = useLocation();
  useEffect(() => {
    if (loggedIn && !newRegister) return navigate(state?.path || "/my-account");
  }, [loggedIn]);

  const dispatch = useDispatch();

  const verifyToken = async (token: any) => {
    try {
      return await axios.post(`https://tezyak.eu/wp/wp-json/wp/v3/captcha`, {
        reCAPTCHA_TOKEN: token,
      });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const [user, setUser] = useState({
    email: "", // ok
    password: "",
    address_1: "", // ok
    state: "",
    address_2: "",
    company: "", // ok
    postcode: "", // ok
    phone: "",
    first_name: "", // ok
    last_name: "", // ok
    country: "",
    city: "", // ok
    vat_number: "", // ok
    vat_country: "",
    house_number: "",
    billing_first_name: "",
    billing_last_name: "",
    billing_phone: "",
    billing_email: "",
    billing_address_1: "",
    billing_address_2: "",
    billing_city: "",
    billing_state: "",
    billing_postcode: "",
    billing_country: "",
    billing_company: "",
    billing_house_number: "",
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_phone: "",
    shipping_email: "",
    shipping_address_1: "",
    shipping_address_2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_postcode: "",
    shipping_country: "",
    shipping_company: "",
    shipping_house_number: "",
  });

  const recaptchaRef = useRef();

  async function handleSubmit(e: any) {
    e.preventDefault();
    //@ts-ignore
    let token = recaptchaRef.current.getValue();
    //@ts-ignore
    recaptchaRef.current.reset();
    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token) {
        Object.keys(e.target.elements).map((key) => {
          const element = e.target.elements[key];
          if (element.name) {
            //@ts-ignore
            user[element.name] = element.value;
            const billing_key = "billing_" + element.name;
            //@ts-ignore
            if (user[billing_key] !== undefined) {
              //@ts-ignore
              user["billing_" + element.name] = element.value;
            }
            const shipping_key = "shipping_" + element.name;
            //@ts-ignore
            if (user[shipping_key] !== undefined) {
              //@ts-ignore
              user["shipping_" + element.name] = element.value;
            }
          }
        });
        let user_meta = {
          billing_first_name: user.first_name,
          billing_last_name: user.last_name,
          billing_address_1: user.address_1,
          billing_address_2: user.address_2,
          billing_house_number: user.house_number,
          billing_company: user.company,
          billing_state: user.state,
          billing_city: user.city,
          billing_postcode: user.postcode,
          billing_phone: user.phone,
          billing_email: user.email,
          billing_country: user.country,

          shipping_first_name: user.first_name,
          shipping_last_name: user.last_name,
          shipping_phone: user.phone,
          shipping_email: user.email,
          shipping_address_1: user.address_1,
          shipping_address_2: user.address_2,
          shipping_house_number: user.house_number,
          shipping_company: user.company,
          shipping_state: user.state,
          shipping_city: user.city,
          shipping_postcode: user.postcode,
          shipping_country: user.country,
          vat_number: user.vat_number,
        };
        //@ts-ignore
        dispatch(signUp({ ...user, user_meta: JSON.stringify(user_meta) }));
      } else {
        notify("Please verify you are not a robot");
      }
    } else {
      notify("Please verify you are not a robot");
    }
  }

  return (
    <Page>
      <Section
        title="Registration"
        subtitle="Complete the form below to subscribe as a wholesaler"
      >
        <Card className="md:!mx-30 lg:!mx-44">
          <form onSubmit={handleSubmit} className="grid grid-cols-2  md:pt-24">
            <div className=" col-span-2 md:col-span-1 flex flex-col pb-4 md:pb-0 ">
              <img
                className=" w-[291px] h-[291px] m-auto"
                src="logo/logo_green.png"
                alt=""
              />
            </div>
            <div className="flex flex-col col-span-2 md:col-span-1  sm:px-10 md:pr-5 md:px-0 lg:px-0 lg:pr-5 gap-y-2">
              <SecondaryInput name="first_name" placeholder="Name*" required />
              <SecondaryInput
                name="last_name"
                placeholder="Lastname*"
                required
              />
              <SecondaryInput name="company" placeholder="Company Name" />
              <SecondaryInput
                required
                name="vat_number"
                placeholder="VAT Number"
              />
              <SecondaryInput
                required
                name="address_1"
                placeholder="Adress line 1*"
              />
              <SecondaryInput
                required
                name="address_2"
                placeholder="Address line 2"
              />
              <SecondaryInput
                required
                name="postcode"
                placeholder="Postcode*"
              />
              <SecondaryInput required name="city" placeholder="City" />
              <SelectCountry required name="country" placeholder="Country" />
              <SecondaryInput
                required
                name="state"
                placeholder="State/Region"
              />
              <SecondaryInput required name="email" placeholder="Email*" />
              <SecondaryInput required name="phone" placeholder="Phone*" />
              <PasswordInput required name="password" placeholder="Password" />
              <div className="mx-auto">
                <ReCAPTCHA
                  //@ts-ignore
                  ref={recaptchaRef}
                  sitekey={"6LeRaponAAAAAIwf_8AmSZh0NwaNBtozTIX0hX78"}
                />
              </div>
            </div>
            <div className="col-span-2 flex justify-center my-10">
              <ButtonComponent
                type={"submit"}
                className="bg-gray-lighter w-40 h-12 rounded-15 text-green font-semibold text-xs hover:bg-green hover:text-white"
              >
                Register
              </ButtonComponent>
            </div>
          </form>
        </Card>
      </Section>
    </Page>
  );
}

export default Register;
