import { createSlice} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/notify";
import Cookies from "js-cookie";

const initialState = {
  products: Cookies.get("wishlist")
    ? JSON.parse(Cookies.get("wishlist"))
    : [],
};


const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action) => {

      let item = findItem(state, action);
      if (typeof item === "undefined") {
        addItem(state, action)
      } else{
        removeItem(state, action)
      }
      Cookies.set("wishlist", JSON.stringify(state.products));
    },

    removeFromWishlist: (state, action) => {
      let item = findItem(state, action)
      if (typeof item !== "undefined") {
        removeItem(state, action)
      }

      Cookies.set("wishlist", JSON.stringify(state.products));
    },
  },
});

const findItem = (state, action) => {
    return state.products.find((item) => item.id === action.payload.id);
}

const addItem = (state, action) => {
    state.products.push({ ...action.payload });
    addedNotification(action);
}

const removeItem = (state, action) => {
    state.products = state.products.filter(
        (item) => item.id !== action.payload.id
    );
    removedNotification(action);
}

const addedNotification = (action) => {
    toast(
        `${action.payload.name
            .split(" ")
            .slice(0, 3)
            .join(" ")} added to wishlist`,
        toastOptions
    );
}

const removedNotification = (action) => {
    toast(
        `${action.payload.name
            .split(" ")
            .slice(0, 3)
            .join(" ")} removed from wishlist`,
        toastOptions
    );
}

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
