import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getShippingPrice = createAsyncThunk(
  "cart/getShippingPrice",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `https://fragrancedistri.com/wp/wp-json/sendcloud/v1/shipping_price`,
        args
      );
      console.log(response?.data);
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
