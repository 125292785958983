import InitializeGoogleAnalytics, { TrackGoogleAnalyticsEvent } from "..";

const ContactFormEvent = () => {
  InitializeGoogleAnalytics();
  TrackGoogleAnalyticsEvent(
    "Contact Form",
    "Form_Submit",
    "Contact Form Submitted"
  );
};

export default ContactFormEvent;
