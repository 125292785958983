import React from "react";
import Navbar from "../Elements/Semantic/Navbar";
import { motion } from "framer-motion";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Elements/Semantic/Footer";
import { useEffect } from 'react';

type Props = {
  children: React.ReactNode;
  banner?: React.ReactNode;
  navbar?: boolean;
};

const Page = ({ children, banner, navbar = true }: Props) => {


  useEffect(() => {
    window.scrollTo(0, 0)
 
  }, [])

  
  return (
    <>
      {navbar && <Navbar />}
      {banner}
      <main className="page">{children}</main>
      <Sidebar />
      <Footer />
    </>
  );
};

export default Page;

