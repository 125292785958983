import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getAddresses = createAsyncThunk(
  "user/getAddresses",
  async (args, thunkAPI) => {
    try {

        const token = Cookies.get("fd-token");
      const response = await axios.get(
        `https://www.fragrancedistri.com/wp/wp-json/wp/v3/customer/address?JWT=${token}`,
        args
      )

      return response.data;
      
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
