import React from 'react'
import { ButtonProps } from './ButtonComponent';


export const SecondaryButton = ({ children, className, active = false, ...props }: ButtonProps & {active?: boolean} ) => {
    return (
      <button { ...props } className={`flex items-center justify-center text-xs  rounded-15  ${active ? "bg-white shadow-default" : "border border-gray-light"}  ${className}`}>
       
          {children}

      </button>
    );
  };
  