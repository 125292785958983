import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const signUp = createAsyncThunk(
    "user/signUp",
    async (args,thunkAPI) => {

        try {
            const response = await axios.post(
                "https://www.fragrancedistri.com/wp/wp?rest_route=/simple-jwt-login/v1/users&AUTH_KEY=wiaIwNTkxfQ.bJ0uPaqHk%" , args
            );

            const jwt = response.data.jwt;
            const userInfoResponse = await axios.post(`https://fragrancedistri.com/wp/wp-json/wp/v3/customer/info?JWT=${jwt}`);

            return {
                jwt,
                user: userInfoResponse.data
            }

        } catch (error) {

            return thunkAPI.rejectWithValue({ error: error.response });
        }
    });