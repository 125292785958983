import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const signIn = createAsyncThunk(
    "user/signInUser",
    async (args,thunkAPI) => {
        try {
            const authResponse = await axios.post(
                "https://www.fragrancedistri.com/wp/wp?rest_route=/simple-jwt-login/v1/auth&AUTH_KEY=wiaIwNTkxfQ.bJ0uPaqHk%", args.user
            )

            const token = authResponse.data.data.jwt;
            const userInfoResponse = await axios.post(`https://fragrancedistri.com/wp/wp-json/wp/v3/customer/info?JWT=${token}`);

            return {
                token,
                user: userInfoResponse.data
            }
         
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.data.message );
        }
    });