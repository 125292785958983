/*  order slice */

import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "./actions/getOrders";
import { createOrder } from "./actions/createOrder";
import OrderAcceptedEvent from "../../utils/google-analytics/events/OrderAcceptedEvent";

const initialState = {
  orders: [],
  total: 0,
  filteredOrders: [],
  showFiltered: false,
  loading: false,
  paid: 0,
  unpaid: 0,
  created_order: {},
  payment_link: "",
  status: "idle",
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    filterOrder: (state, { payload }) => {
      const endDate = Date.parse(payload.end);
      const startDate = Date.parse(payload.start);
      state.filteredOrders = state.orders.filter((order) => {
        const orderDate = Date.parse(order.date.date.substring(0, 10));

        if (orderDate >= startDate && orderDate <= endDate) {
          return true;
        } else {
          return false;
        }
      });
      state.showFiltered = true;
    },
    resetFilter: (state) => {
      state.filteredOrders = [];
      state.showFiltered = false;
    },

    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        state.orders = payload;
        let total = 0;
        let paid = 0;
        let unpaid = 0;
        payload.map((order) => {
          total += parseFloat(order?.total);
          if (order?.status === "on-hold" || order?.status === "pending") {
            unpaid += parseFloat(order?.total);
          } else if (
            order?.status === "completed" ||
            order?.status === "processing"
          ) {
            paid += parseFloat(order?.total);
          }
        });
        state.total = total.toFixed(2);
        state.paid = paid.toFixed(2);
        state.unpaid = unpaid.toFixed(2);
        state.loading = false;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createOrder.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        OrderAcceptedEvent();
        state.status = "succeeded";
        state.created_order = payload.order;
        state.payment_link = payload.payment_link;
        state.loading = false;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { filterOrder, resetFilter, setStatus } = orderSlice.actions;

export default orderSlice.reducer;
